<template>
  <router-link
    class="card p-4 pt-5 d-flex flex-column justify-content-between"
    :to="{ path: href }"
  >
    <div class="d-flex align-items-center flex-column">
      <slot name="card-dropdown"></slot>
      <app-photo-user :photo="photo" size="l" :placeholder="photoPlaceholder"></app-photo-user>
      <h5 class="mt-3 mb-0">{{ title }}</h5>
      <span class="text-secondary subtitle">{{ subtitle }}</span>
      <slot name="card-content"></slot>
    </div>
    <footer class="footer"><slot name="card-footer"></slot></footer>
  </router-link>
</template>

<script>
import AppPhotoUser from '@/components/ui/photo.user.vue'

export default {
  props: ['title', 'subtitle', 'photo', 'photoPlaceholder', 'href'],
  components: {
    AppPhotoUser,
  },
}
</script>

<style lang="scss">
.card {
  text-decoration: none !important;
  position: relative !important;

  &:hover {
    background-color: rgba(#f4f4f5, 0.3);
  }

  footer {
    height: 24px;
  }

  footer .vue-content-placeholders-text__line {
    margin-top: 5px;
    margin-bottom: 0;
    width: 100%;
  }
}

.subtitle:empty::after {
  content: '\00a0';
}
</style>
