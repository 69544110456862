<template class="test">
  <div class="d-flex m-0 h-100 align-items-center justify-content-center">
    <div class="box-login shadow-lg p-4 rounded">
      <div class="text-center mb-4 mt-3">
        <img src="/img/logo.svg" class="logo" />
      </div>
      <h2 class="text-center mb-4">Login to WeBuild</h2>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Email" label-for="userEmail">
          <b-form-input
            id="userEmail"
            tabindex="1"
            v-model.trim="$v.form.email.$model"
            :state="validateState($v.form.email)"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Password" label-for="userPassword">
          <b-form-input
            id="userPassword"
            tabindex="2"
            type="password"
            v-model.trim="$v.form.password.$model"
            :state="validateState($v.form.password)"
          ></b-form-input>
        </b-form-group>
        <app-button-spinner :loading="isProcessing" :tabindex="3" block>Login</app-button-spinner>
      </b-form>
    </div>
  </div>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  components: {
    AppButtonSpinner,
  },
  computed: {
    ...mapGetters('app', ['isProcessing']),
  },
  methods: {
    validateState,
    ...mapActions('auth', ['login']),
    ...mapMutations('app', ['SET_PAGELOADING']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.login(this.form)
    },
  },
  created() {
    this.SET_PAGELOADING(false)
  },
}
</script>
