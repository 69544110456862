import * as fb from '@/firebase'
import router from '@/router/index'
import { toastError, toastSuccess } from '@/components/ui/toast'
import { cloneDeep } from 'lodash'
import { trackEvent } from '@/utils/analytics'

// Reviews are a subcollection of workers, that's why they are stored & mutated on worker
// On this module we store an array of all reviews (currently used for latest reviews on dashboard)
const state = {
  all: []
}

const mutations = {
  UPDATE_REVIEW(state, {id, updatedReview}) {
    const review = state.all.find(review => review.id === id)
    // If review existing, overwrite
    if (review) {
      Object.assign(review, updatedReview)
      // Otherwise add to store
    } else {
      state.all.push(updatedReview)
    }
  },
}

const getters = {
  listAllReviews(state) {
    return [...state.all].sort((a, b) => {
      return a.createdOn < b.createdOn ? 1 : a.createdOn > b.createdOn ? -1 : 0
    })
  },
  listLatestReviews(state) {
    return [...state.all].sort((a, b) => {
      return a.createdOn < b.createdOn ? 1 : a.createdOn > b.createdOn ? -1 : 0
    }).slice(0,5)
  },
  findReview(state, getters, rootState) {
    return (id, reviewId) => {
      try {
        const worker = rootState.workers.all.filter(worker => worker.id === id)[0]
        const review = worker.reviews.all.filter(review => review.id === reviewId)[0]
        return review
      } catch (e) {
        return {}
      }
    }
  },
}

/* --------------------------------

  Index: Actions
  ---------------------------------
  - addReview()
  - updateReview()
  - addTeamReview()
  - deleteReview()
  - cumulateCategoryRatings()

--------------------------------- */

const actions = {
  // Load reviews for one worker and populate additional review metrics 
  loadReviews: async function({ dispatch, commit, rootState }, workerId) {
    // Get reviews from worker
    const rawReviews = await fb.workersCollection
      .doc(workerId)
      .collection('reviews')
      .orderBy('createdOn', 'desc')
      .get()

    // Set init state of reviews to set counts to 0
    const reviews: any = {
      ...rootState.workers.toPopulate.reviews
    }
    // Temp count, not needed on review object
    let totalAvgRating = 0
    
    // Store reviews on each worker
    reviews.all = rawReviews.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    // Calculate ratings
    reviews.all.forEach(review => {
      // Create array with only rating values
      const ratings = review.categories.map(item => (item.rating ? item.rating : 0))
      // Calculate average rating per review
      const avgRating = ratings.reduce((a, b) => a + b, 0) / ratings.length
      // Store avgRating per review
      review.avgRating = avgRating

      // Counter for total reviews
      reviews.totalCount++
      
      // Only if review is verified
      if(review.verified) {
        reviews.verifiedCount++
        // Sum up average rating to calculate total average rating later on across all reviews
        totalAvgRating += avgRating

        if (review.recommend) reviews.verifiedRecommendations++
      } else {
        reviews.unverifiedCount++
      }

      commit('UPDATE_REVIEW', { id: review.id, updatedReview: { workerId, ...review } })
    })

    // Summarise all review categories
    reviews.avgRatingByCategories = await dispatch('cumulateCategoryRatings', reviews.all)

    // Calculate avgRating if at least one verified review is existing. Otherwise it's 0
    if(reviews.verifiedCount)
      reviews.avgRating = totalAvgRating / reviews.verifiedCount

    return reviews
  },
  addReview: async function({ commit, dispatch, getters, rootGetters }, { form, id: workerId }) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone form to not modify the visible form for user while processing
    form = cloneDeep(form)

    // Delete bonus so it's not stored in the database on the review afterwards
    const bonus = form.bonus
    delete form.bonus

    // 1) Add transaciton (bonus) to database if set
    if (bonus.granted) {
      // Add transaction to database
      await fb.workersCollection
        .doc(workerId)
        .collection('transactions')
        .add({
          createdOn: new Date(),
          updatedOn: new Date(),
          amount: bonus.amount,
          comment: bonus.comment,
          type: 'add',
          notifyWorker: false,
        })
    }

    // 2) Add review to database
    const { id: reviewId } = await fb.workersCollection
      .doc(workerId)
      .collection('reviews')
      .add({
        createdOn: new Date(),
        updatedOn: new Date(),
        verified: true, // Reviews added via admin are automatically verified
        internal: true, // Review added via admin console
        ...form,
      })

    // 3) Add activity log for new review
    await fb.workersCollection.doc(workerId).collection('activities').add({
      type: 'review',
      createdOn: new Date(),
      reviewId,
      reviewerFullname: form.fullname,
      reviewerCompany: form.company,
      project: form.project
    })

    // 4) Sent new review notification if selected
    if (form.notifyWorker) {
      const tplId = bonus.granted ? 'reviewNewWithBonus' : 'reviewNew'
      const url = `${rootGetters['settings/listSettings'].app.url}/profile?tab=reviews`

      dispatch(
        'notifications/sendTplNotification',
        {
          tplId,
          tplData: { worker: { ...rootGetters['workers/findWorker'](workerId) }, review: { ...form }, bonus, url },
        },
        { root: true }
      )
    }

    trackEvent('Review Added', { sendNotification: form.notifyWorker, bonus: bonus.granted })
    commit('app/SET_PROCESSING', false, { root: true })

    // 5) Redirect to reviews overview
    router
      .push({
        path: `/workers/${workerId}/reviews`,
        query: { ...rootGetters['app/getRedirectQuery'] },
      })
      .catch(err => err)
  },
  updateReview: async function({ commit, rootGetters }, { form, id: workerId, reviewId }) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone form to not modify the visible form for user while processing
    form = cloneDeep(form)

    // Cleanup comulated fields
    delete form.avgRating

    // Update review in database
    await fb.workersCollection
      .doc(workerId)
      .collection('reviews')
      .doc(reviewId)
      .update({
        updatedOn: new Date(),
        updatedBy: rootGetters['auth/getUserProfile'].displayName,
        ...form,
      })

    trackEvent('Review Updated')
    commit('app/SET_PROCESSING', false, { root: true })

    // 3) Redirect to reviews overview
    router
      .push({
        path: `/workers/${workerId}/reviews`,
        query: { ...rootGetters['app/getRedirectQuery'] },
      })
      .catch(err => err)
  },
  addTeamReview: async function({ commit, dispatch, getters, rootGetters }, form) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone form to not modify the visible form for user while processing
    form = cloneDeep(form)
    const workers = form.workers
    delete form.workers

    // Delete bonus so it's not stored in the database on the review afterwards
    const bonus = form.bonus
    delete form.bonus

    // Loop through all selected workers
    for (const worker of workers) {
      // 1) Add transaciton (bonus) to database if set
      if (bonus.granted) {
        // Add transaction to database
        await fb.workersCollection
          .doc(worker.id)
          .collection('transactions')
          .add({
            createdOn: new Date(),
            updatedOn: new Date(),
            amount: bonus.amount,
            comment: bonus.comment,
            type: 'add',
            notifyWorker: false,
          })
      }

      // 2) Add review to database
      await fb.workersCollection
        .doc(worker.id)
        .collection('reviews')
        .add({
          createdOn: new Date(),
          updatedOn: new Date(),
          ...form,
          verified: true, // Reviews added via admin are automatically verified
          internal: true, // Review added via admin console
        })

      // 3) Sent new review notification if selected
      if (form.notifyWorker) {
        const tplId = bonus.granted ? 'reviewNewWithBonus' : 'reviewNew'
        const url = `${rootGetters['settings/listSettings'].app.url}/profile?tab=reviews`

        dispatch(
          'notifications/sendTplNotification',
          {
            tplId,
            tplData: { worker: { ...rootGetters['workers/findWorker'](worker.id) }, review: { ...form }, bonus, url },
          },
          { root: true }
        )
      }
    }

    // Reload workers
    await dispatch('workers/loadWorkers', {},{ root: true })

    trackEvent('Team Review Added', {
      sendNotification: form.notifyWorker,
      count: workers.length,
      bonus: bonus.granted,
    })
    toastSuccess(this, `The team review for ${workers.length} was added.`)

    commit('app/SET_PROCESSING', false, { root: true })

    // 3) Redirect to reviews overview
    router
      .push({
        path: `/workers`,
        query: { ...rootGetters['app/getRedirectQuery'] },
      })
      .catch(err => err)
  },
  deleteReview: async function({ dispatch }, { workerId, id }) {
    // 1) Delete review from database
    await fb.workersCollection
      .doc(workerId)
      .collection('reviews')
      .doc(id)
      .delete()

    // 2) Reload worker
    await dispatch('workers/loadWorker', { awaitPopulated: true, id: workerId }, { root: true })

    trackEvent('Review Deleted')
  },
  // Populates rating summary per category
  cumulateCategoryRatings: async function(context, reviews) {
    const rawRatings = []
    const ratings = []

    // 1) Create raw array with all ratings per category
    reviews.forEach(review => {
      // Skip unverified reviews
      if(!review.verified) return

      review.categories.forEach(rating => {
        // Create array if not yet existing
        if (typeof rawRatings[rating.id] === 'undefined') rawRatings[rating.id] = []

        rawRatings[rating.id].push(rating)
      })
    })

    // 2) Create rating array with final properties and calculations
    Object.keys(rawRatings).forEach(key => {
      let total = 0

      rawRatings[key].forEach(rating => {
        total += rating.rating ? rating.rating : 0
      })

      ratings.push({
        avgRating: total / rawRatings[key].length,
        name: rawRatings[key][0].name,
        id: rawRatings[key][0].id,
      })
    })

    return ratings
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
