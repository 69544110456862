<template>
  <div class="mb-5">
    <h3 class="mb-4">{{ title }}</h3>
    <p class="text-muted">{{ description }}</p>
    <div>
      <b-list-group class="list-editable mb-3">
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          v-for="item in items"
          :key="item.id"
        >
          <b-form-input
            :value="item.name"
            :ref="item.id"
            @change="updateItem(item.id)"
          ></b-form-input>
          <a v-b-modal="`deleteModal${label}`" @click="selectedItem = item" class="link-delete">
            <b-icon icon="x-circle" font-scale="1"></b-icon>
          </a>
        </b-list-group-item>
      </b-list-group>
      <b-link @click="addItem()">
        <b-icon icon="plus" font-scale="1" class="mr-1"></b-icon>
        Add {{ label.toLowerCase() }}
      </b-link>
      <b-link @click="$emit('init')" v-if="!items.length" class="ml-4">
        <b-icon icon="download" font-scale="1" class="mr-1"></b-icon>Load default
      </b-link>
      <slot></slot>
    </div>

    <app-confirm-modal
      :title="`Delete ${label}?`"
      :customId="`deleteModal${label}`"
      @ok="$emit('delete', selectedItem.id)"
    >
      <div>
        Are you sure you want to delete this {{ label.toLowerCase() }}
        <strong>{{ selectedItem.name }}</strong> permanently? This action can not be undone.
      </div>
    </app-confirm-modal>
  </div>
</template>

<script>
import AppConfirmModal from '@/components/ui/modal.confirm.vue'

export default {
  props: ['title', 'items', 'label', 'description'],
  data: function () {
    return {
      selectedItem: {},
    }
  },
  methods: {
    addItem() {
      this.$emit('add', this)
    },
    updateItem(id) {
      this.$emit('update', { val: this.$refs[id][0].$el.value, id })
    },
  },
  components: {
    AppConfirmModal,
  },
}
</script>

<style lang="scss" scoped>
.list-editable .form-control {
  padding: 0;
  height: auto;
  border: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
