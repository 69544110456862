// State object
const state = {
  processing: false, // State if form is processing
  pageLoading: true,
  sidebar: {
    loading: false,
    title: '',
    class: '',
  },
  redirectToQuery: {},
}

// Mutations
const mutations = {
  SET_PROCESSING(state, val) {
    state.processing = val
  },
  SET_PAGELOADING(state, val) {
    state.pageLoading = val
  },
  SET_SIDEBARLOADING(state, val) {
    state.sidebar.loading = val
  },
  SET_SIDEBARTITLE(state, val) {
    state.sidebar.title = val
  },
  SET_SIDEBARCLASS(state, val) {
    state.sidebar.class = val
  },
  SET_REDIRECT_QUERY(state, val) {
    state.redirectToQuery = val
  },
}

// Getter functions
const getters = {
  isProcessing(state) {
    return state.processing
  },
  isPageLoading(state) {
    return state.pageLoading
  },
  isSidebarLoading(state) {
    return state.sidebar.loading
  },
  getSidebarTitle(state) {
    return state.sidebar.title
  },
  getSidebarClass(state) {
    return state.sidebar.class
  },
  getRedirectQuery(state) {
    return state.redirectToQuery
  },
}
// Actions
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
