<template>
  <b-input-group class="input-search size-sm">
    <b-form-input
      type="search"
      :value="content"
      :size="size"
      @input="handleInput"
      @change="setQuery"
      :placeholder="placeholder"
    ></b-form-input>
    <b-input-group-append>
      <b-input-group-text class="c-pointer">
        <b-icon
          v-if="content && content !== ''"
          icon="x"
          @click="resetInput"
          :font-scale="size === 'sm' ? '1.05' : '1.1'"
        />
        <b-icon
          v-else
          icon="search"
          class="text-gray-500"
          :font-scale="size === 'sm' ? '1.05' : '1.1'"
        />
      </b-input-group-text>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  props: {
    size: { type: String, default: '' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: 'Type your search' },
  },
  data() {
    return {
      content: this.value,
    }
  },
  watch: {
    // Emit input for two-way-binding
    content: function () {
      this.$emit('input', this.content)
    },
    // Reset content if query gets removed from route (e.g. by clicking on «workers» main navigation)
    $route: function () {
      if (!this.$route.query.search) this.content = ''
    },
  },
  methods: {
    handleInput(e) {
      this.content = e
    },
    resetInput() {
      this.content = ''
      this.setQuery()
    },
    setQuery() {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          ...this.$route.query,
          search: this.content || undefined,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.input-search {
  min-width: 250px;

  &.size-sm {
    min-width: 220px;
  }

  .form-control[type='search'] {
    border-right: 0;
  }

  .input-group-append .input-group-text {
    background-color: transparent;
    border-left: 0;
  }
}
</style>
