<template>
  <div>
    <app-page-header title="Add Worker"></app-page-header>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group
        label="Firstname*"
        label-for="workerFirstname"
        invalid-feedback="The firstname is required."
      >
        <b-form-input
          id="workerFirstname"
          tabindex="1"
          v-model.trim="$v.form.firstname.$model"
          :state="validateState($v.form.firstname)"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Lastname*"
        label-for="workerLastname"
        invalid-feedback="The lastname is required."
      >
        <b-form-input
          id="workerLastname"
          tabindex="2"
          v-model.trim="$v.form.lastname.$model"
          :state="validateState($v.form.lastname)"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Jobtitle" label-for="workerJobtitle">
        <b-form-input id="workerJobtitle" tabindex="3" v-model.trim="form.jobtitle"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Phone number*"
        label-for="workerPhone"
        description="The phone number needs to be in the format of +41 79 XXX XX XX."
      >
        <b-form-input
          lazy
          id="workerPhone"
          tabindex="4"
          v-model.trim="$v.form.phone.$model"
          :state="validateState($v.form.phone)"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.phone.isPhoneNumberExisting"
          >The phone number is already existing.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-if="!$v.form.phone.isPhoneNumber"
          >The phone number is invalid.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label="Photo" label-for="workerPhoto">
        <b-form-file
          v-model="form.photo"
          ref="workerPhoto"
          accept="image/jpeg, image/png, image/gif"
          placeholder="Choose a photo or drop it here..."
          drop-placeholder="Drop photo here..."
        ></b-form-file>
        <b-link @click="$refs['workerPhoto'].reset()" class="link-small">Reset selection</b-link>
      </b-form-group>
      <h5 class="mt-4 mb-3" v-if="getUserProfile.role === 'admin'">Expert Settings</h5>
      <b-form-group
        label="Segment Properties"
        label-for="workerSegment"
        description="These properties (JSON) can be used to segment the worker within analytics."
        v-if="getUserProfile.role === 'admin'"
      >
        <b-form-textarea
          id="workerSegment"
          ref="workerSegment"
          placeholder='{ "property": "value" }'
          tabindex="5"
          rows="2"
          max-rows="3"
          v-model.trim="$v.form.segment.$model"
          :state="validateState($v.form.segment)"
        ></b-form-textarea>
      </b-form-group>
      <b-form-checkbox v-model="form.workerWelcomeNotification" value="true" class="mb-3">
        Send app invitation to worker
        <span
          class="ml-1 text-secondary"
          v-if="findNotificationTemplate('signupInvitation')"
          :title="findNotificationTemplate('signupInvitation').message"
          v-b-tooltip.hover
        >
          <b-icon icon="info-circle" font-scale="1"></b-icon
        ></span>
        <b-badge
          v-if="!listSettings.notifications.enabled"
          variant="danger"
          class="ml-2"
          title="Warning: Sending notifications is disabled in settings."
          v-b-tooltip.hover
          >Disabled</b-badge
        >
      </b-form-checkbox>
      <app-button-spinner :loading="isProcessing" :tabindex="6">Save</app-button-spinner>
      <b-button variant="light" @click="$router.go(-1)" class="btn-minwidth">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { required } from 'vuelidate/lib/validators'
import {
  validateState,
  isPhoneNumberExisting,
  isPhoneNumber,
  IsJsonString,
} from '@/utils/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      form: {
        firstname: '',
        lastname: '',
        phone: '',
        segment: '',
        workerWelcomeNotification: true,
      },
    }
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      phone: { isPhoneNumberExisting, isPhoneNumber },
      segment: { IsJsonString },
    },
  },
  computed: {
    ...mapGetters('app', ['isProcessing']),
    ...mapGetters('settings', ['listSettings', 'findNotificationTemplate']),
    ...mapGetters('auth', ['getUserProfile']),
  },
  components: {
    AppPageHeader,
    AppButtonSpinner,
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('workers', ['addWorker']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.addWorker(this.form)

      // Reset validation afterwards, otherwise isPhoneNumberExisting throws an error as entry exists in database
      this.$nextTick(() => this.$v.$reset())
    },
  },
  created() {
    this.SET_PAGELOADING(false)
  },
}
</script>

<style lang="scss" scoped></style>
