<template>
  <div v-show="!isPageLoading">
    <app-page-header title="References">
      <template v-if="listReferences.length">
        <div class="mr-2"></div>
      </template>
      <div>
        <b-button variant="primary" class="btn-minwidth mr-0" to="/references/add"
          >Add Reference</b-button
        >
      </div>
    </app-page-header>
    <div v-if="listReferences.length">
      <app-toolbar
        :defaultValues="{
          viewMode: 'list',
          sortDirection: 'desc',
          sortBy: 'createdOn',
        }"
        :sortOptions="[
          {
            label: 'creation date',
            key: 'createdOn',
          },
          {
            label: 'project',
            key: 'project',
          },
          {
            label: '# comments',
            key: 'comments',
          },
          {
            label: '# likes',
            key: 'likes',
          },
          {
            label: 'worker',
            key: 'worker',
          },
        ]"
        searchPlaceholder="Search by project title"
        @search="filter.search = $event"
        @changeViewMode="toolbar.viewMode = $event"
        @changeSortBy="toolbar.sortBy = $event"
        @changeSortDirection="toolbar.sortDirection = $event"
      >
        <template v-slot:additional-filters>
          <div class="ml-2">
            <multiselect
              :value="filter.worker.value"
              track-by="id"
              :show-labels="false"
              :internal-search="false"
              :options="filter.worker.options"
              @search-change="searchFilter"
              @input="updateQueryString"
              class="input-filter"
              placeholder="Filter by worker"
            >
              <template slot="option" slot-scope="{ option }">
                <div class="d-flex align-items-center">
                  <app-photo-user
                    class="mr-2"
                    :photo="option.photo"
                    size="s"
                    :placeholder="`${option.firstname} ${option.lastname}` | getInitials"
                  ></app-photo-user>
                  {{ option.firstname }} {{ option.lastname }}
                </div>
              </template>
              <template slot="noResult">No workers found.</template>
              <template slot="singleLabel" slot-scope="{ option }"
                >{{ findWorker(option).firstname }} {{ findWorker(option).lastname }}</template
              >
            </multiselect>
          </div>
        </template>
      </app-toolbar>

      <b-table
        ref="referenceTable"
        show-empty
        :items="sortedReferences({ sortBy: toolbar.sortBy, sortDirection: toolbar.sortDirection })"
        :filter="filter"
        :filter-function="searchTable"
        :fields="table"
        hover
      >
        <template v-slot:emptyfiltered>
          <app-search-no-results></app-search-no-results>
        </template>
        <template v-slot:cell(date)="{ item: reference }">
          {{ reference.createdOn | formatDate }}
        </template>
        <template v-slot:cell(project)="{ item: reference }">
          <b-link
            v-b-modal.modal-reference
            :to="{
              name: 'showReference',
              params: { id: reference.id },
              query: { ...$route.query },
            }"
            class="text-decoration-none"
          >
            <strong>{{ reference.project }}</strong>
            <span
              v-if="reference.photos.length"
              class="ml-2"
              v-b-tooltip.hover
              title="This reference project has pictures attached"
            >
              <b-icon icon="images" font-scale="1.2" class="text-gray-500"></b-icon>
            </span>
          </b-link>
        </template>
        <template v-slot:cell(comments)="{ item: reference }">
          {{ reference.comments ? reference.comments.length : 0 }}
        </template>
        <template v-slot:cell(likes)="{ item: reference }"
          ><span class="cursor-default" :id="`likes-${reference.id}`">
            {{ reference.likes ? reference.likes.length : 0 }}
          </span>
          <b-tooltip
            :target="`likes-${reference.id}`"
            v-if="reference.likes"
            custom-class="tooltip-small"
          >
            <div v-for="(like, index) in reference.likes" :key="`like-${reference.id}-${index}`">
              <div v-if="findWorker(like).firstname">
                {{ findWorker(like).firstname }} {{ findWorker(like).lastname }}
              </div>
              <div v-else>Deleted Worker</div>
            </div>
          </b-tooltip>
        </template>
        <template v-slot:cell(worker)="{ item: reference }">
          <content-placeholders v-if="!reference.isPopulated" class="w-75">
            <content-placeholders-text :lines="1" />
          </content-placeholders>
          <div v-else class="d-flex">
            <app-photo-user
              class="mr-2"
              :photo="reference.worker.photo"
              size="s"
              :placeholder="
                reference.worker.id
                  ? $options.filters.getInitials(
                      `${reference.worker.firstname} ${reference.worker.lastname}`
                    )
                  : ''
              "
            ></app-photo-user>
            {{ reference.worker.firstname }}
            {{ reference.worker.lastname }}
          </div>
        </template>
        <template v-slot:cell(actions)="{ item: reference }">
          <app-reference-actions
            :reference="reference"
            @delete="selectedItem = $event"
          ></app-reference-actions>
        </template>
      </b-table>
    </div>
    <div v-else class="text-center mt-10">
      <b-icon icon="star" font-scale="2.5" class="mb-3 text-gray-500"></b-icon>
      <h5>No references yet</h5>
      <p>
        There are currently no reference projects yet.
        <br />Your workers can add them as soon as you invite them to the app.
      </p>
    </div>
    <app-confirm-modal title="Delete Reference" @ok="deleteReference({ id: selectedItem.id })">
      <div>
        Are you sure you want to delete the reference
        <strong>{{ selectedItem.project }}</strong> permanently? This action can not be undone.
      </div>
    </app-confirm-modal>
    <router-view @getNext="getNext" :next="next"></router-view>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppConfirmModal from '@/components/ui/modal.confirm.vue'
import AppToolbar from '@/components/ui/toolbar.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import AppReferenceActions from '@/components/references/actions.dropdown.vue'
import AppSearchNoResults from '@/components/ui/search.noresults.vue'
// import AppCard from '@/components/ui/card.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      selectedItem: {},
      table: [
        { key: 'date', tdClass: 'col-date' },
        { key: 'project' },
        { key: 'comments' },
        { key: 'likes' },
        { key: 'worker', tdClass: 'col-worker' },
        {
          key: 'actions',
          label: '',
          tdClass: 'col-actions text-right',
        },
      ],
      filter: {
        worker: {
          value: this.$route.query.worker,
          options: [],
        },
        search: this.$route.query.search,
      },
      toolbar: {
        viewMode: '',
        sortBy: '',
        sortDirection: '',
      },
      next: null,
    }
  },
  components: {
    AppPageHeader,
    AppConfirmModal,
    AppToolbar,
    AppReferenceActions,
    AppSearchNoResults,
    AppPhotoUser,
    // AppCard,
  },
  watch: {
    $route: function () {
      // Reset filter value if query string is not set anymore
      this.filter.worker.value = this.$route.query.worker ? this.$route.query.worker : []
    },
  },
  computed: {
    ...mapGetters('app', ['isPageLoading']),
    ...mapGetters('workers', ['listWorkers', 'findWorker']),
    ...mapGetters('references', ['listReferences', 'sortedReferences']),
  },
  methods: {
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('workers', ['loadWorkers']),
    ...mapActions('references', ['loadReferences', 'deleteReference']),
    getNext(val) {
      const tableContent = this.$refs.referenceTable.sortedItems
      const index = tableContent.map((x) => x.id).indexOf(val.id)

      if (val.type === 'next')
        this.next =
          typeof tableContent[index + 1] !== 'undefined' ? tableContent[index + 1].id : null
      if (val.type === 'previous')
        this.next =
          typeof tableContent[index - 1] !== 'undefined' ? tableContent[index - 1].id : null
    },
    // Search worker (filter select field)
    searchFilter(query) {
      this.filter.worker.options = this.listWorkers.filter((worker) =>
        `${worker.firstname} ${worker.lastname}`.toLowerCase().includes(query.toLowerCase())
      )
    },
    searchTable(row, query) {
      // If value is array or undefined, then worker filter is not set
      const workerFilterSet =
        !Array.isArray(query.worker.value) && typeof query.worker.value !== 'undefined'

      // If no filter active, show all results
      if (!query.search && !workerFilterSet) return true
      // If worker filter enabled but worker not matching, return false
      if (workerFilterSet && row.worker.id !== query.worker.value) return false
      // If search string is set but not matching, return false
      if (query.search && !row.project.toLowerCase().includes(query.search.toLowerCase()))
        return false
      // Otherwise return true
      return true
    },
    // Update query string on changing workers filter
    updateQueryString(value) {
      // Set query string if not the same item again (if you select the same twice it resets the filter)
      if (value && value.id !== this.$route.query.worker) {
        this.$router.push({ query: { worker: value.id } }).catch((err) => err)
      } else {
        this.$router.replace({ query: {} })
      }
    },
  },
  async created() {
    this.SET_PAGELOADING(true)

    // Populate store
    await Promise.all([this.loadWorkers(false), this.loadReferences()])

    this.filter.worker.options = this.listWorkers

    this.SET_PAGELOADING(false)
  },
}
</script>

<style lang="scss">
.input-filter {
  min-width: 230px;

  .multiselect__tags {
    min-height: 38px !important;
  }
}
</style>