import * as fb from '@/firebase'
import { trackEvent } from '@/utils/analytics'

const state = {
  all: {},
  default: [
    { name: 'Sauberkeit Arbeitsplatz' },
    { name: 'Sauberkeit Ausführung' },
    { name: 'Geschwindigkeit' },
    { name: 'Auftreten' },
    { name: 'Fachkompetenz' },
  ],
}

const mutations = {
  SET_CATEGORIES(state, val) {
    state.all = val
  },
  ADD_CATEGORY(state, val) {
    state.all.push(val)
  },
  UPDATE_CATEGORY(state, { id, val }) {
    const category = state.all.find(category => category.id === id)
    Object.assign(category, { name: val })
  },
  DELETE_CATEGORY(state, id) {
    const index = state.all.map(category => category.id).indexOf(id)
    state.all.splice(index, 1)
  },
}

const getters = {
  listCategories(state) {
    return state.all
  },
  listPlainCategories(state) {
    return state.all.map(item => {
      return { id: item.id, name: item.name }
    })
  },
  listDefault(state) {
    return state.default
  },
}

const actions = {
  loadCategories: async ({ commit }) => {
    const snapshot = await fb.categoriesCollection.orderBy('createdOn').get()
    const categories = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    commit('SET_CATEGORIES', categories)
  },
  initCategories: function({ commit, getters }) {
    const defaultCategories = getters.listDefault

    // Add all default categories to database
    defaultCategories.forEach(category => {
      fb.categoriesCollection
        .add({
          createdOn: new Date(),
          updatedOn: new Date(),
          ...category,
        })
        .then(categoryRef => {
          // Once added to database, add to store
          commit('ADD_CATEGORY', { id: categoryRef.id, name: category.name })
        })
    })
  },
  addCategory: async function({ commit }, vm) {
    // Create empty category
    const categoryRef = await fb.categoriesCollection.add({
      createdOn: new Date(),
      updatedOn: new Date(),
    })

    // Add empty category to store
    commit('ADD_CATEGORY', { id: categoryRef.id, name: '' })
    trackEvent('Category Added')

    // Set autofocus to new item
    vm.$nextTick(() => vm.$refs[categoryRef.id][0].focus())
  },
  deleteCategory: async function({ commit }, id) {
    // Delete category from collection
    fb.categoriesCollection.doc(id).delete()
    // Delete category from store
    commit('DELETE_CATEGORY', id)
    trackEvent('Category Deleted')
  },
  updateCategory: async function({ commit }, { val, id }) {
    // Update category in database
    await fb.categoriesCollection.doc(id).update({ name: val, updatedOn: new Date() })

    // Update category in state
    commit('UPDATE_CATEGORY', { id, val })
    trackEvent('Category Updated')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
