<template>
  <div :class="`photo-user size-${size} round`" :style="`background-image: url(${photo})`">
    <span v-if="!photo && placeholder" :title="placeholder">{{ placeholder }}</span>
  </div>
</template>

<script>
export default {
  props: ['photo', 'size', 'placeholder'],
}
</script>

<style lang="scss" scoped>
.photo-user {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-color: rgba(#4a5396, 0.15);

  span {
    position: relative;
    color: #4a5396;
  }

  &.size-l {
    height: 75px;
    width: 75px;

    span {
      font-size: 1.1rem;
    }
  }

  &.size-m {
    height: 50px;
    width: 50px;

    span {
      font-size: 1rem;
    }
  }

  &.size-sm {
    height: 30px;
    width: 30px;

    span {
      font-size: 0.9rem;
    }
  }

  &.size-s {
    height: 25px;
    width: 25px;
    min-width: 25px;

    span {
      font-size: 0.8rem;
    }
  }

  &.round {
    border-radius: 50%;
    overflow: hidden;
  }

  span {
    font-size: 1.1rem;
  }
}
</style>
