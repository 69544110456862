<template>
  <div v-if="!isSidebarLoading">
    <b-card
      no-body
      :header="`Pending Withdrawal Requests (${findPendingWithdrawalRequests(worker.id).length})`"
      v-if="findPendingWithdrawalRequests(worker.id).length"
      class="mb-3 text-xsmall"
      bg-variant="warning"
      header-class="font-weight-bold"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="withdrawalRequest in findPendingWithdrawalRequests(worker.id).slice(0, 3)"
          :key="withdrawalRequest.id"
          variant="warning"
          class="position-relative withdrawal-request"
        >
          <div class="d-flex justify-content-between">
            <strong>{{ withdrawalRequest.requestedOn | formatDate }}</strong>
            <span class="request-amount"
              >{{ withdrawalRequest.amount }} CHF
              <b-link
                @click.prevent="
                  processWithdrawalRequest({ workerId: worker.id, requestId: withdrawalRequest.id })
                "
                v-b-tooltip.hover
                title="Mark this withdrawal request as processed. You still have to add the transaction manually."
              >
                <b-icon icon="x" font-scale="1" class="cursor-pointer"></b-icon> </b-link
            ></span>
          </div>
          <p v-if="withdrawalRequest.comment" class="mb-0">{{ withdrawalRequest.comment }}</p>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card class="mb-2 card-highlight" bg-variant="light" no-body>
      <b-card-body class="p-3">
        <h6 class="mb-0 d-flex justify-content-between">
          <span>Balance</span>
          <span>{{ worker.balance }} CHF</span>
        </h6>
      </b-card-body>
    </b-card>
    <div class="mb-2">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Type" label-for="transactionType">
          <b-form-radio-group
            v-model="form.type"
            :options="[
              { text: 'Grant Bonus', value: 'add' },
              { text: 'Withdrawal', value: 'sub' },
            ]"
            name="transactionType"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="Amount" label-for="transactionAmount">
          <b-input-group append="CHF">
            <b-form-input
              autofocus
              id="transactionAmount"
              :state="validateState($v.form.amount)"
              autocomplete="off"
              tabindex="1"
              placeholder="0"
              v-model.trim="$v.form.amount.$model"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Comment" label-for="transactionComment">
          <b-form-textarea
            id="transactionComment"
            tabindex="2"
            rows="3"
            max-rows="6"
            v-model.trim="form.comment"
          ></b-form-textarea>
        </b-form-group>
        <b-form-checkbox v-model="form.notifyWorker" :value="true" class="mb-3">
          Send notification to worker
          <span class="ml-1 text-secondary" :title="tooltip" v-b-tooltip.hover>
            <b-icon icon="info-circle" font-scale="1"></b-icon>
          </span>
          <b-badge
            v-if="!listSettings.notifications.enabled"
            variant="danger"
            class="ml-2"
            title="Warning: Sending notifications is disabled in settings."
            v-b-tooltip.hover
            >Disabled</b-badge
          >
        </b-form-checkbox>
        <app-button-spinner :loading="isProcessing" block :tabindex="3"
          >Add Transaction</app-button-spinner
        >
      </b-form>
    </div>
    <template v-if="worker.transactions.length">
      <b-card
        v-for="transaction in worker.transactions"
        :key="transaction.id"
        class="mb-2 card-item"
        no-body
      >
        <b-card-body class="p-3">
          <div class="d-flex justify-content-between">
            <div>
              <strong>
                <span v-if="transaction.type === 'add'">+</span>
                <span v-else-if="transaction.type === 'sub'">-</span>
                {{ transaction.amount }} CHF
              </strong>
            </div>
            <span class="text-secondary">
              <b-icon
                icon="trash"
                font-scale="1"
                class="card-item-delete"
                v-if="!isDeleting"
                @click="onDelete({ workerId: id, id: transaction.id })"
              ></b-icon>
              <b-spinner
                v-else
                class="card-item-delete-processing"
                type="grow"
                variant="secondary"
              ></b-spinner>
              <span class="card-item-date">{{ transaction.createdOn | formatDate }}</span>
            </span>
          </div>
          <div v-if="transaction.comment" class="text-secondary font-italic text-multiline">
            {{ transaction.comment }}
          </div>
        </b-card-body>
      </b-card>
    </template>
    <template v-else>
      <div class="text-center mt-10">
        <b-icon icon="cash-stack" font-scale="2.5" class="mb-3 text-secondary"></b-icon>
        <p class="mb-3">No transactions for {{ worker.firstname }} {{ worker.lastname }} yet.</p>
      </div>
    </template>
  </div>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { required, numeric } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['id'],
  data: function () {
    return {
      ratings: [],
      isDeleting: false, // Indicate if deleting is in progress
      form: {
        type: 'add',
        amount: null,
        comment: '',
        notifyWorker: true,
      },
      transactions: [],
      tooltip: '',
    }
  },
  validations: {
    form: {
      amount: { required, numeric },
    },
  },
  computed: {
    worker: function () {
      return this.findWorker(this.id)
    },
    ...mapGetters('app', ['isSidebarLoading', 'isProcessing']),
    ...mapGetters('workers', ['findWorker', 'findPendingWithdrawalRequests']),
    ...mapGetters('settings', ['listSettings', 'findNotificationTemplate']),
  },
  watch: {
    'form.type': function () {
      this.tooltip =
        this.form.type === 'add'
          ? this.findNotificationTemplate('transactionsGrantbonus').message
          : this.findNotificationTemplate('transactionsWithdrawal').message
    },
  },
  components: {
    AppButtonSpinner,
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_SIDEBARLOADING', 'SET_SIDEBARTITLE']),
    ...mapActions('workers', [
      'loadWorker',
      'deleteWorkerTransaction',
      'addWorkerTransaction',
      'processWithdrawalRequest',
    ]),
    async onDelete({ workerId, id }) {
      this.isDeleting = true

      await this.deleteWorkerTransaction({ workerId, id })

      this.isDeleting = false
    },
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.addWorkerTransaction({ form: this.form, id: this.id, balance: this.worker.balance })

      this.form.amount = null
      this.form.comment = ''
      this.form.type = 'add'

      // Reset validation afterwards to avoid that resetting causes form errors
      this.$nextTick(() => this.$v.$reset())
    },
  },
  async created() {
    this.SET_SIDEBARLOADING(true)

    await this.loadWorker({ awaitPopulated: true, id: this.id })

    // Set initial tooltip content
    this.tooltip = this.findNotificationTemplate('transactionsGrantbonus').message

    // Set sidebar title
    this.SET_SIDEBARTITLE(`Transactions ${this.worker.firstname} ${this.worker.lastname}`)

    this.SET_SIDEBARLOADING(false)
  },
}
</script>

<style lang="scss" scoped>
.request-amount {
  margin-right: -5px;

  svg {
    color: #7c6c4a;
  }
}
</style>