<template>
  <b-button-toolbar class="d-flex flex-row justify-content-between no-outline mb-2">
    <div class="mb-3 d-flex">
      <div>
        <app-search v-model="filter.search" :placeholder="searchPlaceholder"></app-search>
      </div>
      <slot name="additional-filters"></slot>
    </div>
    <div class="mb-3">
      <b-dropdown variant="light" right :text="`Sort by ${toolbar.sortBy.label}`">
        <b-dropdown-item
          v-for="item in sortOptions"
          :key="item.key"
          href="#"
          @click.prevent="setSortBy({ label: item.label, key: item.key })"
          >Sort by {{ item.label }}</b-dropdown-item
        >
      </b-dropdown>
      <b-button
        variant="light"
        v-b-tooltip="{ placement: 'bottom', customClass: 'tooltip-small' }"
        :title="toolbar.sortDirectionTooltip"
        :class="`btn-sort btn-sort-${toolbar.sortDirection} ml-2`"
        @click="setSortDirection(toolbar.sortDirection === 'desc' ? 'asc' : 'desc')"
      ></b-button>
      <b-button-group class="ml-2" right>
        <b-button
          variant="light"
          :pressed="toolbar.viewMode === 'list'"
          @click="setViewMode('list')"
          ><b-icon icon="list" font-scale="1.1"></b-icon
        ></b-button>
        <b-button
          variant="light"
          :pressed="toolbar.viewMode === 'card'"
          @click="setViewMode('card')"
          ><b-icon icon="grid" font-scale="1.1"></b-icon
        ></b-button>
      </b-button-group>
    </div>
  </b-button-toolbar>
</template>

<script>
import AppSearch from '@/components/ui/form.search.vue'

export default {
  props: ['sortOptions', 'defaultValues', 'searchPlaceholder'],
  data: function () {
    return {
      toolbar: {
        viewMode: '',
        sortDirection: '',
        sortDirectionTooltip: '',
        sortBy: {
          label: '',
          key: '',
        },
      },
      filter: {
        search: this.$route.query.search || '',
      },
    }
  },
  methods: {
    setSortDirection(sortDirection, updateURL = true) {
      this.toolbar.sortDirection = sortDirection
      this.toolbar.sortDirectionTooltip =
        this.toolbar.sortDirection === 'desc'
          ? 'Sort data descending (Z-A, 1-0)'
          : 'Sort data ascending (A-Z, 0-1) '

      this.$emit('changeSortDirection', this.toolbar.sortDirection)

      // Push sortDirection to query
      if (updateURL)
        this.$router
          .push({ query: { ...this.$route.query, sortDirection: this.toolbar.sortDirection } })
          .catch((err) => err)
    },
    setSortBy(sortBy, updateURL = true) {
      this.toolbar.sortBy = sortBy
      this.$emit('changeSortBy', sortBy.key)

      // Push sortBy to query
      if (updateURL)
        this.$router
          .push({ query: { ...this.$route.query, sortBy: sortBy.key } })
          .catch((err) => err)
    },
    setViewMode(viewMode, updateURL = true) {
      this.toolbar.viewMode = viewMode
      this.$emit('changeViewMode', viewMode)

      // Push viewMode to query
      if (updateURL)
        this.$router
          .push({ query: { ...this.$route.query, viewMode: viewMode } })
          .catch((err) => err)
    },
    // Set default params based on URL
    initQueryParams() {
      // 1. Set sortBy if present in query
      const sortBy = this.$route.query.sortBy ? this.$route.query.sortBy : this.defaultValues.sortBy
      const querySortSet = this.sortOptions.find((x) => x.key === sortBy)

      if (querySortSet) {
        this.toolbar.sortBy = querySortSet
        this.setSortBy(querySortSet, false)
      }

      // 2. Set viewMode if present in query
      this.toolbar.viewMode = this.$route.query.viewMode
        ? this.$route.query.viewMode
        : this.defaultValues.viewMode
      this.setViewMode(this.toolbar.viewMode, false)

      // 3. Set sortDirection if present in query
      this.toolbar.sortDirection = this.$route.query.sortDirection
        ? this.$route.query.sortDirection
        : this.defaultValues.sortDirection
      this.setSortDirection(this.toolbar.sortDirection, false)

      // 4. Init search value
      this.$emit('search', this.filter.search)
    },
  },
  watch: {
    $route: function () {
      // Reset toolbar values if clicked on main navigation
      if (!this.$route.query.length) this.initQueryParams()
    },
    'filter.search': function () {
      this.$emit('search', this.filter.search)
    },
  },
  components: {
    AppSearch,
  },
  created() {
    // Init query params when component gets loaded
    this.initQueryParams()
  },
}
</script>

<style lang="scss">
// .btn-toolbar {
//   .input-filter .multiselect__tags {
//     min-height: 18px !important;
//     padding: 3px 40px 0 8px;
//   }

//   .multiselect__single,
//   .multiselect__input {
//     font-size: 0.875rem;
//   }

//   .multiselect__input {
//     padding-top: 2px;
//     margin-bottom: 4px;
//   }

//   .multiselect__select {
//     height: 30px;
//   }
// }
</style>