import Vue from 'vue'
import App from './App.vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import router from './router'
import store from './store'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'
import { filtersInit } from './utils/filters'
import VueContentPlaceholders from 'vue-content-placeholders'
import { auth } from './firebase'
import $axios from './utils/axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'

// Init Vue Filters
filtersInit(Vue)

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()]
})

// Install BootstrapVue, Icons, Lodash
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueContentPlaceholders)
Vue.use(VueLodash, { lodash })
Vue.component('multiselect', Multiselect)

Vue.prototype.$axios = $axios
Vue.config.productionTip = false

$axios.interceptors.request.use(config => {
  const token = store.getters['auth/getToken']

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

let app: Vue

auth.onAuthStateChanged(async user => {
  if (user) {
    // Populate store (Fetch user profile & settings)
    await Promise.all([
      store.dispatch('auth/fetchUserProfile', user),
      store.dispatch('settings/loadSettings'),
    ])
  }

  if (!app) {
    // Store axios in store property
    store['$axios'] = $axios

    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')

    // Init Bugsnag
    const bugsnagVue = Bugsnag.getPlugin('vue')
    bugsnagVue.installVueErrorHandler(Vue)

    // Store app in store property $app to make it accessible within store
    store['$app'] = app
  }
})
