<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Notifications"> </app-page-header>
    <div class="flex-grow-1 d-flex flex-row">
      <div class="notifications-sidebar">
        <b-card no-body header="Latest Conversations" class="border-right-0 rounded-0">
          <b-list-group flush class="rounded-0">
            <b-list-group-item
              v-for="(worker, index) in listNotificationsByWorker"
              :key="index"
              :active="index === selected ? true : false"
              class="d-flex justify-content-between align-items-center no-outline"
              @click="selected = index"
              button
            >
              <div class="d-flex align-items-center flex-grow-1">
                <app-photo-user
                  :photo="findWorker(index).photo"
                  size="m"
                  :placeholder="
                    `${findWorker(index).firstname} ${findWorker(index).lastname}` | getInitials
                  "
                ></app-photo-user>
                <div class="ml-3 d-flex flex-column flex-grow-1">
                  <div>
                    <strong
                      >{{ findWorker(index).firstname }} {{ findWorker(index).lastname }}</strong
                    >
                  </div>
                  <div class="text-small truncate">{{ worker[0].body }}</div>
                </div>
              </div>
              <span class="text-xxsmall text-gray-500 text-right">{{
                worker[0].createdOn | formatTimeRelative
              }}</span></b-list-group-item
            >
          </b-list-group>
        </b-card>
      </div>
      <div class="d-flex flex-column border-left pl-4">
        <app-conversation
          :notifications="this.listNotificationsByWorker[selected]"
          :worker="findWorker(selected)"
        ></app-conversation>
      </div>
    </div>
  </div>
</template>

<script>
import AppConversation from '@/components/ui/conversation.vue'
import AppPageHeader from '@/components/layout/page-header.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      selected: null,
    }
  },
  components: {
    AppPageHeader,
    AppConversation,
    AppPhotoUser,
  },
  methods: {
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('workers', ['loadWorkers']),
    ...mapActions('notifications', [
      'loadNotifications',
      'subscribeAllNotifications',
      'unsubscribeAllNotifications',
    ]),
    setSelected(id) {
      this.selected.worker = this.findWorker(id)
      this.selected.notifications = this.listNotificationsByWorker[id]
    },
  },
  computed: {
    ...mapGetters('app', ['isPageLoading', 'isProcessing']),
    ...mapGetters('notifications', ['listNotificationsByWorker', 'listNotificationsSort']),
    ...mapGetters('workers', ['listWorkers', 'findWorker']),
  },
  async created() {
    this.SET_PAGELOADING(true)

    await Promise.all([this.loadWorkers(false), this.loadNotifications()])
    this.subscribeAllNotifications()

    this.selected = Object.keys(this.listNotificationsByWorker)[0]

    this.SET_PAGELOADING(false)
  },
  destroyed() {
    this.unsubscribeAllNotifications()
  },
}
</script>

<style lang="scss" scoped>
.truncate {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-view {
  flex-direction: column;
  display: flex;
}

.notifications-sidebar {
  min-width: 400px;
}
</style>