<template>
  <div v-if="!isSidebarLoading" class="h-100 d-flex">
    <app-conversation
      :notifications="listWorkerNotifications(id)"
      :worker="worker"
    ></app-conversation>
  </div>
</template>

<script>
import AppConversation from '@/components/ui/conversation.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['id'],
  computed: {
    ...mapGetters('app', ['isSidebarLoading']),
    ...mapGetters('workers', ['findWorker']),
    ...mapGetters('notifications', ['listWorkerNotifications']),
  },
  components: {
    AppConversation,
  },
  watch: {
    // Probably not needed as you can't switch from notifications to another workers notifications
    id: async function () {
      this.unsubscribeNotifications()
      await this.loadWorkerNotifications(this.id)
      this.subscribeNotifications(this.id)
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SIDEBARLOADING', 'SET_SIDEBARTITLE', 'SET_SIDEBARCLASS']),
    ...mapActions('workers', ['loadWorker']),
    ...mapActions('notifications', [
      'loadWorkerNotifications',
      'subscribeWorkerNotifications',
      'unsubscribeWorkerNotifications',
    ]),
  },
  async created() {
    this.SET_SIDEBARLOADING(true)
    this.SET_SIDEBARCLASS('sticky-footer')

    await this.loadWorkerNotifications(this.id)
    this.subscribeWorkerNotifications(this.id)

    // Reference to worker in store
    this.worker = this.findWorker(this.id)

    // Set sidebar title
    this.SET_SIDEBARTITLE(`Conversation with ${this.worker.firstname} ${this.worker.lastname}`)

    this.SET_SIDEBARLOADING(false)
  },
  destroyed() {
    this.SET_SIDEBARCLASS('')
    this.unsubscribeWorkerNotifications()
  },
}
</script>
