import VuePluralize from 'vue-pluralize'
import moment from 'moment'

const filtersInit = Vue => {
  Vue.use(VuePluralize)

  Vue.filter('formatDate', value => {
    return value ? moment.unix(value.seconds).format('DD.MM.YYYY') : ''
  })

  Vue.filter('formatDateTime', value => {
    return value ? moment.unix(value.seconds).format('DD.MM.YYYY HH:mm') : ''
  })

  Vue.filter('formatTimeRelative', value => {
    if(!value) return 'Never'

    const now = moment(new Date())
    const compare = moment.unix(value.seconds)
    const duration = moment.duration(now.diff(compare))
    const hours = duration.asHours()

    if (hours < 24) {
      return moment.unix(value.seconds).format('HH:mm')
    } else if (hours < 144) {
      return moment.unix(value.seconds).format('dddd')
    } else {
      return moment.unix(value.seconds).format('DD.MM.YYYY')
    }
  })

  Vue.filter('getInitials', value => {
    return value
      ? value
          .split(' ')
          .map(item => item.charAt(0).toUpperCase())
          .join('')
      : ''
  })
}

export { filtersInit }
