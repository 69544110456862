import * as fb from '@/firebase'
import router from '@/router/index'

import { trackInit } from '@/utils/analytics'
import { toastError } from '@/components/ui/toast'

// State object
const state = {
  userProfile: {},
  token: null,
}

// Mutations
const mutations = {
  SET_USER_PROFILE(state, val) {
    state.userProfile = val
  },
  UPDATE_USER_PROFILE(state, val) {
    Object.assign(state.userProfile, val)
  },
  SET_TOKEN(state, val) {
    state.token = val
  },
}

// Getter functions
const getters = {
  getUserProfile(state) {
    return state.userProfile
  },
  getToken(state) {
    return state.token
  },
}
// Actions
const actions = {
  login: async function({ commit }, form) {
    commit('app/SET_PROCESSING', true, { root: true })

    fb.auth
      .signInWithEmailAndPassword(form.email, form.password)
      .then(() => {
        commit('app/SET_PROCESSING', false, { root: true })
      })
      .catch(err => {
        commit('app/SET_PROCESSING', false, { root: true })

        toastError(this, err.message)
      })
  },
  fetchUserProfile: async ({ commit }, user) => {
    const tokenData = await user.getIdTokenResult()

    trackInit(user)

    // Set user profile in state
    commit('SET_USER_PROFILE', {
      uid: user.uid,
      token: tokenData.token,
      role: tokenData.claims.role,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    })

    // Change route to dashboard if coming from login or register
    if (router.currentRoute.name === 'login' || router.currentRoute.name === 'register')
      router.push('/dashboard').catch(err => err)
  },
  logout: async ({ commit }) => {
    fb.auth.signOut().then(() => {
      // clear userProfile and redirect to /login
      commit('SET_USER_PROFILE', {})
      router.push('/login').catch(err => err)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
