<template>
  <div>
    <content-placeholders v-if="!worker.isPopulated">
      <content-placeholders-text :lines="1" />
    </content-placeholders>
    <router-link
      v-else
      class="table-link"
      sidebar-right
      :to="{ name: 'references', query: { worker: worker.id } }"
    >
      <span
        v-if="findWorkerReferences(worker.id)"
        v-b-tooltip.hover
        title="Number of reference projects from this worker"
      >
        <b-icon icon="briefcase" font-scale="1.2" class="mr-2"></b-icon>
        {{ findWorkerReferences(worker.id).length }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['worker'],
  computed: {
    ...mapGetters('references', ['findWorkerReferences']),
  },
}
</script>