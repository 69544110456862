import * as fb from '@/firebase'

import { set } from 'lodash'

const state = {
  all: {},
  twilio: {},
  default: {
    app: {
      url: 'https://app.we-build.ch'
    },
    mail: {
      receivers: 'flumueller@me.com'
    },
    notifications: {
      enabled: true,
      sender: '+41 79 807 87 05',
      receiverWhitelist: '+41 79 548 96 56, +48 50 039 60 98',
      templates: [
        {
          id: 'signupInvitation',
          label: 'Invitation',
          message:
            'Hallo {{worker.firstname}} \nWir nutzen bei der Suter Gruppe neu die App WeBuild. Darüber kannst du Bonuszahlungen erhalten, Bewertungen einholen und Projekte erfassen, auf welche du besonders stolz bist. Melde dich hier an: {{url}}',
          fieldnames: '{{worker.firstname}}, {{worker.lastname}}, {{url}}',
        },
        {
          id: 'transactionsGrantbonus',
          label: 'Transaction Grant Bonus',
          message:
            'Hallo {{worker.firstname}} \nDu hast für deine tolle Arbeit soeben eine Bonuszahlung von {{transaction.amount}} CHF erhalten \ud83d\ude80. In der WeBuild App kannst du dir den Bonus jederzeit auszahlen lassen. {{url}}',
          fieldnames: '{{worker.firstname}}, {{worker.lastname}}, {{transaction.amount}}, {{url}}',
        },
        {
          id: 'transactionsWithdrawal',
          label: 'Transaction Withdrawal',
          message:
            'Hallo {{worker.firstname}} \nWir haben soeben eine Bonusauszahlung von {{transaction.amount}} CHF ausgelöst \ud83d\ude80. Der Betrag wird dir innert kürze auf dein TWINT Konto gutgeschrieben.',
          fieldnames: '{{worker.firstname}}, {{worker.lastname}}, {{transaction.amount}}',
        },
        {
          id: 'reviewNew',
          label: 'New Review',
          message:
            'Hallo {{worker.firstname}} \nDu hast soeben eine neue Bewertung \u2b50  deiner Arbeit am Projekt {{review.project}} von {{review.fullname}}, {{review.company}} via WeBuild erhalten. {{url}}',
          fieldnames:
            '{{worker.firstname}}, {{worker.lastname}}, {{review.project}}, {{review.fullname}}, {{review.company}}, {{url}}',
        },
        {
          id: 'reviewNewWithBonus',
          label: 'New Review with Bonus',
          message:
            'Hallo {{worker.firstname}} \nDu hast soeben eine neue Bewertung \u2b50  deiner Arbeit am Projekt {{review.project}} via WeBuild erhalten. Dafür haben wir dir einen Bonus von {{bonus.amount}} CHF gutgeschrieben! {{url}}',
          fieldnames:
            '{{worker.firstname}}, {{worker.lastname}}, {{review.project}}, {{review.fullname}}, {{review.company}}, {{bonus.amount}, {{url}}',
        },
        {
          id: 'reviewRequest',
          label: 'Request a Review',
          message:
            'Hallo {{reviewer.fullname}} \n{{worker.firstname}} {{worker.lastname}} hat dich darum geben, eine Bewertung für seine Arbeit abzugeben. Es wäre toll, wenn du das machen würdest. {{url}}',
          fieldnames:
            '{{reviewer.fullname}}, {{worker.firstname}}, {{worker.lastname}}, {{url}}',
        },
        {
          id: 'reviewVerify',
          label: 'Verify a Review',
          message:
            'Hallo {{reviewer.fullname}} \nVielen Dank für die Bewertung von {{worker.firstname}} {{worker.lastname}}. Um die Bewertung zu bestätigen, klicken Sie auf den folgenden Link. {{url}}',
          fieldnames:
            '{{reviewer.fullname}}, {{worker.firstname}}, {{worker.lastname}}, {{url}}',
        },
      ],
    },
  },
}

const mutations = {
  SET_SETTINGS(state, val) {
    state.all = val
  },
  SET_TWILIO(state, val) {
    state.twilio = val
  },
  SET_PROPERTY(state, { field, val }) {
    const path = 'all.' + field
    // Set updated property in store (e.g. all.notifications.enabled) based on the path
    set(state, path, val)
  },
  UPDATE_TEMPLATE(state, { id, defaultTemplate }) {
    const template = state.all.notifications.templates.find(template => template.id === id)
    Object.assign(template, defaultTemplate)
  },
}

const getters = {
  listSettings(state) {
    return state.all
  },
  findNotificationTemplate(state) {
    return id => {
      return state.all.notifications.templates.find(template => template.id === id)
    }
  },
  listDefault(state) {
    return state.default
  },
  getTwilioAccountInfo(state) {
    return state.twilio
  },
}

const actions = {
  loadSettings: async ({ commit }) => {
    const snapshot = await fb.settingsCollection.get()
    const settings = {}

    // Create object with all settings as root notes
    snapshot.docs.forEach(setting => {
      settings[setting.id] = setting.data()
    })

    commit('SET_SETTINGS', settings)
  },
  initSettings: function({ commit, getters }) {
    const defaultSettings = getters.listDefault

    // Store all settings in database as individual docs
    Object.keys(defaultSettings).forEach(setting => {
      fb.settingsCollection.doc(setting).set(defaultSettings[setting])
    })

    commit('SET_SETTINGS', defaultSettings)
  },
  loadTwilioAccountInfo: async function({ commit }) {
    const { data } = await this['$axios'].get('/api/twilio/twilioaccountinfo')

    commit('SET_TWILIO', data)
  },
  updateSettings: function({ getters }) {
    const settings = getters.listSettings

    // Store settings in database
    Object.keys(settings).forEach(async setting => {
      fb.settingsCollection.doc(setting).set(settings[setting])
    })
  },
  resetTemplate: function({ commit, getters }, id) {
    const defaultTemplate = getters.listDefault.notifications.templates.find(
      template => template.id === id
    )

    commit('UPDATE_TEMPLATE', { id, defaultTemplate })
    // Read updated settings from store to update database afterwards
    const settings = getters.listSettings

    Object.keys(settings).forEach(async setting => {
      fb.settingsCollection.doc(setting).set(settings[setting])
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
