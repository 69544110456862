<template>
  <b-dropdown variant="light" size="sm" no-caret right>
    <template v-slot:button-content>
      <b-icon icon="three-dots" class="text-secondary" font-scale="1.5"></b-icon>
    </template>
    <b-dropdown-item :to="{ name: 'editWorker', params: { id: worker.id } }">
      <b-icon icon="pencil" font-scale="1.2"></b-icon>Edit
    </b-dropdown-item>
    <b-dropdown-item
      sidebar-right
      :to="{ name: 'showReviews', params: { id: worker.id }, query: { ...$route.query } }"
    >
      <b-icon icon="star" font-scale="1.2"></b-icon>Reviews
    </b-dropdown-item>
    <b-dropdown-item
      sidebar-right
      :to="{ name: 'showTransactions', params: { id: worker.id }, query: { ...$route.query } }"
    >
      <b-icon icon="cash-stack" font-scale="1.2"></b-icon>Transactions
    </b-dropdown-item>
    <b-dropdown-item
      sidebar-right
      :to="{ name: 'showNotifications', params: { id: worker.id }, query: { ...$route.query } }"
    >
      <b-icon icon="envelope" font-scale="1.2"></b-icon>Notifications
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item @click.prevent="resendInvitation(worker)">
      <b-icon icon="arrow-counterclockwise" font-scale="1.2"></b-icon>Resend Invitation
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item v-b-modal.deleteModal @click="$emit('delete', worker)">
      <b-icon icon="trash" font-scale="1.2"></b-icon>Delete
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['worker'],
  data: function () {
    return {
      deleteReferences: false,
    }
  },
  methods: {
    ...mapActions('workers', ['resendInvitation']),
  },
}
</script>