import * as fb from '@/firebase'
import { trackEvent } from '@/utils/analytics'

const state = {
  all: {},
  default: [
    { name: 'Kälte mit Kautschuck' },
    { name: 'Heizungen' },
    { name: 'Sanitär' },
    { name: 'Lüftung' },
    { name: 'Apparate (Alu, Blech)' },
  ],
}

const mutations = {
  SET_COMPETENCES(state, val) {
    state.all = val
  },
  ADD_COMPETENCE(state, val) {
    state.all.push(val)
  },
  UPDATE_COMPETENCE(state, { id, val }) {
    const competence = state.all.find(competence => competence.id === id)
    Object.assign(competence, { name: val })
  },
  DELETE_COMPETENCE(state, id) {
    const index = state.all.map(competence => competence.id).indexOf(id)
    state.all.splice(index, 1)
  },
}

const getters = {
  listCompetences(state) {
    return state.all
  },
  listDefault(state) {
    return state.default
  },
  findCompetence(state) {
    return id => {
      return state.all.find(competence => competence.id === id)
    }
  },
}

const actions = {
  loadCompetences: async ({ commit }) => {
    const snapshot = await fb.competencesCollection.orderBy('createdOn').get()
    const competences = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    commit('SET_COMPETENCES', competences)
  },
  initCompetences: function({ commit, getters }) {
    const defaultCompetences = getters.listDefault

    // Add all default competences to database
    defaultCompetences.forEach(competence => {
      fb.competencesCollection
        .add({
          createdOn: new Date(),
          updatedOn: new Date(),
          ...competence,
        })
        .then(competenceRef => {
          // Once added to database, add to store
          commit('ADD_COMPETENCE', { id: competenceRef.id, name: competence.name })
        })
    })
  },
  addCompetence: async function({ commit }, vm) {
    // Create empty competence
    const competenceRef = await fb.competencesCollection.add({
      createdOn: new Date(),
      updatedOn: new Date(),
    })

    // Add empty competence to store
    commit('ADD_COMPETENCE', { id: competenceRef.id, name: '' })
    trackEvent('Competence Added')

    // Set autofocus to new item
    vm.$nextTick(() => vm.$refs[competenceRef.id][0].focus())
  },
  deleteCompetence: async function({ commit }, id) {
    // Delete competence from collection
    fb.competencesCollection.doc(id).delete()
    // Delete competence from store
    commit('DELETE_COMPETENCE', id)
    trackEvent('Competence Deleted')
  },
  updateCompetence: async function({ commit }, { val, id }) {
    // Update competence in database
    await fb.competencesCollection.doc(id).update({ name: val, updatedOn: new Date() })

    // Update competence in state
    commit('UPDATE_COMPETENCE', { id, val })
    trackEvent('Competence Updated')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
