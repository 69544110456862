<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      v-if="notifications.length"
      class="notification-container flex-grow-1 d-flex flex-column-reverse justify-content-start"
      ref="notificationsContainer"
    >
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="mt-3 notification p-3"
        :class="[
          notification.type === 'outgoing'
            ? 'bg-primary text-light align-self-end'
            : 'bg-light align-self-start',
        ]"
      >
        <div class="d-flex justify-content-between mb-1">
          <div class="text-gray-500 text-small">
            {{ notification.createdOn | formatDateTime }}
          </div>
          <div
            v-if="notification.sent"
            class="sent-status"
            title="This notification has been successfully sent to the workers phone."
            v-b-tooltip.hover
          >
            <b-icon icon="check" font-scale="1.2"></b-icon>
          </div>
        </div>
        <p class="mb-0" v-html="notification.body.replace(/(?:\r\n|\r|\n)/g, '<br />')"></p>
      </div>
    </div>
    <div v-else>
      <div class="text-center no-results">
        <b-icon icon="envelope" font-scale="2.5" class="mb-3 text-secondary"></b-icon>
        <p class="mb-3">No conversation yet with {{ worker.firstname }} {{ worker.lastname }}.</p>
      </div>
    </div>
    <div class="mt-2">
      <b-form class="d-flex" @submit.stop.prevent="onSubmit" ref="notificationForm">
        <b-form-input
          id="notificationBody"
          ref="notificationBody"
          autofocus
          tabindex="1"
          v-model="form.body"
          class="mr-2"
          :state="validateState($v.form.body)"
          v-model.trim="$v.form.body.$model"
          placeholder="Type your notification…"
          autocomplete="off"
        ></b-form-input>
        <app-button-spinner :loading="isProcessing" :tabindex="2" css="no-class">
          <b-icon icon="envelope" font-scale="1.2"></b-icon>
        </app-button-spinner>
      </b-form>
      <div v-if="!listSettings.notifications.enabled" class="text-danger text-xsmall mt-2">
        <strong>Warning:</strong> Sending messages is
        <b-badge variant="danger">disabled</b-badge> in the settings.
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { required } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['notifications', 'worker'],
  data: function () {
    return {
      form: {
        body: '',
      },
    }
  },
  validations: {
    form: {
      body: { required },
    },
  },
  computed: {
    ...mapGetters('app', ['isProcessing']),
    ...mapGetters('settings', ['listSettings']),
  },
  components: {
    AppButtonSpinner,
  },
  methods: {
    validateState,
    ...mapActions('notifications', ['sendCustomNotification']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.sendCustomNotification({
        to: this.worker.phone,
        type: 'outgoing',
        workerId: this.worker.id,
        body: this.form.body,
      })

      this.form.body = ''
      this.$refs.notificationBody.focus()
      this.$nextTick(() => this.$v.$reset())
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-container {
  overflow-y: auto;
  height: 0px;
}

.notification {
  width: 85%;
  font-size: 0.9rem;
  border-radius: 0.6rem;

  &.align-self-start {
    border-bottom-left-radius: 0;
  }

  &.align-self-end {
    border-bottom-right-radius: 0;
  }

  .sent-status {
    opacity: 0.8;
  }
}
</style>
