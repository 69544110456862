<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Edit Worker"></app-page-header>
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col md>
          <h5 class="mb-3">Personal Data</h5>
          <b-form-group
            label="Firstname"
            label-for="workerFirstname"
            invalid-feedback="The firstname is required."
          >
            <b-form-input
              id="workerFirstname"
              tabindex="1"
              v-model.trim="$v.form.firstname.$model"
              :state="validateState($v.form.firstname)"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Lastname"
            label-for="workerLastname"
            invalid-feedback="The lastname is required."
          >
            <b-form-input
              id="workerLastname"
              tabindex="2"
              v-model.trim="$v.form.lastname.$model"
              :state="validateState($v.form.lastname)"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Jobtitle" label-for="workerJobtitle">
            <b-form-input id="workerJobtitle" tabindex="3" v-model="form.jobtitle"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Phone number"
            label-for="workerPhone"
            description="The phone number can currently not be modified on an existing worker."
          >
            <b-form-input
              lazy
              id="workerPhone"
              tabindex="4"
              v-model.trim="$v.form.phone.$model"
              :state="validateState($v.form.phone)"
              disabled
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.phone.isPhoneNumberExisting"
              >The phone number is already existing.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!$v.form.phone.isPhoneNumber"
              >The phone number is invalid.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Photo" label-for="workerPhoto">
            <div v-if="worker.photo" class="mb-2">
              <div class="img-removable">
                <app-photo-user
                  :photo="worker.photo"
                  size="l"
                  :placeholder="`${worker.firstname} ${worker.lastname}` | getInitials"
                ></app-photo-user>
                <a @click.prevent="deletePhoto(worker)">
                  <b-icon icon="x" font-scale="1.2" class="text-white"></b-icon>
                </a>
              </div>
            </div>
            <b-form-file
              v-model="form.photo"
              ref="workerPhoto"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Choose a photo or drop it here..."
              drop-placeholder="Drop photo here..."
            ></b-form-file>
            <b-link @click="$refs['workerPhoto'].reset()" class="link-small"
              >Reset selection</b-link
            >
          </b-form-group>
        </b-col>
        <b-col md>
          <h5 class="mb-3">Profile & Education</h5>
          <b-form-group label="Profile Description" label-for="workerProfile">
            <b-form-textarea
              id="workerProfile"
              ref="workerProfile"
              tabindex="5"
              rows="4"
              max-rows="4"
              v-model="form.profile"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Education" label-for="workerEducation">
            <b-form-textarea
              id="workerEducation"
              ref="workerEducation"
              tabindex="6"
              rows="4"
              max-rows="4"
              v-model="form.education"
            ></b-form-textarea>
          </b-form-group>
          <h5 class="mt-4 mb-3">Competences</h5>
          <app-multiselect
            :options="listCompetences"
            v-model="form.competences"
            placeholder="Add competence"
            placeholder-no-options="All competences added"
            trackBy="id"
            label="name"
          ></app-multiselect>
          <h5 class="mt-4 mb-3" v-if="getUserProfile.role === 'admin'">Expert Settings</h5>
          <b-form-group
            label="Segment Properties"
            label-for="workerSegment"
            description="These properties (JSON) can be used to segment the worker within analytics."
            v-if="getUserProfile.role === 'admin'"
          >
            <b-form-textarea
              id="workerSegment"
              ref="workerSegment"
              placeholder='{ "property": "value" }'
              tabindex="7"
              rows="2"
              max-rows="3"
              v-model.trim="$v.form.segment.$model"
              :state="validateState($v.form.segment)"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <app-button-spinner :loading="isProcessing" :tabindex="8">Save</app-button-spinner>
      <b-button variant="light" @click="$router.go(-1)" class="btn-minwidth">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import AppMultiselect from '@/components/ui/multiselect.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { required } from 'vuelidate/lib/validators'
import {
  validateState,
  isPhoneNumberExisting,
  isPhoneNumber,
  IsJsonString,
} from '@/utils/validators'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: ['id'],
  data: function () {
    return {
      form: {
        firstname: '',
        lastname: '',
        phone: '',
        segment: '',
        competences: [],
      },
      worker: {},
    }
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      phone: { isPhoneNumber, isPhoneNumberExisting },
      segment: { IsJsonString },
    },
  },
  computed: {
    ...mapGetters('app', ['isProcessing', 'isPageLoading']),
    ...mapGetters('competences', ['listCompetences']),
    ...mapGetters('workers', ['findWorker']),
    ...mapGetters('auth', ['getUserProfile']),
  },
  components: {
    AppPageHeader,
    AppButtonSpinner,
    AppMultiselect,
    AppPhotoUser,
  },
  methods: {
    validateState,
    ...mapActions('workers', ['updateWorker', 'loadWorker', 'deletePhoto']), // , 'populateFields'
    ...mapActions('competences', ['loadCompetences']),
    ...mapMutations('app', ['SET_PAGELOADING']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.updateWorker(this.form)
    },
  },
  async created() {
    this.SET_PAGELOADING(true)

    // Populate worker in store
    await Promise.all([
      this.loadWorker({ awaitPopulated: true, id: this.id }),
      this.loadCompetences(),
    ])

    // Create local copy of the worker for form manipulation
    this.form = this._.cloneDeep(this.findWorker(this.id))

    // Reset photo so it's not interferring with form-field
    this.form.photo = null
    // Reference to worker in store (needed to display photo)
    this.worker = this.findWorker(this.id)

    this.SET_PAGELOADING(false)
  },
}
</script>
