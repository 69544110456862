<template>
  <div id="app" class="d-flex flex-column h-100">
    <app-header v-if="showAppHeader"></app-header>
    <b-container class="h-100">
      <b-row class="h-100">
        <b-col>
          <app-spinner v-if="isPageLoading" css="page-loader" text="Loading data..."></app-spinner>
          <router-view class="main-view" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AppHeader from '@/components/layout/app-header'
import AppSpinner from '@/components/ui/spinner.vue'

export default {
  components: {
    AppHeader,
    AppSpinner,
  },
  computed: {
    ...mapGetters('app', ['isPageLoading']),
    ...mapState('auth', ['userProfile']),
    showAppHeader() {
      return Object.keys(this.userProfile).length
    },
  },
}
</script>