<template>
  <div>
    <content-placeholders v-if="!worker.isPopulated">
      <content-placeholders-text :lines="1" />
    </content-placeholders>
    <router-link
      v-else
      class="table-link"
      sidebar-right
      :to="{
        name: 'showTransactions',
        params: { id: worker.id },
        query: { ...$route.query },
      }"
    >
      {{ worker.balance }}
      <span class="text-xxsmall">CHF</span>
      <span
        v-if="findPendingWithdrawalRequests(worker.id).length"
        v-b-tooltip.hover
        class="text-xsmall"
        title="This worker has at least one pending withdrawal request."
        ><b-icon icon="exclamation-circle-fill" font-scale="1" class="ml-1 text-warning"></b-icon
      ></span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['worker'],
  computed: {
    ...mapGetters('workers', ['findPendingWithdrawalRequests']),
  },
}
</script>

<style lang="scss">
.card {
  text-decoration: none;
  position: relative;

  &:hover {
    background-color: rgba(#f4f4f5, 0.3);
  }
}
</style>
