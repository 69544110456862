import Vue from 'vue'
import * as fb from '@/firebase'
import router from '@/router/index'
import { generateUUID, cleanString } from '@/utils'
import { isPhoneNumber } from '@/utils/validators'
import { toastError, toastSuccess } from '@/components/ui/toast'
import { cloneDeep, get } from 'lodash'
import { trackEvent } from '@/utils/analytics'

const state = {
  all: [],
  toPopulate: {
    competences: [],
    transactions: [],
    withdrawalRequests: [],
    balance: 0,
    reviews: {
      all: [], // All reviews
      totalCount: 0,
      verifiedCount: 0,
      unverifiedCount: 0,
      verifiedRecommendations: 0,
      avgRating: 0, // Average rating accross all reviews
      avgRatingByCategories: []
    },
    isPopulated: false,
  },
}

const mutations = {
  SET_WORKERS(state, val) {
    state.all = val
  },
  UPDATE_WORKER(state, { id, updatedWorker }) {
    const worker = state.all.find(worker => worker.id === id)
    // If worker existing, overwrite
    if (worker) {
      Object.assign(worker, updatedWorker)
      // Otherwise add to store (when updating a single worker and the store is not yet populated)
    } else {
      state.all.push(updatedWorker)

    }
  },
  DELETE_WORKER(state, id) {
    const index = state.all.map(worker => worker.id).indexOf(id)
    state.all.splice(index, 1)
  },
  DELETE_PHOTO(state, id) {
    const worker = state.all.find(worker => worker.id === id)
    // Remove photo property from worker
    Vue.delete(worker, 'photo')
  },
  ADD_PHOTO(state, { id, photo }) {
    const worker = state.all.find(worker => worker.id === id)
    // Add photo property to worker
    Vue.set(worker, 'photo', photo)
  },
  UPDATE_WITHDRAWAL_REQUEST(state, { id, requestId }) {
    const worker = state.all.find(worker => worker.id === id)
    const withdrawalRequest = worker.withdrawalRequests.find(withdrawalRequest => withdrawalRequest.id === requestId)

    Vue.set(withdrawalRequest, 'pending', false)
  }
}

const getters = {
  listWorkers(state) {
    return state.all
  },
  listLastActiveWorkers(state) {
    return [...state.all].sort((a,b) => {
      const aDate = a.lastActivity ? a.lastActivity.seconds : null
      const bDate = b.lastActivity ? b.lastActivity.seconds : null

      return aDate < bDate ? 1 : aDate > bDate ? -1 : 0
    }).slice(0,5)
  },
  sortedWorkers(state, getters, root, rootGetters) {
    return sort => {
      const items = [...state.all].sort((a, b) => {
        let aVal = a[sort.sortBy]
        let bVal = b[sort.sortBy]

        // If it's a nested path, extract
        if(sort.sortBy.includes('.')) {
          aVal = get(a, sort.sortBy)
          bVal = get(b, sort.sortBy)
        }

        // Sort by number of references
        if (sort.sortBy === 'references') {
          const arrA =
            typeof rootGetters['references/findWorkerReferences'](a.id) !== 'undefined'
              ? rootGetters['references/findWorkerReferences'](a.id)
              : []
          const arrB =
            typeof rootGetters['references/findWorkerReferences'](b.id) !== 'undefined'
              ? rootGetters['references/findWorkerReferences'](b.id)
              : []

          return arrA.length < arrB.length ? -1 : arrA.length > arrB.length ? 1 : 0
          // Sort by ratingsAvg
        } else if (sort.sortBy === 'reviews.avgRating') {
          // If ratings are equal, afterwards sort by # reviews
          return aVal < bVal
            ? -1
            : aVal > bVal
            ? 1
            : get(a, 'reviews.verifiedCount') > get(b, 'reviews.verifiedCount')
            ? 1
            : get(a, 'reviews.verifiedCount') < get(b, 'reviews.verifiedCount')
            ? -1
            : 0
        // Sort by activity
        } else if (sort.sortBy === 'lastActivity') {
          const aDate = aVal ? aVal.seconds : null
          const bDate = bVal ? bVal.seconds : null

          return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        // Everything else
        } else {
          return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
        }
      })

      if (sort.sortDirection === 'desc') {
        items.reverse()
      }

      return items
    }
  },
  findWorker(state) {
    return id => {
      const worker = state.all.filter(worker => worker.id === id)
      // Return worker object or empty object of no worker found
      return worker.length ? worker[0] : {}
    }
  },
  findPendingWithdrawalRequests(state) {
    return id => {
      const worker = state.all.find(worker => worker.id === id)
      return worker.withdrawalRequests.filter(withdrawalRequest => withdrawalRequest.pending)
    }
  },
  isPhoneNumberExisting(state) {
    return phone =>
      state.all.find(worker => worker.phone.replace(/ /g, '') === phone.replace(/ /g, ''))
  },
}

/* --------------------------------

  Index: Actions
  ---------------------------------
   - loadWorker()
   - loadWorkers()
   - populateFields()
   - importWorkers()
   - addWorker()
   - resendInvitation()
   - deleteWorker()
   - uploadPhoto()
   - updateWorker()
   - isPhoneNumberExisting()
   - addWorkerTransaction()
   - deleteWorkerTransaction()

--------------------------------- */

const actions = {
  // Load one worker and update store
  loadWorker: async ({ commit, dispatch, state, getters }, { awaitPopulated = false, id }) => {
    // Reset init populated values only if not yet present – otherwise transition time until population causes a bad user experience (e.g. delete review) -> https://www.xspdf.com/resolution/51672475.html
    const toPopulate = !getters.findWorker(id).isPopulated ? state.toPopulate : {}

    // Load worker and competences
    const snapshot = await Promise.all([
      fb.workersCollection.doc(id).get(),
      dispatch({ type: 'competences/loadCompetences' }, { root: true }),
    ])

    // toPopulate fields need to be present on initial commit, otherwise they are not reactive
    const worker = { id: snapshot[0].id, ...toPopulate, ...snapshot[0].data() }

    commit('UPDATE_WORKER', { id, updatedWorker: worker })

    if (awaitPopulated) {
      await dispatch('populateFields', [worker])
    } else {
      dispatch('populateFields', [worker])
    }
  },
  // Load all workers and add to store
  loadWorkers: async function({ commit, dispatch, getters }, populate = true) {
    // Load workers and competences
    const snapshot = await Promise.all([
      fb.workersCollection.orderBy('lastname').get(),
      dispatch({ type: 'competences/loadCompetences' }, { root: true }),
    ])

    // toPopulate fields need to be present on initial commit, otherwise they are not reactive
    const workers = snapshot[0].docs.map(doc => {
      return {
        id: doc.id,
        ...state.toPopulate,
        ...doc.data(),
      }
    })

    commit('SET_WORKERS', workers)

    // Populate reviews & competences to every worker
    if (populate) dispatch('populateFields', getters.listWorkers)
  },
  populateFields: async ({ commit, dispatch, rootGetters }, workers) => {
    // Add reviews & competences to each worker
    return Promise.all(
      workers.map(async worker => {
        // eslint-disable-next-line
        const update: any = {}

        // Populate reviews of worker
        update.reviews = await dispatch('reviews/loadReviews', worker.id, { root: true })

        // Add worker competences as default value but add empty array if not yet defined
        update.competences = worker.competences ? worker.competences : []

        // Add competence name for all selected competences
        update.competences.forEach(competence => {
          const competenceRef = rootGetters['competences/findCompetence'](competence.id)
          competence.name = competenceRef ? competenceRef.name : '[Deleted]'
        })

        // Get transactions & withdrawalRequests from worker
        const snapshot = await Promise.all([
          fb.workersCollection.doc(worker.id).collection('transactions').orderBy('createdOn', 'desc').get(),
          fb.workersCollection.doc(worker.id).collection('withdrawalRequests').orderBy('requestedOn', 'desc').get()
        ])

        // Store transactions on each worker
        update.transactions = snapshot[0].docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })

        // Store withdrawalRequests on each worker
        update.withdrawalRequests = snapshot[1].docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })

        update.balance = 0
        // Calculate ratings
        update.transactions.forEach(transaction => {
          update.balance =
            transaction.type === 'add'
              ? update.balance + parseInt(transaction.amount)
              : update.balance - parseInt(transaction.amount)
        })

        update.isPopulated = true

        commit('UPDATE_WORKER', { id: worker.id, updatedWorker: { ...worker, ...update } })
      })
    )
  },
  importWorkers: async function({ dispatch, commit, getters, rootGetters }, form) {
    commit('app/SET_PROCESSING', true, { root: true })

    await fb.storage
      .ref(`tmp/${generateUUID()}.${form.file.name.split('.').pop()}`)
      .put(form.file)
      .then(async res => {
        try {
          // Call Cloud Function to convert uploaded xlsx file to json
          const { data } = await this['$axios'].post('/api/utils/xlsxtojson', {
            file: res.metadata.fullPath,
            Accept: 'application/json;charset=UTF-8',
          })

          // Delete file after the import
          fb.storage.ref(res.metadata.fullPath).delete()

          let errorCounter = 0
          // Check if some phone numbers are already existing in collection
          data.forEach(worker => {
            // Replace whitespace in phone number & remove invisible unicode characters (for some reason there are some)
            worker.phone = cleanString(worker.phone)

            if (getters.isPhoneNumberExisting(worker.phone) || !isPhoneNumber(worker.phone))
              errorCounter++
          })

          // Throw error if at least one phone numbers is alrady existing
          if (errorCounter) {
            // Error message toast
            toastError(
              this,
              'Import failed: File to import contains invalid phone numbers one some which are already assigned to a worker.'
            )

            commit('app/SET_PROCESSING', false, { root: true })
            // Abort import
            return
          }

          // Create workers in collection
          data.forEach(async worker => {
            const workerRef = await fb.workersCollection.add({
              createdOn: new Date(),
              updatedOn: new Date(),
              ...worker,
            })

            // If welcome notifications should be sent
            if (form.workerWelcomeNotification) {
              const url = `${rootGetters['settings/listSettings'].app.url}/login?phone=${encodeURIComponent(worker.phone)}`

              dispatch(
                'notifications/sendTplNotification',
                { tplId: 'signupInvitation', tplData: { worker: { id: workerRef.id, ...worker }, url  } },
                { root: true }
              )
            }
          })

          trackEvent('Workers Imported', { count: data.length })

          // Repopulate store after import
          await dispatch('loadWorkers')

          // Success message toast
          toastSuccess(this, `Successfully imported ${data.length} worker(s).`)
        } catch (err) {
          // Error message toast
          toastError(this, err.message)
        }
      })

    commit('app/SET_PROCESSING', false, { root: true })
  },
  addWorker: async function({ dispatch, commit, rootGetters }, worker) {
    commit('app/SET_PROCESSING', true, { root: true })

    // 1) Cleanup
    // Store file in temp var and delete it from object as photo needs to get uploaded first
    const photo = worker.photo
    delete worker.photo
    // Replace whitespace in phone number & remove special unicode characters
    worker.phone = cleanString(worker.phone)

    // 2) Create worker in database
    const workerRef = await fb.workersCollection.add({
      createdOn: new Date(),
      updatedOn: new Date(),
      ...worker,
    })

    // 3) Sent welcome message if selected
    if (worker.workerWelcomeNotification) {
      const url = `${rootGetters['settings/listSettings'].app.url}/login?phone=${encodeURIComponent(worker.phone)}`

      dispatch(
        'notifications/sendTplNotification',
        { tplId: 'signupInvitation', tplData: { worker: { id: workerRef.id, ...worker }, url } },
        { root: true }
      )
    }

    // 4) Upload photo to storage if set
    if (photo) {
      const publicPhotoUrl = await dispatch('uploadPhoto', {
        workerId: workerRef.id,
        photo,
      })

      // Update worker with updated photo (await so the photo is ready after redirect)
      await fb.workersCollection.doc(workerRef.id).update({ photo: publicPhotoUrl })
    }

    trackEvent('Worker Added', { sendNotification: worker.workerWelcomeNotification })
    toastSuccess(this, `The worker ${worker.firstname} ${worker.lastname} was added.`)

    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to workers and append queryString
    router
      .push({ path: '/workers', query: { ...rootGetters['app/getRedirectQuery'] } })
      .catch(err => err)
  },
  resendInvitation: async function({ dispatch, rootGetters}, worker) {
    const url = `${rootGetters['settings/listSettings'].app.url}/login?phone=${encodeURIComponent(worker.phone)}`

    dispatch(
      'notifications/sendTplNotification',
      { tplId: 'signupInvitation', tplData: { worker, url } },
      { root: true }
    )

    trackEvent('Resend Invite')
    toastSuccess(this, `Successfully resent the invitation to the worker ${worker.firstname} ${worker.lastname}.`)
  },
  deleteWorker: async function({ dispatch, commit }, { worker, deleteReferences }) {
    // 1) Delete photo if set
    if (worker.photo) dispatch('deletePhoto', worker)

    // 2) Remove subcollections -> is this really needed or does it happen automatically?
    await dispatch('deleteSubcollection', { collections: ['withdrawalRequests', 'transactions', 'activities', 'reviews'], worker })

    // 3) Get & delete notifications from worker
    const notifications = await fb.notificationsCollection.where('workerId', '==', worker.id).get()

    notifications.docs.forEach(doc => {
      fb.notificationsCollection.doc(doc.id).delete()
    })

    // 4) Check of references should also be deleted
    if (deleteReferences) {
      const references = await fb.referencesCollection.where('worker', '==', worker.id).get()

      references.docs.forEach(doc => {
        dispatch('references/deleteReference', { id: doc.id, successMsg: false }, { root: true })
      })
    }

    // 5) Delete worker from collection
    fb.workersCollection.doc(worker.id).delete()

    // Delete worker from store
    commit('DELETE_WORKER', worker.id)

    trackEvent('Worker Deleted')
    toastSuccess(this, `The worker ${worker.firstname} ${worker.lastname} was deleted.`)
  },
  deleteSubcollection: async function(context, { collections, worker } ) {
    return Promise.all(
      collections.map(async collection => {
        // Read subcollection from worker
        const data = await fb.workersCollection
          .doc(worker.id)
          .collection(collection)
          .get()

        // Delete items in subcollection
        data.docs.forEach(doc => {
          fb.workersCollection
            .doc(worker.id)
            .collection(collection)
            .doc(doc.id)
            .delete()
        })
      })
    )
  },
  uploadPhoto: async function(context, { workerId, photo }) {
    try {
      const filename = `${generateUUID()}.${photo.name.split('.').pop()}`
      const path = `workers/${workerId}/${filename}`

      // Await photo upload to store
      await fb.storage.ref(path).put(photo)

      // Call cloud function to resize image
      const { data } = await this['$axios'].post('/api/utils/resizeimage', { file: path })

      //return data.url[0] // would be used if signed url approach is used
      return data.url
    } catch (err) {
      return null
    }
  },
  deletePhoto: async function({ commit }, worker) {
    // Get filename of photo from public url
    const file = decodeURIComponent(worker.photo)
      .split('?')[0]
      .split('/')
      .pop()

    // Delete photo on storage
    fb.storage
      .ref(`workers/${worker.id}/${file}`)
      .delete()
      .catch(err => err)
    // Delete photo property from database
    fb.workersCollection.doc(worker.id).update({ photo: fb.firebase.firestore.FieldValue.delete() })

    // Delete photo property from state
    commit('DELETE_PHOTO', worker.id)
  },
  updateWorker: async function({ dispatch, commit, getters, rootGetters }, worker) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone worker not to modify form data when saving (otherwise form looks broken for a short moment)
    worker = cloneDeep(worker)

    // 1) Upload new photo if set
    if (worker.photo) {
      // If there is already one existing, delete old one first
      if (getters.findWorker(worker.id).photo)
        await dispatch('deletePhoto', getters.findWorker(worker.id))

      worker.photo = await dispatch('uploadPhoto', {
        workerId: worker.id,
        photo: worker.photo,
      })
    } else {
      // Otherwise keep old one
      delete worker.photo
    }

    // 2) Cleanup fields
    // Clean populated properties as they should not be stored but rather repopulated every time
    delete worker.recommendations
    delete worker.reviewCategories
    delete worker.reviews
    delete worker.transactions
    delete worker.balance
    delete worker.ratingsAvg
    delete worker.reviewsTotal
    delete worker.withdrawalRequests
    delete worker.isPopulated

    worker.competences.forEach((competence) => {
      // Don't store competence name
      delete competence.name
      delete competence.createdOn
      delete competence.updatedOn
    })

    delete worker.phone // Delete phone number as it can't be modified for now after adding the worker
    // Replace whitespace in phone number & remove special unicode characters
    // worker.phone = cleanString(worker.phone)

    // 3) Update worker in database
    await fb.workersCollection.doc(worker.id).update({ ...worker, updatedOn: new Date() })

    // 4) Update worker in state
    commit('UPDATE_WORKER', { id: worker.id, updatedWorker: worker })

    trackEvent('Worker Updated')
    toastSuccess(this, `The worker ${worker.firstname} ${worker.lastname} was updated.`)

    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to workers and append queryString
    router
      .push({ path: '/workers', query: { ...rootGetters['app/getRedirectQuery'] } })
      .catch(err => err)
  },
  // Check if phonenumber is already existing
  isPhoneNumberExisting: async function(context, phone) {
    const pn = phone.replace(/ /g, '')
    // todo: improve search functionality. await fb.workersCollection.where('phone', '==', pn).get() somehow doesn't find all phone numbers
    const workersRef = await fb.workersCollection.get()
    const allWorkers = workersRef.docs.map(doc => {
      return { id: doc.id, phone: null, ...doc.data() }
    })

    const match = allWorkers.find(worker => {
      return worker.phone.includes(pn)
    })

    return match
  },
  addWorkerTransaction: async function({ commit, dispatch, getters, rootGetters }, { form, id, balance }) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone form as form gets reset after submit on component
    form = cloneDeep(form)

    // 1) Check if balance is big enough for payout
    if (form.type === 'sub' && parseInt(balance) < parseInt(form.amount)) {
      toastError(this, `Balance is too low for a withdrawel of ${form.amount} CHF.`)

      commit('app/SET_PROCESSING', false, { root: true })
      return
    }

    // 2) Add transaction to database
    await fb.workersCollection
      .doc(id)
      .collection('transactions')
      .add({
        createdOn: new Date(),
        updatedOn: new Date(),
        ...form,
      })

    // 3) Sent new transaction notification if selected
    if (form.notifyWorker) {
      const tpl = form.type === 'add' ? 'transactionsGrantbonus' : 'transactionsWithdrawal'
      const url = `${rootGetters['settings/listSettings'].app.url}/dashboard`

      dispatch(
        'notifications/sendTplNotification',
        {
          tplId: tpl,
          tplData: { worker: { ...getters.findWorker(id) }, transaction: { ...form }, url },
        },
        { root: true }
      )
    }

    // 4) Reload worker
    await dispatch('loadWorker', { awaitPopulated: true, id })

    trackEvent('Worker Transaction Added', {
      sendNotification: form.notifyWorker,
      type: form.type,
      amount: parseInt(form.amount),
    })

    commit('app/SET_PROCESSING', false, { root: true })
  },
  deleteWorkerTransaction: async function({ dispatch }, { workerId, id }) {
    // 1) Delete transaction from database
    await fb.workersCollection
      .doc(workerId)
      .collection('transactions')
      .doc(id)
      .delete()

    // 2) Reload worker
    await dispatch('loadWorker', { awaitPopulated: true, id: workerId })

    trackEvent('Worker Transaction Deleted')
  },
  processWithdrawalRequest: async function({ commit }, { workerId, requestId }) {
    await fb.workersCollection.doc(workerId).collection('withdrawalRequests').doc(requestId).update({ pending: false })

    commit('UPDATE_WITHDRAWAL_REQUEST', { id: workerId, requestId })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
