<template>
  <div v-if="!isSidebarLoading">
    <template v-if="worker.reviews.totalCount">
      <b-card
        class="mb-2 card-highlight"
        bg-variant="light"
        no-body
        v-if="worker.reviews.verifiedCount"
      >
        <b-card-body class="p-3">
          <h6 class="mb-3">
            Summary
            <span class="review-count text-secondary ml-1"
              >({{ worker.reviews.verifiedCount }}
              {{ 'Review' | pluralize(worker.reviews.verifiedCount) }},
              {{
                (
                  (worker.reviews.verifiedRecommendations / worker.reviews.verifiedCount) *
                  100
                ).toFixed()
              }}% recommendation rate)</span
            >
          </h6>
          <div class="d-flex justify-content-between">
            <div class="rating-label text-secondary">Total</div>
            <b-form-rating
              v-model="worker.reviews.avgRating"
              size="sm"
              precision="1"
              class="p-0 text-primary"
              show-value
              readonly
              no-border
              inline
            ></b-form-rating>
          </div>
          <hr class="my-2" />
          <div
            v-for="(category, index) in worker.reviews.avgRatingByCategories"
            :key="category.id"
            class="d-flex justify-content-between"
            :class="{ 'mb-2': index !== category.length - 1 }"
          >
            <div class="rating-label text-secondary">{{ category.name }}</div>
            <b-form-rating
              v-model="category.avgRating"
              size="sm"
              precision="1"
              class="p-0 text-primary"
              show-value
              readonly
              no-border
              inline
            ></b-form-rating>
          </div>
        </b-card-body>
      </b-card>
      <div class="mb-2">
        <b-button
          variant="primary"
          :to="{ name: 'addReviews', params: { id }, query: { ...$route.query } }"
          block
          >Add Review</b-button
        >
      </div>
      <b-card v-for="review in worker.reviews.all" :key="review.id" class="mb-2 card-item" no-body>
        <b-tooltip :target="review.id" placement="bottom">
          <div
            v-for="category in review.categories"
            :key="category.id"
            class="d-flex justify-content-between"
          >
            <div class="mr-3" v-if="category">{{ category.name }}</div>
            <div class="mr-3" v-else>[Deleted]</div>
            <div>{{ category.rating ? category.rating : 0 }}</div>
          </div>
        </b-tooltip>
        <b-card-body class="p-3">
          <div class="d-flex justify-content-between">
            <div>
              <span
                v-if="!review.verified"
                class="text-warning text-xsmall mb-1"
                v-b-tooltip.hover
                title="Unverified reviews are not visible for anyone else nor considered in the overall worker rating."
              >
                Unverified
                <b-icon icon="exclamation-circle-fill" class="ml-1" font-scale="1"></b-icon>
              </span>
              <div>
                <strong>{{ review.fullname }}</strong>
                <span v-if="review.company">
                  <br />
                  {{ review.company }}
                </span>
              </div>
            </div>
            <span class="text-secondary text-xsmall">{{ review.createdOn | formatDate }} </span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <b-form-rating
              v-model="review.avgRating"
              size="sm"
              precision="1"
              :id="review.id"
              class="p-0 text-primary"
              show-value
              readonly
              no-border
              inline
            ></b-form-rating>
            <div
              v-if="review.recommend"
              class="recommended bg-primary"
              v-b-tooltip.hover
              title="Would recommend this person."
            >
              <b-icon icon="hand-thumbs-up" font-scale="1"></b-icon>
            </div>
          </div>
          <div v-if="review.comment" class="text-secondary font-italic text-multiline">
            {{ review.comment }}
          </div>
        </b-card-body>
        <b-card-footer class="text-xsmall d-flex justify-content-between" footer-bg-variant="white">
          <div>
            <b-link
              :to="{
                name: 'editReviews',
                params: { reviewId: review.id },
                query: { ...$route.query },
              }"
              class="text-xsmall mr-2"
              >Edit</b-link
            >

            <b-link
              v-b-modal.deleteReviewModal
              @click.prevent="selectedItem = review"
              class="text-xsmall"
              >Delete</b-link
            >
          </div>
          <div
            v-if="review.updatedBy"
            class="text-gray-500"
            v-b-tooltip.hover
            :title="`Review edited by ${review.updatedBy}`"
          >
            Edited on {{ review.updatedOn | formatDate }}
          </div>
        </b-card-footer>
      </b-card>
    </template>
    <template v-else>
      <div class="text-center no-results">
        <b-icon icon="star" font-scale="2.5" class="mb-3 text-secondary"></b-icon>
        <p class="mb-3">No reviews for {{ worker.firstname }} {{ worker.lastname }} yet.</p>
        <b-button
          variant="primary"
          class="btn-minwidth"
          :to="{ path: `/workers/${id}/reviews/add`, query: { ...$route.query } }"
          >Add Review</b-button
        >
      </div>
    </template>
    <app-confirm-modal
      title="Delete Review"
      customId="deleteReviewModal"
      @ok="deleteReview({ workerId: worker.id, id: selectedItem.id })"
    >
      <div>
        Are you sure you want to delete this
        <strong>{{ Math.round(selectedItem.avgRating * 10) / 10 }}</strong> star review from
        <strong>{{ selectedItem.fullname }}</strong> ? This action can not be undone.
      </div>
    </app-confirm-modal>
  </div>
</template>

<script>
import AppConfirmModal from '@/components/ui/modal.confirm.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['id'],
  data: function () {
    return {
      worker: {},
      reviews: [],
      ratings: [],
      selectedItem: {},
    }
  },
  components: {
    AppConfirmModal,
  },
  computed: {
    ...mapGetters('app', ['isSidebarLoading']),
    ...mapGetters('workers', ['findWorker']),
  },
  methods: {
    ...mapMutations('app', ['SET_SIDEBARLOADING', 'SET_SIDEBARTITLE']),
    ...mapActions('workers', ['loadWorker']),
    ...mapActions('reviews', ['deleteReview']),
  },
  async created() {
    this.SET_SIDEBARLOADING(true)

    await this.loadWorker({ awaitPopulated: true, id: this.id })
    // Reference to worker in store
    this.worker = this.findWorker(this.id)

    // Set sidebar title
    this.SET_SIDEBARTITLE(`Reviews ${this.worker.firstname} ${this.worker.lastname}`)

    this.SET_SIDEBARLOADING(false)
  },
}
</script>

<style lang="scss" scoped>
.rating-label {
  font-size: 0.8rem;
  font-weight: bold;
}

.review-count {
  font-weight: normal;
  font-size: 0.7rem;
}

.recommended {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  width: 22px;
  height: 22px;

  svg {
    position: relative;
    color: #fff;
    top: -2px;
  }
}
</style>
