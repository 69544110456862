<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Users">
      <b-button variant="primary" class="btn-minwidth" to="/users/add">Add User</b-button>
    </app-page-header>
    <b-table ref="usersTable" :items="listUsers" :fields="table" show-empty hover>
      <template v-slot:cell(photo)="{ item: user }">
        <app-photo-user
          :photo="user.photoURL"
          size="m"
          :placeholder="user.displayName | getInitials"
        ></app-photo-user>
      </template>
      <template v-slot:cell(role)="{ item: user }">
        <b-badge
          :variant="user.role === 'admin' ? 'primary' : 'secondary'"
          class="text-capitalize"
          >{{ user.role }}</b-badge
        >
      </template>
      <template v-slot:cell(actions)="{ item: user }">
        <b-dropdown variant="light" size="sm" no-caret right>
          <template v-slot:button-content>
            <b-icon icon="three-dots" class="text-secondary" font-scale="1.5"></b-icon>
          </template>
          <b-dropdown-item :to="{ name: 'editUser', params: { id: user.id } }">
            <b-icon icon="pencil" font-scale="1.2"></b-icon>Edit
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-b-modal.deleteModal @click="selectedItem = user">
            <b-icon icon="trash" font-scale="1.2"></b-icon>Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <app-confirm-modal title="Delete User" @ok="deleteUser(selectedItem)">
      <div>
        Are you sure you want to delete the user
        <strong>{{ selectedItem.displayName }}</strong>
        permanently? This action can not be undone.
      </div>
    </app-confirm-modal>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppConfirmModal from '@/components/ui/modal.confirm.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { formatDate } from '@/utils'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  data: function () {
    return {
      table: [
        { key: 'photo', label: '', tdClass: 'col-photo' },
        { key: 'displayName', sortable: true },
        { key: 'email' },
        { key: 'role' },
        {
          key: 'metadata.lastSignInTime',
          label: 'Last Sign In',
          formatter: formatDate,
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'col-actions text-right',
        },
      ],
      selectedItem: {},
    }
  },
  components: {
    AppPageHeader,
    AppConfirmModal,
    AppPhotoUser,
  },
  computed: {
    ...mapGetters('app', ['isPageLoading']),
    ...mapGetters('users', ['listUsers']),
  },
  methods: {
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('users', ['loadUsers', 'deleteUser']),
  },
  async created() {
    this.SET_PAGELOADING(true)

    await this.loadUsers()

    this.SET_PAGELOADING(false)
  },
}
</script>