<template>
  <b-dropdown variant="light" size="sm" no-caret right>
    <template v-slot:button-content>
      <b-icon icon="three-dots" class="text-secondary" font-scale="1.5"></b-icon>
    </template>
    <b-dropdown-item
      v-b-modal.modal-reference
      :to="{
        name: 'showReference',
        params: { id: reference.id },
        query: { ...$route.query },
      }"
    >
      <b-icon icon="eye" font-scale="1.2"></b-icon>Show
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'editReference', params: { id: reference.id } }">
      <b-icon icon="pencil" font-scale="1.2"></b-icon>Edit
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item v-b-modal.deleteModal @click="$emit('delete', reference)">
      <b-icon icon="trash" font-scale="1.2"></b-icon>Delete
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: ['reference'],
}
</script>