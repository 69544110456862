import { render, staticRenderFns } from "./workers.reviews.edit.vue?vue&type=template&id=5c100811&"
import script from "./workers.reviews.edit.vue?vue&type=script&lang=js&"
export * from "./workers.reviews.edit.vue?vue&type=script&lang=js&"
import style0 from "./workers.reviews.edit.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports