<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Workers">
      <b-dropdown
        split
        text="Add Worker"
        split-to="/workers/add"
        variant="primary"
        class="text-nowrap"
        right
      >
        <b-dropdown-item v-b-modal.modal-importer
          ><b-icon icon="cloud-arrow-up" font-scale="1.3"></b-icon>Import Workers</b-dropdown-item
        >
        <b-dropdown-item sidebar-right :to="{ name: 'showTeamReview', query: { ...$route.query } }"
          ><b-icon icon="star" font-scale="1.2"></b-icon>Team Review</b-dropdown-item
        >
      </b-dropdown>
    </app-page-header>
    <div v-if="listWorkers.length">
      <app-toolbar
        :defaultValues="{
          viewMode: 'list',
          sortDirection: 'asc',
          sortBy: 'lastname',
        }"
        :sortOptions="[
          {
            label: 'firstname',
            key: 'firstname',
          },
          {
            label: 'lastname',
            key: 'lastname',
          },
          {
            label: 'jobtitle',
            key: 'jobtitle',
          },
          {
            label: 'rating score',
            key: 'reviews.avgRating',
          },
          {
            label: '# reviews',
            key: 'reviews.verifiedCount',
          },
          {
            label: '# references',
            key: 'references',
          },
          {
            label: 'balance',
            key: 'balance',
          },
          {
            label: 'last active',
            key: 'lastActivity',
          },
        ]"
        searchPlaceholder="Search by name, jobtitle"
        @search="filter.search = $event"
        @changeViewMode="toolbar.viewMode = $event"
        @changeSortBy="toolbar.sortBy = $event"
        @changeSortDirection="toolbar.sortDirection = $event"
      ></app-toolbar>
      <template v-if="this.toolbar.viewMode === 'card'">
        <div v-if="filteredWorkers().length" class="cards">
          <app-card
            v-for="worker in filteredWorkers()"
            :key="worker.id"
            :photo="worker.photo"
            :photo-placeholder="`${worker.firstname} ${worker.lastname}` | getInitials"
            :href="`/workers/${worker.id}`"
            :title="`${worker.firstname} ${worker.lastname}`"
            :subtitle="worker.jobtitle"
          >
            <template v-slot:card-footer
              ><div class="d-flex justify-content-between">
                <app-references-preview :worker="worker" class="w-30"></app-references-preview>
                <app-balance-preview :worker="worker" class="w-30 text-right"></app-balance-preview>
              </div>
            </template>
            <template v-slot:card-content>
              <div class="card-content mt-3 mb-5">
                <app-ratings-preview
                  class="text-center"
                  buttonClass="mt-1"
                  :worker="worker"
                  centered
                ></app-ratings-preview>
              </div>
            </template>
            <template v-slot:card-dropdown>
              <app-worker-actions :worker="worker" @delete="setSelected"></app-worker-actions>
            </template>
          </app-card>
        </div>
        <app-search-no-results v-else></app-search-no-results>
      </template>
      <b-table
        v-else
        ref="workersTable"
        :items="sortedWorkers({ sortBy: toolbar.sortBy, sortDirection: toolbar.sortDirection })"
        :fields="table"
        :filter="filter.search"
        :filter-function="searchWorkers"
        responsive="sm"
        show-empty
        hover
      >
        <template v-slot:emptyfiltered>
          <app-search-no-results></app-search-no-results>
        </template>
        <template v-slot:cell(photo)="{ item: worker }">
          <app-photo-user
            :photo="worker.photo"
            size="m"
            :placeholder="`${worker.firstname} ${worker.lastname}` | getInitials"
          ></app-photo-user>
        </template>
        <template v-slot:cell(phone)="{ item: worker }">
          {{ worker.phone }}
          <div class="text-xsmall text-gray-500">
            Last active: {{ worker.lastActivity | formatTimeRelative }}
          </div>
        </template>
        <template v-slot:cell(name)="{ item: worker }">
          <b-link
            :to="{ name: 'editWorker', params: { id: worker.id } }"
            class="text-decoration-none"
          >
            <div>
              <strong>{{ worker.firstname }} {{ worker.lastname }}</strong>
            </div>
            <span v-if="worker.jobtitle" class="text-secondary">{{ worker.jobtitle }}</span>
          </b-link>
        </template>
        <template v-slot:cell(ratingsAvg)="{ item: worker }">
          <app-ratings-preview :worker="worker"></app-ratings-preview>
        </template>
        <template v-slot:cell(references)="{ item: worker }">
          <app-references-preview :worker="worker"></app-references-preview>
        </template>
        <template v-slot:cell(balance)="{ item: worker }">
          <app-balance-preview :worker="worker"></app-balance-preview>
        </template>
        <template v-slot:cell(actions)="{ item: worker }">
          <app-worker-actions :worker="worker" @delete="setSelected"></app-worker-actions>
        </template>
      </b-table>
    </div>
    <div v-else class="text-center mt-10">
      <b-icon icon="people" font-scale="2.5" class="mb-3 text-gray-500"></b-icon>
      <h5>No workers yet</h5>
      <p>
        There are currently no workers yet.
        <br />Start adding them
        <b-link to="/workers/add" class="text-underline">manually</b-link>&nbsp;or
        <b-link v-b-modal.modal-importer class="text-underline">import</b-link>&nbsp;them.
      </p>
    </div>
    <app-confirm-modal
      title="Delete Worker"
      @ok="deleteWorker({ worker: selectedItem, deleteReferences })"
    >
      <div>
        Are you sure you want to delete the worker
        <strong>{{ selectedItem.firstname }} {{ selectedItem.lastname }}</strong>
        permanently? This action can not be undone.
        <b-form-checkbox v-model="deleteReferences" :value="true" class="mt-3">
          Delete references of {{ selectedItem.firstname }} {{ selectedItem.lastname }} as well ({{
            findWorkerReferences(selectedItem.id).length
          }})
        </b-form-checkbox>
      </div>
    </app-confirm-modal>
    <app-worker-importer></app-worker-importer>
    <app-sidebar></app-sidebar>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppConfirmModal from '@/components/ui/modal.confirm.vue'
import AppToolbar from '@/components/ui/toolbar.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import AppWorkerImporter from '@/components/workers/importer.vue'
import AppCard from '@/components/ui/card.vue'
import AppWorkerActions from '@/components/workers/actions.dropdown.vue'
import AppRatingsPreview from '@/components/workers/ratings.preview.vue'
import AppReferencesPreview from '@/components/workers/references.preview.vue'
import AppBalancePreview from '@/components/workers/balance.preview.vue'
import AppSearchNoResults from '@/components/ui/search.noresults.vue'
import AppSidebar from '@/components/ui/sidebar.vue'
import { formatPhoneNumber } from '@/utils'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      table: [
        { key: 'photo', label: '', tdClass: 'col-photo' },
        { key: 'name' },
        { key: 'phone', tdClass: 'text-nowrap', formatter: formatPhoneNumber },
        { key: 'ratingsAvg', label: 'Rating', tdClass: 'col-rating' },
        { key: 'references' },
        { key: 'balance', tdClass: 'col-balance' },
        {
          key: 'actions',
          label: '',
          tdClass: 'col-actions text-right',
        },
      ],
      deleteReferences: false,
      references: [],
      selectedItem: {},
      filter: {
        search: '',
      },
      toolbar: {
        viewMode: '',
        sortBy: '',
        sortDirection: '',
      },
    }
  },
  components: {
    AppConfirmModal,
    AppPageHeader,
    AppWorkerImporter,
    AppWorkerActions,
    AppSidebar,
    AppCard,
    AppPhotoUser,
    AppToolbar,
    AppRatingsPreview,
    AppReferencesPreview,
    AppBalancePreview,
    AppSearchNoResults,
  },
  computed: {
    ...mapGetters('app', ['isPageLoading']),
    ...mapGetters('workers', ['listWorkers', 'findWorker', 'sortedWorkers']),
    ...mapGetters('references', ['findWorkerReferences']),
  },
  methods: {
    ...mapActions('workers', ['loadWorkers', 'deleteWorker', 'resendInvitation']),
    ...mapActions('references', ['loadReferences']),
    ...mapMutations('app', ['SET_PAGELOADING']),
    filteredWorkers() {
      const items = this.sortedWorkers({
        sortBy: this.toolbar.sortBy,
        sortDirection: this.toolbar.sortDirection,
      })

      return items.filter((item) => {
        return this.searchWorkers(item, this.filter.search)
      })
    },
    searchWorkers(row, query) {
      const fullName = `${row.firstname} ${row.lastname}`.toLowerCase()
      const jobtitle = row.jobtitle ? row.jobtitle.toLowerCase() : ''
      const phone = row.phone ? row.phone.toString() : ''

      return (
        fullName.includes(query.toLowerCase()) ||
        jobtitle.includes(query.toLowerCase()) ||
        phone.replace(/ /g, '').includes(query.replace(/ /g, '')) ||
        row.id === query
      )
    },
    setSelected(item) {
      this.selectedItem = item
      this.deleteReferences = false
    },
  },
  async created() {
    this.SET_PAGELOADING(true)

    // Populate store
    await Promise.all([this.loadWorkers(), this.loadReferences(false)])

    this.SET_PAGELOADING(false)
  },
}
</script>

<style lang="scss">
.card-content {
  min-height: 50px;
}
</style>