<template>
  <b-modal id="modal-importer">
    <template v-slot:modal-title>
      <b-icon icon="cloud-arrow-up" font-scale="1.3" class="mr-2 text-primary"></b-icon>Import
      Workers
    </template>
    <p>
      Import multiple workers at once via this Excel import. You can download the Excel template
      <b-link href="/files/import.workers.tpl.xlsx" target="_blank" class="text-underline"
        >here</b-link
      >.
    </p>
    <b-form @submit.prevent="formSubmit" id="formImporter">
      <b-form-group label="File to import" label-for="importerFile">
        <b-form-file
          v-model="form.file"
          accept=".xls, .xlsx"
          placeholder="Choose a .xls or .xlsx file to import..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <b-form-checkbox v-model="form.workerWelcomeNotification" value="true"
        >Send welcome notification to worker</b-form-checkbox
      >
      <b-button type="submit" ref="importSubmit" class="d-none">Import</b-button>
    </b-form>
    <template v-slot:modal-footer="{ cancel }">
      <app-button-spinner
        :loading="isProcessing"
        :tabindex="5"
        @click.native="$refs.importSubmit.click()"
        >Import</app-button-spinner
      >
      <b-button variant="light" @click="cancel()" class="btn-minwidth">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      form: {
        file: [],
        workerWelcomeNotification: true,
      },
    }
  },
  computed: {
    ...mapGetters('app', ['isProcessing']),
  },
  components: {
    AppButtonSpinner,
  },
  methods: {
    ...mapActions('workers', ['importWorkers']),
    async formSubmit() {
      try {
        await this.importWorkers(this.form)
        this.$bvModal.hide('modal-importer')
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
