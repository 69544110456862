import * as fb from '@/firebase'
import router from '@/router/index'
import { generateUUID } from '@/utils'
import { trackEvent } from '@/utils/analytics'
import { toastSuccess } from '@/components/ui/toast'
import { cloneDeep } from 'lodash'

const state = {
  all: [],
}

const mutations = {
  SET_REFERENCES(state, val) {
    state.all = val
  },
  ADD_REFERENCE(state, val) {
    state.all.push(val)
  },
  UPDATE_REFERENCE(state, { id, updatedReference }) {
    const reference = state.all.find(reference => reference.id === id)

    // If reference existing, overwrite
    if (reference) {
      Object.assign(reference, updatedReference)
      // Otherwise add to store (when updating a single reference and the store is not yet populated)
    } else {
      state.all.push(updatedReference)
    }
  },
  DELETE_REFERENCE(state, id) {
    const index = state.all.map(reference => reference.id).indexOf(id)
    state.all.splice(index, 1)
  },
  DELETE_PHOTO(state, { id, photo }) {
    const reference = state.all.find(reference => reference.id === id)
    const index = reference.photos.indexOf(photo)
    //Remove photo property from reference
    reference.photos.splice(index, 1)
  },
  ADD_PHOTO(state, { id, photo }) {
    const reference = state.all.find(reference => reference.id === id)
    if (reference) reference.photos.push(photo)
  },
  UPDATE_PHOTO(state, { id, photo, oldPhoto }) {
    const reference = state.all.find(reference => reference.id === id)

    if (reference) {
      const index = reference.photos.findIndex(photo => photo.includes(oldPhoto))

      // Replace old photo with new one if existing, otherwise push new foto to array
      if (index) {
        reference.photos[index] = photo
      } else {
        reference.photos.push(photo)
      }
    }
  },
}

const getters = {
  listReferences(state) {
    return state.all
  },
  sortedReferences(state, getters, root, rootGetters) {
    return sort => {
      const items = [...state.all].sort((a, b) => {
        // Custom sort for workers
        if (sort.sortBy === 'worker') {
          return rootGetters['workers/findWorker'](a.worker).lastname < rootGetters['workers/findWorker'](b.worker).lastname
            ? -1
            : rootGetters['workers/findWorker'](a.worker).lastname > rootGetters['workers/findWorker'](b.worker).lastname
            ? 1
            : 0
        } else if (sort.sortBy === 'likes') {
          a.likes = a.likes ? a.likes : []
          b.likes = b.likes ? b.likes : []
          return a.likes.length < b.likes.length ? -1 : a.likes.length > b.likes.length ? 1 : 0
        } else if (sort.sortBy === 'project') {
          return a[sort.sortBy].toLowerCase() < b[sort.sortBy].toLowerCase() ? -1 : a[sort.sortBy].toLowerCase() > b[sort.sortBy].toLowerCase() ? 1 : 0
        } else {
          return a[sort.sortBy] < b[sort.sortBy] ? -1 : a[sort.sortBy] > b[sort.sortBy] ? 1 : 0
        }
      })

      if (sort.sortDirection === 'desc') {
        items.reverse()
      }

      return items
    }
  },
  findWorkerReferences(state) {
    return workerId => state.all.filter(reference => reference.worker === workerId)
  },
  findReference(state) {
    return id => {
      return state.all.find(reference => reference.id === id)
    }
  },
}

const actions = {
  // Load one worker and update store
  loadReference: async ({ commit, dispatch }, { awaitPopulated = false, id }) => {
    // Load reference from database
    const snapshot = await fb.referencesCollection.doc(id).get()
    const reference = { id: snapshot.id, ...snapshot.data() }

    commit('UPDATE_REFERENCE', { id: reference.id, updatedReference: reference })

    // Populate worker & competences for reference
    awaitPopulated
      ? await dispatch('populateFields', [reference])
      : dispatch('populateFields', [reference])
  },
  loadReferences: async ({ commit, dispatch, getters }, populate = true) => {
    const snapshot = await fb.referencesCollection.orderBy('createdOn').get()
    const references = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    commit('SET_REFERENCES', references)

    // Populate reviews & competences to every worker
    if (populate) dispatch('populateFields', getters.listReferences)
  },
  populateFields: async ({ commit, dispatch, rootGetters }, references) => {
    // Repopulate competences to make sure that they are present
    await dispatch({ type: 'competences/loadCompetences' }, { root: true })

    // Add worker & competences to each reference
    return Promise.all(
      references.map(async reference => {
        const worker = rootGetters['workers/findWorker'](reference.worker)
        // If not existing, load worker to store before continuing
        if (!worker) await dispatch('workers/loadWorker', { id: reference.worker }, { root: true })

        // Define skeleton object for reference update
        const update = {
          isPopulated: false,
          worker: {},
          competences: [],
          comments: []
        }

        // Populate worker
        update.worker = rootGetters['workers/findWorker'](reference.worker)

        // Read comments subcollection from reference
        const commentsSnapshot = await fb.referencesCollection
          .doc(reference.id)
          .collection('comments')
          .orderBy('createdOn', 'desc')
          .get()

        update.comments = commentsSnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })

        // Populate competences
        reference.competences.forEach(competence => {
          const tmpCompetence = rootGetters['competences/findCompetence'](competence)
          // If competence is not existing anymore, don't populate
          if (!tmpCompetence) return

          // Remove createdOn and updatedOn as it's not relevant per competence
          delete tmpCompetence.createdOn
          delete tmpCompetence.updatedOn

          update.competences.push(tmpCompetence)
        })

        update.isPopulated = true

        commit('UPDATE_REFERENCE', {
          id: reference.id,
          updatedReference: { ...reference, ...update },
        })
      })
    )
  },
  addReference: async function({ commit, dispatch }, reference) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone reference not to modify form data when saving (otherwise form looks broken for a short moment)
    reference = cloneDeep(reference)

    // Only store IDs of competences & worker
    if (reference.competences) reference.competences = reference.competences.map(item => item.id)
    if (reference.worker) reference.worker = reference.worker.id
    // Turn into valid date format before storing
    reference.date = new Date(reference.date)

    // Assign photos array to perform file uploading
    const photos = reference.photos
    // Set reference photos to empty array (urls will get added after upload)
    reference.photos = []

    // Add reference to database
    const referenceRef = await fb.referencesCollection.add({
      createdOn: new Date(),
      updatedOn: new Date(),
      ...reference,
    })

    // Upload photo to storage if set. Do one by one synchronously to make sure all photos get properly added to photos array
    if (photos) {
      const publicPhotoUrls = await dispatch('uploadPhotos', {
        referenceId: referenceRef.id,
        photos,
      })

      // Update reference with updated photos (await so the photos are ready after redirect)
      await fb.referencesCollection.doc(referenceRef.id).update({ photos: publicPhotoUrls })
    }

    trackEvent('Reference Added', { photoCount: photos.length })
    toastSuccess(this, `The reference ${reference.project} was added.`)

    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to references
    router.push('/references').catch(err => err)
  },
  updateReference: async function({ commit, dispatch, getters, rootGetters }, reference) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone reference not to modify form data when saving (otherwise form looks broken for a short moment)
    reference = cloneDeep(reference)

    // Only store IDs of competences & worker, not entire object
    if (reference.competences) reference.competences = reference.competences.map(item => item.id)
    if (reference.worker) reference.worker = reference.worker.id
    // Turn into valid date format before storing
    reference.date = new Date(reference.date)
    // Clean populated properties as they should not be stored but rather repopulated every time
    delete reference.isPopulated
    delete reference.comments

    let publicPhotoUrls = []
    // Upload photos to storage if additional ones are selected
    if (reference.photos) {
      publicPhotoUrls = await dispatch('uploadPhotos', {
        referenceId: reference.id,
        photos: reference.photos,
      })
    }

    // Add new files to already existing ones
    reference.photos = getters.findReference(reference.id).photos.concat(publicPhotoUrls)

    // Update worker in database
    await fb.referencesCollection.doc(reference.id).update({ ...reference, updatedOn: new Date() })

    // Update reference in state
    commit('UPDATE_REFERENCE', { id: reference.id, updatedReference: reference })

    trackEvent('Reference Updated')
    toastSuccess(this, `The reference ${reference.project} was updated.`)

    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to references and append queryString
    router
      .push({ path: '/references', query: { ...rootGetters['app/getRedirectQuery'] } })
      .catch(err => err)
  },
  deleteReference: async function({ dispatch, commit }, { id, successMsg = true }) {
    // Fetch most version to make sure it's up to date
    const snapshot = await fb.referencesCollection.doc(id).get()
    const reference = { id: snapshot.id, project: '', photos: [], ...snapshot.data() }

    // Delete photos if set
    if (reference.photos) {
      reference.photos.forEach(photo => {
        dispatch('deletePhoto', { photo, reference, deleteFromDb: false })
      })
    }

    // Read comments subcollection from reference
    const comments = await fb.referencesCollection
      .doc(id)
      .collection('comments')
      .get()

    // Delete comments in subcollection
    comments.docs.forEach(doc => {
      fb.referencesCollection
        .doc(id)
        .collection('comments')
        .doc(doc.id)
        .delete()
    })

    // Delete reference from collection
    fb.referencesCollection.doc(reference.id).delete()
    // Delete worker from store
    commit('DELETE_REFERENCE', reference.id)
    trackEvent('Reference Deleted')

    // When deleting worker with references, don't show success message. When deleting reference directly, show
    if (successMsg) {
      // Success message toast
      toastSuccess(this, `The reference ${reference.project} was deleted.`)
    }
  },
  uploadPhotos: async function(context, { referenceId, photos }) {
    return Promise.all(
      photos.map(async photo => {
        try {
          const filename = `${generateUUID()}.${photo.name.split('.').pop()}`
          const path = `references/${referenceId}/${filename}`

          // Await photo upload to store
          await fb.storage.ref(path).put(photo)

          // Call cloud function to resize image
          const { data } = await this['$axios'].post('/api/utils/resizeimage', { file: path, maxsize: 1280 })

          return data.url
        } catch (err) {
          return null
        }
      })
    )
  },
  deletePhoto: async function({ commit }, { photo, reference, deleteFromDb = true }) {
    // Retrieve filename from public signed URL
    const file = decodeURIComponent(photo)
      .split('?')[0]
      .split('/')
      .pop()

    // Delete photo on storage
    fb.storage
      .ref(`references/${reference.id}/${file}`)
      .delete()
      .catch(err => err)

    // Delete photo property from state
    commit('DELETE_PHOTO', { id: reference.id, photo })

    // Deleting from database is only needed when removing a single picture in edit reference mode, when deleting entire reference with all picture, database doc is anyway gone completely
    if (deleteFromDb) fb.referencesCollection.doc(reference.id).update({ photos: reference.photos })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
