import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection references
const usersCollection = db.collection('users')
const workersCollection = db.collection('workers')
const competencesCollection = db.collection('competences')
const categoriesCollection = db.collection('categories')
const settingsCollection = db.collection('settings')
const referencesCollection = db.collection('references')
const notificationsCollection = db.collection('notifications')

// export utils/refs
export {
  firebase,
  db,
  auth,
  storage,
  usersCollection,
  workersCollection,
  competencesCollection,
  categoriesCollection,
  settingsCollection,
  referencesCollection,
  notificationsCollection,
}
