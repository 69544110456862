<template>
  <b-sidebar
    id="sidebar-right"
    :visible="sidebar.visible"
    bg-variant="white"
    width="370px"
    @hidden="hideSidebar"
    :class="getSidebarClass"
    no-close-on-route-change
    no-header
    backdrop
    right
    shadow
  >
    <template v-slot:default="{ hide }">
      <header class="sidebar-header p-4 d-flex justify-content-between">
        <h5>
          <span v-if="!isSidebarLoading">{{ getSidebarTitle }}</span>
        </h5>
        <b-link @click="hide">
          <b-icon icon="x" font-scale="1.5" class="text-gray-500"></b-icon>
        </b-link>
      </header>
      <app-spinner v-if="isSidebarLoading" css="sidebar-loader" text="Loading..."></app-spinner>
      <div class="b-sidebar-content px-4 pb-3">
        <router-view></router-view>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import AppSpinner from '@/components/ui/spinner.vue'
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      sidebar: {
        visible: false,
      },
    }
  },
  components: {
    AppSpinner,
  },
  computed: {
    ...mapGetters('app', ['isSidebarLoading', 'getSidebarTitle', 'getSidebarClass']),
  },
  watch: {
    $route: function () {
      // Show sidebar if childroute is loaded
      this.sidebar.visible = this.$route.matched.length > 1 ? true : false
    },
  },
  methods: {
    hideSidebar() {
      // Set route to parent if sidebar gets hidden (on closing "x" cross or clicking ESC)
      this.$router
        .push({ path: this.$route.matched[0].path, query: { ...this.$route.query } })
        .catch((err) => err)
      this.sidebar.visible = false
    },
  },
  created() {
    // Show sidebar if childroute is loaded
    this.sidebar.visible = this.$route.matched.length > 1 ? true : false
  },
}
</script>

<style lang="scss">
header.sidebar-header {
  position: relative;
}

.sticky-footer .b-sidebar-body {
  display: flex;
  flex-direction: column;

  .b-sidebar-content {
    flex: 1;
    overflow: hidden;
  }
}

.b-sidebar-backdrop {
  opacity: 0.25;
}
</style>
