var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageLoading),expression:"!isPageLoading"}]},[_c('app-page-header',{attrs:{"title":"References"}},[(_vm.listReferences.length)?[_c('div',{staticClass:"mr-2"})]:_vm._e(),_c('div',[_c('b-button',{staticClass:"btn-minwidth mr-0",attrs:{"variant":"primary","to":"/references/add"}},[_vm._v("Add Reference")])],1)],2),(_vm.listReferences.length)?_c('div',[_c('app-toolbar',{attrs:{"defaultValues":{
        viewMode: 'list',
        sortDirection: 'desc',
        sortBy: 'createdOn',
      },"sortOptions":[
        {
          label: 'creation date',
          key: 'createdOn',
        },
        {
          label: 'project',
          key: 'project',
        },
        {
          label: '# comments',
          key: 'comments',
        },
        {
          label: '# likes',
          key: 'likes',
        },
        {
          label: 'worker',
          key: 'worker',
        } ],"searchPlaceholder":"Search by project title"},on:{"search":function($event){_vm.filter.search = $event},"changeViewMode":function($event){_vm.toolbar.viewMode = $event},"changeSortBy":function($event){_vm.toolbar.sortBy = $event},"changeSortDirection":function($event){_vm.toolbar.sortDirection = $event}},scopedSlots:_vm._u([{key:"additional-filters",fn:function(){return [_c('div',{staticClass:"ml-2"},[_c('multiselect',{staticClass:"input-filter",attrs:{"value":_vm.filter.worker.value,"track-by":"id","show-labels":false,"internal-search":false,"options":_vm.filter.worker.options,"placeholder":"Filter by worker"},on:{"search-change":_vm.searchFilter,"input":_vm.updateQueryString},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var option = ref.option;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('app-photo-user',{staticClass:"mr-2",attrs:{"photo":option.photo,"size":"s","placeholder":_vm._f("getInitials")(((option.firstname) + " " + (option.lastname)))}}),_vm._v(" "+_vm._s(option.firstname)+" "+_vm._s(option.lastname)+" ")],1)]}},{key:"singleLabel",fn:function(ref){
      var option = ref.option;
return [_vm._v(_vm._s(_vm.findWorker(option).firstname)+" "+_vm._s(_vm.findWorker(option).lastname))]}}],null,false,3554294324)},[_c('template',{slot:"noResult"},[_vm._v("No workers found.")])],2)],1)]},proxy:true}],null,false,1711564002)}),_c('b-table',{ref:"referenceTable",attrs:{"show-empty":"","items":_vm.sortedReferences({ sortBy: _vm.toolbar.sortBy, sortDirection: _vm.toolbar.sortDirection }),"filter":_vm.filter,"filter-function":_vm.searchTable,"fields":_vm.table,"hover":""},scopedSlots:_vm._u([{key:"emptyfiltered",fn:function(){return [_c('app-search-no-results')]},proxy:true},{key:"cell(date)",fn:function(ref){
      var reference = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(reference.createdOn))+" ")]}},{key:"cell(project)",fn:function(ref){
      var reference = ref.item;
return [_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-reference",modifiers:{"modal-reference":true}}],staticClass:"text-decoration-none",attrs:{"to":{
            name: 'showReference',
            params: { id: reference.id },
            query: Object.assign({}, _vm.$route.query),
          }}},[_c('strong',[_vm._v(_vm._s(reference.project))]),(reference.photos.length)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-2",attrs:{"title":"This reference project has pictures attached"}},[_c('b-icon',{staticClass:"text-gray-500",attrs:{"icon":"images","font-scale":"1.2"}})],1):_vm._e()])]}},{key:"cell(comments)",fn:function(ref){
          var reference = ref.item;
return [_vm._v(" "+_vm._s(reference.comments ? reference.comments.length : 0)+" ")]}},{key:"cell(likes)",fn:function(ref){
          var reference = ref.item;
return [_c('span',{staticClass:"cursor-default",attrs:{"id":("likes-" + (reference.id))}},[_vm._v(" "+_vm._s(reference.likes ? reference.likes.length : 0)+" ")]),(reference.likes)?_c('b-tooltip',{attrs:{"target":("likes-" + (reference.id)),"custom-class":"tooltip-small"}},_vm._l((reference.likes),function(like,index){return _c('div',{key:("like-" + (reference.id) + "-" + index)},[(_vm.findWorker(like).firstname)?_c('div',[_vm._v(" "+_vm._s(_vm.findWorker(like).firstname)+" "+_vm._s(_vm.findWorker(like).lastname)+" ")]):_c('div',[_vm._v("Deleted Worker")])])}),0):_vm._e()]}},{key:"cell(worker)",fn:function(ref){
          var reference = ref.item;
return [(!reference.isPopulated)?_c('content-placeholders',{staticClass:"w-75"},[_c('content-placeholders-text',{attrs:{"lines":1}})],1):_c('div',{staticClass:"d-flex"},[_c('app-photo-user',{staticClass:"mr-2",attrs:{"photo":reference.worker.photo,"size":"s","placeholder":reference.worker.id
                ? _vm.$options.filters.getInitials(
                    ((reference.worker.firstname) + " " + (reference.worker.lastname))
                  )
                : ''}}),_vm._v(" "+_vm._s(reference.worker.firstname)+" "+_vm._s(reference.worker.lastname)+" ")],1)]}},{key:"cell(actions)",fn:function(ref){
                var reference = ref.item;
return [_c('app-reference-actions',{attrs:{"reference":reference},on:{"delete":function($event){_vm.selectedItem = $event}}})]}}],null,false,287586733)})],1):_c('div',{staticClass:"text-center mt-10"},[_c('b-icon',{staticClass:"mb-3 text-gray-500",attrs:{"icon":"star","font-scale":"2.5"}}),_c('h5',[_vm._v("No references yet")]),_vm._m(0)],1),_c('app-confirm-modal',{attrs:{"title":"Delete Reference"},on:{"ok":function($event){return _vm.deleteReference({ id: _vm.selectedItem.id })}}},[_c('div',[_vm._v(" Are you sure you want to delete the reference "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.project))]),_vm._v(" permanently? This action can not be undone. ")])]),_c('router-view',{attrs:{"next":_vm.next},on:{"getNext":_vm.getNext}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" There are currently no reference projects yet. "),_c('br'),_vm._v("Your workers can add them as soon as you invite them to the app. ")])}]

export { render, staticRenderFns }