var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"title":"Dashboard"}}),_c('b-card',{staticClass:"mb-4"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"card-col-image"},[_c('b-card-img',{staticClass:"onboarding-img mt-4",attrs:{"src":"/img/dashboard.png"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card-body',{attrs:{"title":"Welcome to WeBuild"}},[_c('b-card-text',{staticClass:"text-secondary"},[_c('p',[_vm._v(" WeBuild helps you to increase the productivity of your workforce by providing a compelling platform for performance recording and bonus compensation. Start imediattely with a few easy steps: ")]),_c('ol',[_c('li',[_vm._v("Define your bonus compensation workflow.")]),_c('li',[_vm._v("Invite your workforce to WeBuild.")]),_c('li',[_vm._v("Review project references and grant bonus payments.")]),_c('li',[_vm._v("Collect customer reviews and add them to the system.")])])])],1)],1)],1)],1),_c('b-row',{attrs:{"align-v":"stretch"}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"p-3 widget h-100",attrs:{"title":"Latest Reviews"}},[_c('b-card-text',_vm._l((_vm.listLatestReviews),function(review,index){return _c('router-link',{key:review.id,staticClass:"text-decoration-none text-secondary d-block",class:{ 'border-bottom pb-3 mb-3': index < 4 },attrs:{"to":{
              name: 'showReviews',
              params: { id: review.workerId },
              query: Object.assign({}, _vm.$route.query),
            }}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',[_vm._v(_vm._s(_vm.findWorker(review.workerId).firstname)+" "+_vm._s(_vm.findWorker(review.workerId).lastname))]),_c('span',{staticClass:"text-secondary text-xsmall"},[_vm._v(_vm._s(_vm._f("formatTimeRelative")(review.createdOn)))])]),_c('div',{staticClass:"text-small"},[_c('b-icon',{staticClass:"mr-1 text-primary",attrs:{"icon":"star-fill","font-scale":"1"}}),_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(Math.round(review.avgRating * 10) / 10))]),_vm._v(" by "),_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(review.fullname))]),(review.company)?_c('span',[_vm._v(" from "+_vm._s(review.company))]):_vm._e()],1)])}),1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"p-3 widget h-100",attrs:{"title":"Latest Activities"}},[_c('b-card-text',_vm._l((_vm.listLastActiveWorkers),function(worker,index){return _c('router-link',{key:worker.id,staticClass:"text-decoration-none text-secondary d-flex",class:{ 'border-bottom pb-3 mb-3': index < 4 },attrs:{"to":{
              name: 'workers',
              query: Object.assign({}, _vm.$route.query),
            }}},[_c('app-photo-user',{staticClass:"mr-3",attrs:{"photo":worker.photo,"size":"m","placeholder":_vm._f("getInitials")(((worker.firstname) + " " + (worker.lastname)))}}),_c('div',{staticClass:"flex-fill"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(worker.firstname)+" "+_vm._s(worker.lastname))]),_c('span',{staticClass:"text-secondary text-xsmall"},[_vm._v(" "+_vm._s(_vm._f("formatTimeRelative")(worker.lastActivity))+" ")])]),_c('span',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(worker.jobtitle)+" ")])])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }