<template>
  <b-modal id="modal-reference" @hide="hideModal" :visible="modal.visible" hide-footer>
    <template v-slot:modal-title>{{ reference.project }}</template>
    <slot>
      <template v-if="reference.isPopulated">
        <b-carousel
          v-if="reference.photos"
          style="margin: 0 -1.5rem"
          class="mb-3"
          v-model="slide"
          :interval="0"
          controls
          indicators
        >
          <b-carousel-slide v-for="photo in reference.photos" :key="photo">
            <template v-slot:img>
              <div
                class="h-100 w-100 reference-image"
                :style="`background-image:url(${photo})`"
              ></div>
            </template>
          </b-carousel-slide>
        </b-carousel>
        <div class="d-flex justify-content-between align-items-center">
          <div v-if="reference.worker" class="d-flex align-items-center">
            <app-photo-user
              :photo="reference.worker.photo"
              size="m"
              :placeholder="
                `${reference.worker.firstname} ${reference.worker.lastname}` | getInitials
              "
            ></app-photo-user>
            <div class="ml-2">
              <div>
                <strong class="text-primary"
                  >{{ reference.worker.firstname }} {{ reference.worker.lastname }}</strong
                >
              </div>
              <span v-if="reference.worker.jobtitle" class="text-secondary">{{
                reference.worker.jobtitle
              }}</span>
            </div>
          </div>
          <div>{{ reference.createdOn | formatDate }}</div>
        </div>
        <hr />
        <div v-if="reference.description" class="description mb-2">{{ reference.description }}</div>
        <div v-if="reference.competences">
          <b-badge
            v-for="competence in reference.competences"
            class="mr-2 mb-2"
            :key="competence.id"
            variant="secondary"
            >{{ competence.name }}</b-badge
          >
        </div>
        <div class="text-small mt-2">
          <span class="cursor-default" id="likes-tooltip">
            <b-icon icon="hand-thumbs-up" font-scale="1"></b-icon>
            {{ reference.likes ? reference.likes.length : 0 }}
          </span>
          <b-tooltip target="likes-tooltip" v-if="reference.likes" custom-class="tooltip-small">
            <div v-for="(like, index) in reference.likes" :key="`like-${reference.id}-${index}`">
              <div v-if="findWorker(like).firstname">
                {{ findWorker(like).firstname }} {{ findWorker(like).lastname }}
              </div>
              <div v-else>Deleted Worker</div>
            </div>
          </b-tooltip>
        </div>
        <div v-if="reference.comments.length">
          <hr />
          <div v-for="comment in reference.comments" class="text-small mb-3" :key="comment.id">
            <div class="d-flex align-items-center">
              <app-photo-user
                :photo="findWorker(comment.workerId).photo"
                size="s"
                :placeholder="
                  findWorker(comment.workerId).firstname
                    ? `${findWorker(comment.workerId).firstname} ${
                        findWorker(comment.workerId).lastname
                      }`
                    : '' | getInitials
                "
              ></app-photo-user>
              <div class="ml-2 comment-header">
                <div>
                  <strong class="text-primary" v-if="findWorker(comment.workerId).firstname"
                    >{{ findWorker(comment.workerId).firstname }}
                    {{ findWorker(comment.workerId).lastname }}</strong
                  >
                  <strong class="text-primary" v-else>[Deleted Worker]</strong>
                </div>
                <span class="text-xsmall text-gray-500">
                  {{ comment.createdOn | formatTimeRelative }}
                </span>
              </div>
            </div>
            <div class="comment">
              {{ comment.message }}
            </div>
          </div>
        </div>
      </template>
      <app-spinner v-else text="Loading..." class="m-5"></app-spinner>
    </slot>
  </b-modal>
</template>

<script>
import AppSpinner from '@/components/ui/spinner.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['id', 'next'],
  data: function () {
    return {
      modal: {
        visible: false,
      },
      reference: {},
      slide: 0,
    }
  },
  components: {
    AppSpinner,
    AppPhotoUser,
  },
  watch: {
    $route: async function () {
      // Show modal if childroute is loaded
      this.modal.visible = this.$route.matched.length > 1 ? true : false

      // Reload data if child route has changed
      if (this.$route.matched.length > 1) {
        await this.loadReference({ awaitPopulated: true, id: this.id })
        this.reference = this.findReference(this.id)
      }
    },
    next: function () {
      if (this.next)
        this.$router
          .push({
            name: 'showReference',
            params: { id: this.next },
            query: { ...this.$route.query },
          })
          .catch((err) => err)
    },
  },
  computed: {
    ...mapGetters('references', ['findReference']),
    ...mapGetters('workers', ['findWorker']),
  },
  methods: {
    ...mapActions('references', ['loadReference']),
    hideModal(e) {
      e.preventDefault()
      this.$router.push({
        name: 'references',
        query: { ...this.$route.query },
      })
      this.modal.visible = false
    },
    switchReference(e) {
      switch (e.key) {
        case 'ArrowLeft':
          this.$emit('getNext', { type: 'previous', id: this.id })
          break
        case 'ArrowRight':
          this.$emit('getNext', { type: 'next', id: this.id })
          break
      }
    },
  },
  async created() {
    window.addEventListener('keyup', this.switchReference)

    // Show modal if childroute is loaded
    this.modal.visible = this.$route.matched.length > 1 ? true : false

    await this.loadReference({ awaitPopulated: true, id: this.id })
    this.reference = this.findReference(this.id)
  },
  destroyed() {
    window.removeEventListener('keyup', this.switchReference)
  },
}
</script>

<style lang="scss">
.carousel-item {
  height: 300px;
}

.reference-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.description {
  white-space: pre-line;
}

.comment {
  margin-left: 32px;
}

.comment-header {
  line-height: 1.2rem;
}
</style>
