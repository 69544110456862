<template>
  <b-modal :id="customId" @ok="$emit('ok')" @shown="focusConfirm">
    <template v-slot:modal-title>
      <b-icon
        icon="exclamation-triangle"
        font-scale="1.2"
        class="mr-2 text-danger"
      ></b-icon>
      {{ title }}
    </template>
    <slot></slot>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="light" @click="cancel()" class="btn-minwidth"
        >Cancel</b-button
      >
      <b-button
        variant="danger"
        @click="ok()"
        ref="confirm"
        class="btn-minwidth"
        >Confirm</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    customId: { type: String, default: 'deleteModal' },
  },
  methods: {
    focusConfirm() {
      this.$refs.confirm.focus()
    },
  },
}
</script>
