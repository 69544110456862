import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'
import { get } from 'lodash'

const cleanString = val => {
  /* eslint-disable */
  return val
    .toString()
    .replace(/ /g, '')
    .replace(/[^\x00-\x7F]/g, '')
  /* eslint-enable */
}

const renderTemplateVars = (message, data = {}) => {
  const tplVars = message.match(/[^{{]+(?=}\})/g)

  tplVars.forEach(item => {
    message = message.replace(`{{${item}}}`, get(data, item))
  })

  return message
}

const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const generateFirebaseUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

const formatPhoneNumber = value => {
  try {
    return new PhoneNumber(value).getNumber('international')
  } catch {
    return value
  }
}

const formatDate = value => {
  if (value) return moment(value).format('DD.MM.YYYY – HH:mm')
}

export { generateUUID, generateFirebaseUUID, clone, formatPhoneNumber, renderTemplateVars, cleanString, formatDate }
