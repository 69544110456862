/* eslint-disable */
declare global {
  interface Window {
    analytics: any
  }
}

declare const analytics: {
  track(event: string, data: any): void
  identify(data: any): void
  alias(data: any): void
  people(data: any): void
  page(): void
}
/* eslint-enable */

const trackInit = user => {
  analytics.alias(user.uid)
  window.analytics.identify(user.uid, {
    name: user.displayName,
    email: user.email,
  })
}

const trackPage = name => {
  window.analytics.page(name)
}

const trackEvent = (name, data = {}) => {
  window.analytics.track(name, data)
}

export { trackInit, trackPage, trackEvent }
