<template>
  <div class="ratings-width">
    <content-placeholders v-if="!worker.isPopulated" :centered="centered">
      <content-placeholders-text :lines="2" />
    </content-placeholders>
    <div v-else>
      <router-link
        v-if="worker.reviews.totalCount"
        class="text-decoration-none"
        :to="{ name: 'showReviews', params: { id: worker.id }, query: { ...$route.query } }"
      >
        <b-form-rating
          v-model="worker.reviews.avgRating"
          size="sm"
          precision="1"
          class="p-0 text-primary"
          show-value
          readonly
          no-border
          inline
        ></b-form-rating>
        <div class="text-secondary text-xsmall">
          {{ worker.reviews.verifiedCount }}
          {{ 'Review' | pluralize(worker.reviews.verifiedCount) }}
          <span v-if="worker.reviews.verifiedCount"
            >({{
              (
                (worker.reviews.verifiedRecommendations / worker.reviews.verifiedCount) *
                100
              ).toFixed()
            }}%)
          </span>
          <span
            v-if="worker.reviews.unverifiedCount"
            v-b-tooltip.hover
            title="This worker has at least one unverified review."
            ><b-icon
              icon="exclamation-circle-fill"
              font-scale="1"
              class="ml-1 text-warning"
            ></b-icon
          ></span>
        </div>
      </router-link>
      <b-button
        v-else
        sidebar-right
        variant="light"
        :class="buttonClass"
        :to="{ name: 'addReviews', params: { id: worker.id }, query: { ...$route.query } }"
        size="sm"
        >Add Review</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['worker', 'buttonClass', 'centered'],
}
</script>

<style lang="scss" scoped>
.form-control-sm {
  height: 1.2rem;
  background-color: transparent;
}

.vue-content-placeholders-is-centered {
  margin: 0 auto;
}

.ratings-width {
  width: 160px;
}
</style>