var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isPageLoading)?_c('div',[_c('app-page-header',{attrs:{"title":"Workers"}},[_c('b-dropdown',{staticClass:"text-nowrap",attrs:{"split":"","text":"Add Worker","split-to":"/workers/add","variant":"primary","right":""}},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-importer",modifiers:{"modal-importer":true}}]},[_c('b-icon',{attrs:{"icon":"cloud-arrow-up","font-scale":"1.3"}}),_vm._v("Import Workers")],1),_c('b-dropdown-item',{attrs:{"sidebar-right":"","to":{ name: 'showTeamReview', query: Object.assign({}, _vm.$route.query) }}},[_c('b-icon',{attrs:{"icon":"star","font-scale":"1.2"}}),_vm._v("Team Review")],1)],1)],1),(_vm.listWorkers.length)?_c('div',[_c('app-toolbar',{attrs:{"defaultValues":{
        viewMode: 'list',
        sortDirection: 'asc',
        sortBy: 'lastname',
      },"sortOptions":[
        {
          label: 'firstname',
          key: 'firstname',
        },
        {
          label: 'lastname',
          key: 'lastname',
        },
        {
          label: 'jobtitle',
          key: 'jobtitle',
        },
        {
          label: 'rating score',
          key: 'reviews.avgRating',
        },
        {
          label: '# reviews',
          key: 'reviews.verifiedCount',
        },
        {
          label: '# references',
          key: 'references',
        },
        {
          label: 'balance',
          key: 'balance',
        },
        {
          label: 'last active',
          key: 'lastActivity',
        } ],"searchPlaceholder":"Search by name, jobtitle"},on:{"search":function($event){_vm.filter.search = $event},"changeViewMode":function($event){_vm.toolbar.viewMode = $event},"changeSortBy":function($event){_vm.toolbar.sortBy = $event},"changeSortDirection":function($event){_vm.toolbar.sortDirection = $event}}}),(this.toolbar.viewMode === 'card')?[(_vm.filteredWorkers().length)?_c('div',{staticClass:"cards"},_vm._l((_vm.filteredWorkers()),function(worker){return _c('app-card',{key:worker.id,attrs:{"photo":worker.photo,"photo-placeholder":_vm._f("getInitials")(((worker.firstname) + " " + (worker.lastname))),"href":("/workers/" + (worker.id)),"title":((worker.firstname) + " " + (worker.lastname)),"subtitle":worker.jobtitle},scopedSlots:_vm._u([{key:"card-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('app-references-preview',{staticClass:"w-30",attrs:{"worker":worker}}),_c('app-balance-preview',{staticClass:"w-30 text-right",attrs:{"worker":worker}})],1)]},proxy:true},{key:"card-content",fn:function(){return [_c('div',{staticClass:"card-content mt-3 mb-5"},[_c('app-ratings-preview',{staticClass:"text-center",attrs:{"buttonClass":"mt-1","worker":worker,"centered":""}})],1)]},proxy:true},{key:"card-dropdown",fn:function(){return [_c('app-worker-actions',{attrs:{"worker":worker},on:{"delete":_vm.setSelected}})]},proxy:true}],null,true)})}),1):_c('app-search-no-results')]:_c('b-table',{ref:"workersTable",attrs:{"items":_vm.sortedWorkers({ sortBy: _vm.toolbar.sortBy, sortDirection: _vm.toolbar.sortDirection }),"fields":_vm.table,"filter":_vm.filter.search,"filter-function":_vm.searchWorkers,"responsive":"sm","show-empty":"","hover":""},scopedSlots:_vm._u([{key:"emptyfiltered",fn:function(){return [_c('app-search-no-results')]},proxy:true},{key:"cell(photo)",fn:function(ref){
      var worker = ref.item;
return [_c('app-photo-user',{attrs:{"photo":worker.photo,"size":"m","placeholder":_vm._f("getInitials")(((worker.firstname) + " " + (worker.lastname)))}})]}},{key:"cell(phone)",fn:function(ref){
      var worker = ref.item;
return [_vm._v(" "+_vm._s(worker.phone)+" "),_c('div',{staticClass:"text-xsmall text-gray-500"},[_vm._v(" Last active: "+_vm._s(_vm._f("formatTimeRelative")(worker.lastActivity))+" ")])]}},{key:"cell(name)",fn:function(ref){
      var worker = ref.item;
return [_c('b-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'editWorker', params: { id: worker.id } }}},[_c('div',[_c('strong',[_vm._v(_vm._s(worker.firstname)+" "+_vm._s(worker.lastname))])]),(worker.jobtitle)?_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(worker.jobtitle))]):_vm._e()])]}},{key:"cell(ratingsAvg)",fn:function(ref){
      var worker = ref.item;
return [_c('app-ratings-preview',{attrs:{"worker":worker}})]}},{key:"cell(references)",fn:function(ref){
      var worker = ref.item;
return [_c('app-references-preview',{attrs:{"worker":worker}})]}},{key:"cell(balance)",fn:function(ref){
      var worker = ref.item;
return [_c('app-balance-preview',{attrs:{"worker":worker}})]}},{key:"cell(actions)",fn:function(ref){
      var worker = ref.item;
return [_c('app-worker-actions',{attrs:{"worker":worker},on:{"delete":_vm.setSelected}})]}}],null,false,4117628031)})],2):_c('div',{staticClass:"text-center mt-10"},[_c('b-icon',{staticClass:"mb-3 text-gray-500",attrs:{"icon":"people","font-scale":"2.5"}}),_c('h5',[_vm._v("No workers yet")]),_c('p',[_vm._v(" There are currently no workers yet. "),_c('br'),_vm._v("Start adding them "),_c('b-link',{staticClass:"text-underline",attrs:{"to":"/workers/add"}},[_vm._v("manually")]),_vm._v(" or "),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-importer",modifiers:{"modal-importer":true}}],staticClass:"text-underline"},[_vm._v("import")]),_vm._v(" them. ")],1)],1),_c('app-confirm-modal',{attrs:{"title":"Delete Worker"},on:{"ok":function($event){return _vm.deleteWorker({ worker: _vm.selectedItem, deleteReferences: _vm.deleteReferences })}}},[_c('div',[_vm._v(" Are you sure you want to delete the worker "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.firstname)+" "+_vm._s(_vm.selectedItem.lastname))]),_vm._v(" permanently? This action can not be undone. "),_c('b-form-checkbox',{staticClass:"mt-3",attrs:{"value":true},model:{value:(_vm.deleteReferences),callback:function ($$v) {_vm.deleteReferences=$$v},expression:"deleteReferences"}},[_vm._v(" Delete references of "+_vm._s(_vm.selectedItem.firstname)+" "+_vm._s(_vm.selectedItem.lastname)+" as well ("+_vm._s(_vm.findWorkerReferences(_vm.selectedItem.id).length)+") ")])],1)]),_c('app-worker-importer'),_c('app-sidebar')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }