import Vue from 'vue'
import * as fb from '@/firebase'
import { toastSuccess } from '@/components/ui/toast'
import { generateUUID, generateFirebaseUUID } from '@/utils'
import router from '@/router/index'
import { trackEvent } from '@/utils/analytics'

import { cloneDeep } from 'lodash'

const state = {
  all: [],
}

const mutations = {
  SET_USERS(state, val) {
    state.all = val
  },
  DELETE_PHOTO(state, id) {
    const user = state.all.find(user => user.id === id)
    // Remove photo property from user
    //Vue.delete(user, 'photoURL') //somehow it's not reactive if deleted like that (on this view)
    Vue.set(user, 'photoURL', null)
  },
  ADD_PHOTO(state, { id, photo }) {
    const user = state.all.find(user => user.id === id)
    // Add photo property to user
    Vue.set(user, 'photoURL', photo)
  },
  DELETE_USER(state, id) {
    const index = state.all.map(user => user.id).indexOf(id)
    state.all.splice(index, 1)
  },
}

const getters = {
  listUsers(state) {
    return state.all
  },
  findUser(state) {
    return id => {
      return state.all.find(user => user.id === id)
    }
  },
}

const actions = {
  loadUsers: async function({ commit }) {
    const { data } = await this['$axios'].get('/api/users')

    commit('SET_USERS', data)
  },
  loadUser: async function({ commit }, id) {
    const { data } = await this['$axios'].get(`/api/users/${id}`)

    commit('SET_USERS', [data])
  },
  updateUser: async function({ commit, dispatch, rootGetters, getters }, user) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone user not to modify form data when saving (otherwise form looks broken for a short moment)
    user = cloneDeep(user)

    const update = {
      email: user.email,
      role: user.role.value,
      password: user.password,
      displayName: user.displayName,
      photoURL: user.photo, // Firebase has a fixed name for the photoURL
    }

    // 1) Upload new photo if set
    if (user.photo) {
      // If there is already one existing, delete old one first
      if (getters.findUser(user.id).photoURL)
        await dispatch('deletePhoto', { user: getters.findUser(user.id)})

      update.photoURL = await dispatch('uploadPhoto', {
        userId: user.id,
        photo: user.photo,
      })
    } else {
      // Otherwise keep old one
      delete update.photoURL
    }

    // If no new password is set, remove it from update
    if (!user.password) delete update.password

    await this['$axios'].put(`/api/users/${user.id}`, update)

    // If updated user is signed in user -> update store
    if (rootGetters['auth/getUserProfile'].uid === user.id) {
      // Delete password to not store it in store
      delete update.password

      commit('auth/UPDATE_USER_PROFILE', update, { root: true })
    }

    trackEvent('User Updated')

    toastSuccess(this, `The user ${user.displayName} was updated.`)
    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to users and append queryString
    router
      .push({ path: '/users', query: { ...rootGetters['app/getRedirectQuery'] } })
      .catch(err => err)
  },
  uploadPhoto: async function(context, { userId, photo }) {
    try {
      const filename = `${generateUUID()}.${photo.name.split('.').pop()}`
      const path = `users/${userId}/${filename}`

      // Await photo upload to store
      await fb.storage.ref(path).put(photo)

      // Call cloud function to resize image
      const { data } = await this['$axios'].post('/api/utils/resizeimage', { file: path })

      return data.url
    } catch (err) {
      return null
    }
  },
  deletePhoto: async function({ commit }, { user, updateDatabase = true } ) {
    // Get filename of photo from public url
    const file = decodeURIComponent(user.photoURL)
      .split('?')[0]
      .split('/')
      .pop()

    // Delete photo on storage
    fb.storage
      .ref(`users/${user.id}/${file}`)
      .delete()
      .catch(err => err)

    // Delete photo property from database (if user gets delete, no need to update the database)
    if(updateDatabase)
      await this['$axios'].put(`/api/users/${user.id}`, { photoURL: null }).catch(err => err)

    // Delete photo property from state
    commit('DELETE_PHOTO', user.id)
  },
  addUser: async function({ commit, dispatch, rootGetters }, user) {
    commit('app/SET_PROCESSING', true, { root: true })

    // Clone user not to modify form data when saving (otherwise form looks broken for a short moment)
    user = cloneDeep(user)

    const uid = generateFirebaseUUID()

    const newUser = {
      uid,
      email: user.email,
      role: user.role.value,
      password: user.password,
      displayName: user.displayName,
    }

    // 1) Upload new photo if set
    if (user.photo) {
      newUser['photoURL'] = await dispatch('uploadPhoto', {
        userId: uid,
        photo: user.photo,
      })
    }

    await this['$axios'].post(`/api/users`, newUser)

    trackEvent('User Created')

    toastSuccess(this, `The user ${user.displayName} was created.`)
    commit('app/SET_PROCESSING', false, { root: true })

    // Change route to users and append queryString
    router
      .push({ path: '/users', query: { ...rootGetters['app/getRedirectQuery'] } })
      .catch(err => err)
  },
  deleteUser: async function({ commit, dispatch }, user) {
    // Delete photo if set
    if (user.photoURL) dispatch('deletePhoto', { user, updateDatabase: false })

    // Delete user from database
    await this['$axios'].delete(`/api/users/${user.id}`).catch(err => err)

    // Delete user from store
    commit('DELETE_USER', user.id)

    trackEvent('User Deleted')
    toastSuccess(this, `The user ${user.displayName} was deleted.`)
  },
  isEmailExisting: async function({ commit, dispatch, getters }, email) {
    return getters.listUsers.find(user => user.email === email)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
