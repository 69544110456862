<template>
  <div>
    <app-page-header title="Dashboard"> </app-page-header>
    <b-card class="mb-4">
      <b-row no-gutters>
        <b-col class="card-col-image">
          <b-card-img src="/img/dashboard.png" class="onboarding-img mt-4"></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body title="Welcome to WeBuild">
            <b-card-text class="text-secondary">
              <p>
                WeBuild helps you to increase the productivity of your workforce by providing a
                compelling platform for performance recording and bonus compensation. Start
                imediattely with a few easy steps:
              </p>
              <ol>
                <li>Define your bonus compensation workflow.</li>
                <li>Invite your workforce to WeBuild.</li>
                <li>Review project references and grant bonus payments.</li>
                <li>Collect customer reviews and add them to the system.</li>
              </ol>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-row align-v="stretch">
      <b-col md="6">
        <b-card title="Latest Reviews" class="p-3 widget h-100">
          <b-card-text>
            <router-link
              v-for="(review, index) in listLatestReviews"
              :key="review.id"
              :class="{ 'border-bottom pb-3 mb-3': index < 4 }"
              class="text-decoration-none text-secondary d-block"
              :to="{
                name: 'showReviews',
                params: { id: review.workerId },
                query: { ...$route.query },
              }"
            >
              <div class="d-flex justify-content-between">
                <strong
                  >{{ findWorker(review.workerId).firstname }}
                  {{ findWorker(review.workerId).lastname }}</strong
                >
                <span class="text-secondary text-xsmall">{{
                  review.createdOn | formatTimeRelative
                }}</span>
              </div>
              <div class="text-small">
                <b-icon icon="star-fill" font-scale="1" class="mr-1 text-primary"></b-icon
                ><strong class="text-primary">{{ Math.round(review.avgRating * 10) / 10 }}</strong>
                by
                <strong class="text-primary">{{ review.fullname }}</strong>
                <span v-if="review.company"> from {{ review.company }}</span>
              </div>
            </router-link>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Latest Activities" class="p-3 widget h-100">
          <b-card-text>
            <router-link
              v-for="(worker, index) in listLastActiveWorkers"
              :key="worker.id"
              :class="{ 'border-bottom pb-3 mb-3': index < 4 }"
              class="text-decoration-none text-secondary d-flex"
              :to="{
                name: 'workers',
                query: { ...$route.query },
              }"
            >
              <app-photo-user
                :photo="worker.photo"
                size="m"
                class="mr-3"
                :placeholder="`${worker.firstname} ${worker.lastname}` | getInitials"
              ></app-photo-user>
              <div class="flex-fill">
                <div class="d-flex justify-content-between">
                  <strong class="text-primary">{{ worker.firstname }} {{ worker.lastname }}</strong>
                  <span class="text-secondary text-xsmall">
                    {{ worker.lastActivity | formatTimeRelative }}
                  </span>
                </div>
                <span class="text-secondary">
                  {{ worker.jobtitle }}
                </span>
              </div>
            </router-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AppPageHeader,
    AppPhotoUser,
  },
  computed: {
    ...mapGetters('reviews', ['listLatestReviews']),
    ...mapGetters('workers', ['findWorker', 'listLastActiveWorkers']),
  },
  methods: {
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('workers', ['loadWorkers']),
  },
  created() {
    this.SET_PAGELOADING(false)
    this.loadWorkers()
  },
}
</script>

<style lang="scss" scoped>
.onboarding-img {
  margin-left: -1.25rem;
}

.card-col-image {
  max-width: 340px;
}
</style>