<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Edit User"></app-page-header>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Name*" label-for="userDisplayName">
        <b-form-input
          id="userDisplayName"
          tabindex="1"
          v-model.trim="$v.form.displayName.$model"
          :state="validateState($v.form.displayName)"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Email*" label-for="userEmal">
        <b-form-input
          id="userEmail"
          tabindex="2"
          v-model.trim="$v.form.email.$model"
          :state="validateState($v.form.email)"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.email.isEmailExisting"
          >There is already a user with this email existing.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Role*"
        label-for="userRole"
        :class="{ 'is-invalid': $v.form.role.$error }"
        description="Only the admin role has access to app settings and managing admin users."
      >
        <multiselect
          v-model="$v.form.role.$model"
          :options="roles"
          :tabindex="3"
          :show-labels="false"
          track-by="value"
          label="label"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
        </multiselect>
      </b-form-group>

      <b-form-group
        label="Set new Password"
        label-for="userPassword"
        description="Password needs to be at least 6 characters long"
      >
        <b-form-input
          id="userPassword"
          type="password"
          tabindex="4"
          autocomplete="new-password"
          v-model.trim="$v.form.password.$model"
          :state="validateState($v.form.password)"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Repeat new Password" label-for="userRepeatPassword">
        <b-form-input
          id="userRepeatPassword"
          type="password"
          tabindex="5"
          autocomplete="new-password"
          v-model.trim="$v.form.passwordRepeat.$model"
          :state="validateState($v.form.passwordRepeat)"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.passwordRepeat.sameAsPassword"
          >Passwords must be identical.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label="Photo" label-for="workerPhoto">
        <div v-if="user.photoURL" class="mb-2">
          <div class="img-removable">
            <app-photo-user
              :photo="user.photoURL"
              size="l"
              :placeholder="user.displayName | getInitials"
            ></app-photo-user>
            <a @click.prevent="deletePhoto({ user })">
              <b-icon icon="x" font-scale="1.2" class="text-white"></b-icon>
            </a>
          </div>
        </div>
        <b-form-file
          v-model="form.photo"
          ref="userPhoto"
          accept="image/jpeg, image/png, image/gif"
          placeholder="Choose a photo or drop it here..."
          drop-placeholder="Drop photo here..."
        ></b-form-file>
        <b-link @click="$refs['userPhoto'].reset()" class="link-small">Reset selection</b-link>
      </b-form-group>
      <app-button-spinner :loading="isProcessing" :tabindex="6">Save</app-button-spinner>
      <b-button variant="light" @click="$router.go(-1)" class="btn-minwidth">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import { validateState, isEmailExisting } from '@/utils/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['id'],
  data: function () {
    return {
      form: {
        displayName: '',
        email: '',
        password: '',
        passwordRepeat: '',
        photo: [],
        role: null,
      },
      roles: [
        { value: 'admin', label: 'Admin' },
        { value: 'manager', label: 'Manager' },
      ],
    }
  },
  validations: {
    form: {
      displayName: { required },
      email: { required, email, isEmailExisting },
      password: { minLength: minLength(6) },
      passwordRepeat: {
        sameAsPassword: sameAs('password'),
      },
      role: { required },
    },
  },
  computed: {
    ...mapGetters('app', ['isProcessing', 'isPageLoading']),
    ...mapGetters('users', ['findUser']),
  },
  components: {
    AppPageHeader,
    AppButtonSpinner,
    AppPhotoUser,
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('users', ['loadUsers', 'updateUser', 'deletePhoto']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.updateUser(this.form)
    },
  },
  async created() {
    this.SET_PAGELOADING(true)

    // Populate all users in store (to check for existing emails) -> Otherwise also this.loadUser(this.id) would work.
    await this.loadUsers()

    // Create local copy of the user for form manipulation and merge it with form obejct
    this.form = Object.assign(this.form, this._.cloneDeep(this.findUser(this.id)))
    this.form.role = this.roles.find((role) => role.value === this.form.role)

    // Reset photo so it's not interferring with form-field
    this.form.photo = null
    // Reference to user in store (needed to display photo)
    this.user = this.findUser(this.id)

    console.log(this.user)

    this.SET_PAGELOADING(false)
  },
}
</script>
