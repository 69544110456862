import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { trackPage } from '@/utils/analytics'

import LoginView from '../views/login.vue'
import DashboardView from '../views/dashboard.vue'
import WorkersView from '../views/workers.vue'
import WorkerReviewView from '../views/workers.reviews.vue'
import WorkerReviewAddView from '../views/workers.reviews.add.vue'
import WorkerReviewEditView from '../views/workers.reviews.edit.vue'
import WorkerAddView from '../views/workers.add.vue'
import WorkerEditView from '../views/workers.edit.vue'
import WorkerNotificationsView from '../views/workers.notifications.vue'
import WorkerTransactionsView from '../views/workers.transactions.vue'
import WorkersTeamReviewView from '../views/workers.teamreview.vue'
import ReferencesView from '../views/references.vue'
import ReferenceAddView from '../views/references.add.vue'
import ReferenceEditView from '../views/references.edit.vue'
import ReferenceShowView from '../views/references.show.vue'
import SettingsView from '../views/settings.vue'
import UsersView from '../views/users.vue'
import UsersAddView from '../views/users.add.vue'
import UsersEditView from '../views/users.edit.vue'
import NotificationsView from '../views/notifications.vue'

import store from '../store'

import * as firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      public: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    // meta: {
    //   roles: ['admin', 'manager'],
    // },
  },
  {
    path: '/workers',
    name: 'workers',
    component: WorkersView,
    meta: {
      roles: ['admin', 'manager'],
    },
    children: [
      {
        path: ':id/reviews',
        component: WorkerReviewView,
        name: 'showReviews',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
      {
        path: ':id/reviews/add',
        component: WorkerReviewAddView,
        name: 'addReviews',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
      {
        path: ':id/reviews/:reviewId/',
        component: WorkerReviewEditView,
        name: 'editReviews',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
      {
        path: ':id/notifications',
        component: WorkerNotificationsView,
        name: 'showNotifications',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
      {
        path: ':id/transactions',
        component: WorkerTransactionsView,
        name: 'showTransactions',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
      {
        path: 'teamreview',
        component: WorkersTeamReviewView,
        name: 'showTeamReview',
        meta: {
          roles: ['admin', 'manager'],
        },
      },
    ],
  },
  {
    path: '/workers/add',
    name: 'addWorker',
    component: WorkerAddView,
    meta: {
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/workers/:id',
    name: 'editWorker',
    props: true,
    component: WorkerEditView,
    meta: {
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/references',
    name: 'references',
    component: ReferencesView,
    meta: {
      roles: ['admin', 'manager'],
    },
    children: [
      {
        path: ':id/show',
        component: ReferenceShowView,
        name: 'showReference',
        props: true,
        meta: {
          roles: ['admin', 'manager'],
        },
      },
    ],
  },
  {
    path: '/references/add',
    name: 'addReference',
    component: ReferenceAddView,
    meta: {
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
    meta: {
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/references/:id',
    name: 'editReference',
    props: true,
    component: ReferenceEditView,
    meta: {
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/users/add',
    name: 'addUsers',
    component: UsersAddView,
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/users/:id',
    name: 'editUser',
    props: true,
    component: UsersEditView,
    meta: {
      roles: ['admin'],
    },
  },
  // Default route
  { path: '*', redirect: '/dashboard' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const requiredAuth = !to.matched.some(record => record.meta.public)
  const requiredRole = to.meta.roles || []
  const isAuthenticated = firebase.auth().currentUser
  const userRole = store.getters['auth/getUserProfile'].role
  const isAuthorized = requiredRole.length ? requiredRole.some(role => role === userRole) : true

  if (isAuthenticated) {
    // Refresh token as soon as it's expired
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        // Update token
        store.commit('auth/SET_TOKEN', token)
      })
  }

  trackPage(to.name)

  // Store query string if it's set
  store.commit('app/SET_REDIRECT_QUERY', from.query)

  if (requiredAuth && !isAuthenticated) {
    // Redirect to login if not authenticated user tries to access protected routes
    next('/login')
  } else if (!requiredAuth && isAuthenticated) {
    // Redirect to /dashboard if authenticated user tries to access public routes (login, register)
    next('/dashboard')
  } else if (isAuthenticated && !isAuthorized) {
    // Redirect to /dashboard if authenticated user tries to access not authorised route
    next('/dashboard')
  } else {
    next()
  }
})

export default router
