import store from '@/store'
import PhoneNumber from 'awesome-phonenumber'

const validateState = item => {
  const { $dirty, $error } = item
  return $dirty ? !$error : null
}

const isPhoneNumberExisting = async function(value) {
  // If field is empty, don't validate if existing
  if (value === '') return true

  const isExisting = await store.dispatch('workers/isPhoneNumberExisting', value)
  const workerId = this.worker ? this.worker.id : null

  // If number is existing and doesn't belong to this worker => error
  return isExisting && isExisting.id !== workerId ? false : true
}

const isPhoneNumber = value => {
  return PhoneNumber(value.toString()).isValid()
}

const isEmailExisting = async function(value) {
  // If field is empty, don't validate if existing
  if (value === '') return true

  const isExisting = await store.dispatch('users/isEmailExisting', value)
  const userId = this.user ? this.user.id : null

  // If number is existing and doesn't belong to this user => error
  return isExisting && isExisting.id !== userId ? false : true
}

const IsJsonString = async function(value) {
  if(!value) return true

  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }

  return true
}

export { validateState, isPhoneNumber, isPhoneNumberExisting, isEmailExisting, IsJsonString }
