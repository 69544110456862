<template>
  <div v-if="!isSidebarLoading" class="h-100">
    <b-form
      @submit.stop.prevent="onSubmit"
      ref="reviewForm"
      class="d-flex flex-column justify-content-between h-100"
    >
      <div class="review-container flex-grow-1">
        <b-form-group
          :class="{ 'is-invalid': $v.form.workers.$error }"
          description="Select every worker who should receive the review."
        >
          <label>Workers*</label>
          <multiselect
            v-model="$v.form.workers.$model"
            track-by="id"
            :multiple="true"
            :show-labels="false"
            :internal-search="false"
            :taggable="false"
            id="reviewWorkers"
            :options="workers"
            @search-change="searchWorker"
            :closeOnSelect="false"
            placeholder="Search or select a worker"
          >
            <template slot="option" slot-scope="{ option }">
              <div class="d-flex align-items-center">
                <app-photo-user
                  class="mr-2"
                  :photo="option.photo"
                  size="s"
                  :placeholder="`${option.firstname} ${option.lastname}` | getInitials"
                ></app-photo-user>
                {{ option.firstname }} {{ option.lastname }}
              </div>
            </template>
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag">
                <span>{{ option.firstname }} {{ option.lastname }}</span>
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @click="remove(option)"
                  class="multiselect__tag-icon"
                ></i>
              </span>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label="Name of Reviewer*" label-for="reviewFullname">
          <b-form-input
            id="reviewFullname"
            tabindex="1"
            :state="validateState($v.form.fullname)"
            v-model.trim="$v.form.fullname.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Company*" label-for="reviewCompany">
          <b-form-input
            id="reviewCompany"
            tabindex="2"
            :state="validateState($v.form.company)"
            v-model.trim="$v.form.company.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Project*" label-for="reviewProject">
          <b-form-input
            id="reviewProject"
            autocomplete="off"
            tabindex="3"
            :state="validateState($v.form.project)"
            v-model.trim="$v.form.project.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Comment" label-for="reviewComment">
          <b-form-textarea
            id="reviewComment"
            tabindex="4"
            rows="3"
            max-rows="6"
            v-model.trim="form.comment"
          ></b-form-textarea>
        </b-form-group>
        <b-form-checkbox
          v-model="form.recommend"
          :value="true"
          :unchecked-value="false"
          class="my-3"
          >I would recommend every person in this team</b-form-checkbox
        >
        <template v-if="form.categories">
          <b-form-group
            v-for="category in form.categories"
            :key="category.id"
            :label="category.name"
            :label-for="category.id"
            class="d-flex align-items-center justify-content-between mb-1 category"
          >
            <b-form-rating
              :id="category.id"
              size="sm"
              v-model="category.rating"
              no-border
            ></b-form-rating>
          </b-form-group>
        </template>
        <b-card class="mt-3 card-" bg-variant="light">
          <h6 class="mb-3 d-flex justify-content-between flex-row align-items-center">
            <span><b-icon icon="cash-stack" font-scale="1.2" class="mr-2"></b-icon>Grant Bonus</span
            ><b-form-checkbox
              v-model="form.bonus.granted"
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </h6>
          <p class="mb-0 text-small text-secondary">
            Grant a bonus to reward an exceptional review. Every worker gets it individually.
          </p>
          <div v-if="form.bonus.granted" class="mt-2">
            <b-form-group label="Amount" label-for="transactionAmount">
              <b-input-group append="CHF">
                <b-form-input
                  autofocus
                  id="transactionAmount"
                  :state="validateState($v.form.bonus.amount)"
                  autocomplete="off"
                  tabindex="5"
                  placeholder="0"
                  v-model.trim="$v.form.bonus.amount.$model"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Comment" label-for="transactionComment" class="mb-0">
              <b-form-textarea
                id="transactionComment"
                tabindex="6"
                rows="3"
                max-rows="6"
                v-model.trim="form.bonus.comment"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-card>
        <b-form-checkbox v-model="form.notifyWorker" :value="true" class="mt-3">
          Send notification to team
          <span
            class="ml-1 text-secondary"
            :title="
              form.bonus.granted
                ? findNotificationTemplate('reviewNewWithBonus').message
                : findNotificationTemplate('reviewNew').message
            "
            v-b-tooltip.hover
          >
            <b-icon icon="info-circle" font-scale="1"></b-icon
          ></span>
          <b-badge
            v-if="!listSettings.notifications.enabled"
            variant="danger"
            class="ml-2"
            title="Warning: Sending notifications is disabled in settings."
            v-b-tooltip.hover
            >Disabled</b-badge
          >
        </b-form-checkbox>
      </div>
      <div class="mt-2">
        <app-button-spinner :loading="isProcessing" :tabindex="7">Save</app-button-spinner>
        <b-button variant="light" @click.prevent="$router.go(-1)" class="btn-minwidth"
          >Cancel</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { required, numeric, requiredIf } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      workers: [],
      categories: [],
      form: {
        workers: [],
        fullname: '',
        company: '',
        project: '',
        comment: '',
        categories: {},
        recommend: false,
        notifyWorker: true,
        bonus: {
          granted: false,
          amount: '',
          comment: '',
        },
      },
    }
  },
  validations: {
    form: {
      workers: { required },
      fullname: { required },
      company: { required },
      project: { required },
      bonus: {
        amount: {
          requiredIf: requiredIf(function () {
            return this.form.bonus.granted
          }),
          numeric,
        },
      },
    },
  },
  computed: {
    ...mapGetters('app', ['isSidebarLoading', 'isProcessing']),
    ...mapGetters('workers', ['listWorkers']),
    ...mapGetters('categories', ['listPlainCategories']),
    ...mapGetters('settings', ['listSettings', 'findNotificationTemplate']),
  },
  components: {
    AppButtonSpinner,
    AppPhotoUser,
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_SIDEBARLOADING', 'SET_SIDEBARTITLE', 'SET_SIDEBARCLASS']),
    ...mapActions('reviews', ['addTeamReview']),
    ...mapActions('categories', ['loadCategories']),
    searchWorker(query) {
      this.workers = this.listWorkers.filter((worker) =>
        `${worker.firstname} ${worker.lastname}`.toLowerCase().includes(query.toLowerCase())
      )
    },
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.addTeamReview(this.form)
    },
  },
  async created() {
    this.SET_SIDEBARLOADING(true)
    this.SET_SIDEBARCLASS('sticky-footer')

    // Populate store
    await Promise.all([this.loadCategories()])
    // Create deep clone of categories to modify in form
    this.form.categories = this._.cloneDeep(this.listPlainCategories)
    this.workers = this.listWorkers

    // Set sidebar title
    this.SET_SIDEBARTITLE(`Add Team Review`)

    this.SET_SIDEBARLOADING(false)
  },
  destroyed() {
    this.SET_SIDEBARCLASS('')
  },
}
</script>

<style lang="scss">
.category label {
  margin-bottom: 0;
}

.review-container {
  overflow-y: scroll;
  padding: 0 5px; // To fix the cut-off dropshadow of :focus state from form fields
  margin-left: -5px;
}
</style>