<template>
  <div class="text-center text-secondary" :class="css">
    <div class="spinner-content">
      <b-spinner class="align-middle"></b-spinner>
      <div class="mt-3">
        <strong>{{ text }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    css: { type: String, default: '' },
    text: { type: String, default: 'Loading data…' },
  },
}
</script>
