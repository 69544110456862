<template>
  <header class="bg-dark mb-4">
    <b-container>
      <b-row>
        <b-col sm="12">
          <b-navbar toggleable="sm" type="dark" variant="dark">
            <b-navbar-brand to="/" class="d-flex align-items-center">
              <img src="/img/logo-white.svg" class="logo" />
              <span>WeBuild</span>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item to="/dashboard" active-class="active">Dashboard</b-nav-item>
                <b-nav-item to="/workers" active-class="active">Workers</b-nav-item>
                <b-nav-item to="/references" active-class="active">References</b-nav-item>
                <b-nav-item to="/notifications" active-class="active">Notifications</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                  <template v-slot:button-content>
                    <div class="d-inline-flex align-items-center">
                      <app-photo-user
                        class="mr-2"
                        :photo="getUserProfile.photoURL"
                        size="sm"
                        :placeholder="`${getUserProfile.displayName}` | getInitials"
                      ></app-photo-user>
                      <span>{{ getUserProfile.displayName }}</span>
                    </div>
                  </template>
                  <b-dropdown-item to="/users" v-if="getUserProfile.role === 'admin'">
                    <b-icon icon="people" font-scale="1.2"></b-icon>Users
                  </b-dropdown-item>
                  <b-dropdown-item to="/settings" v-if="getUserProfile.role === 'admin'">
                    <b-icon icon="gear" font-scale="1.2"></b-icon>Settings
                  </b-dropdown-item>
                  <b-dropdown-divider v-if="getUserProfile.role === 'admin'"></b-dropdown-divider>
                  <b-dropdown-item @click="logout">
                    <b-icon icon="door-closed" font-scale="1.2"></b-icon>Logout
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      loggedIn: false,
    }
  },
  components: {
    AppPhotoUser,
  },
  computed: {
    ...mapGetters('auth', ['getUserProfile']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>

<style scoped>
header {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.dropdown-toggle::after {
  margin-top: -10px;
}

.logo {
  width: 25px;
  height: 25px;
  margin-right: 7px;
}

.navbar-brand {
  font-weight: bold;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
}
</style>
