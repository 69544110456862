<template>
  <div>
    <multiselect
      :placeholder="placeholder"
      class="mb-2"
      :options="clonedOptions"
      :searchable="true"
      :reset-after="true"
      :taggable="false"
      :showLabels="false"
      :trackBy="trackBy"
      :label="label"
      @select="addItem"
    >
      <template slot="noOptions">{{ placeholderNoOptions }}</template>
    </multiselect>
    <div class="selected-options mb-3">
      <b-form-tag
        v-for="item in selected"
        @remove="removeItem(item)"
        :key="item[trackBy]"
        :title="item[label]"
        class="mr-1 mb-1"
        >{{ item[label] }}</b-form-tag
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Add item',
    },
    placeholderNoOptions: {
      type: String,
      default: 'All items added',
    },
    value: {
      type: Array,
    },
    options: {
      type: Array,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: 'label',
    },
  },
  data: function () {
    return {
      selected: [],
      clonedOptions: [],
    }
  },
  methods: {
    addItem(value) {
      // Only push if not already selected
      if (!this.selected.find((item) => item[this.trackBy] === value[this.trackBy])) {
        this.selected.push(value)
        this.selected.sort(this.compare)

        this.$emit('input', this.selected)

        // Remove selected item from options
        const index = this.clonedOptions
          .map((item) => item[this.trackBy])
          .indexOf(value[this.trackBy])
        this.clonedOptions.splice(index, 1)
      }
    },
    removeItem(value) {
      const index = this.selected.map((item) => item[this.trackBy]).indexOf(value[this.trackBy])
      this.selected.splice(index, 1)

      this.clonedOptions.push(value)
      this.clonedOptions.sort(this.compare)
    },
    compare(a, b) {
      return a[this.label] < b[this.label] ? -1 : a[this.label] > b[this.label] ? 1 : 0
    },
  },
  created() {
    this.clonedOptions = this._.cloneDeep(this.options)
    this.clonedOptions.sort(this.compare)
    this.selected = this.value

    // Remove already selected options
    this.selected.forEach((item) => {
      const index = this.clonedOptions.map((item) => item[this.trackBy]).indexOf(item[this.trackBy])
      this.clonedOptions.splice(index, 1)
    })
  },
}
</script>

<style lang="scss" scoped>
.selected-options {
  font-size: 1.2rem;
}
</style>