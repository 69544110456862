const toast = ({ type = 'success', msg, elm }) => {
  const h = elm.$app.$createElement
  const vNodes = h('div', [
    h('div', { class: 'bar' }, ''),
    h('b-icon', {
      class: 'mr-2',
      props: { icon: 'check-circle-fill', fontScale: '1.2' },
    }),
    msg,
  ])

  elm.$app.$root.$bvToast.toast(vNodes, {
    variant: type,
    toaster: 'b-toaster-bottom-right',
    noCloseButton: true,
    solid: true,
    noAutoHide: false,
    autoHideDelay: 5000,
  })
}

const toastSuccess = (elm, msg) => toast({ type: 'success', msg, elm, icon: 'check-circle-fill' })
const toastError = (elm, msg) =>
  toast({ type: 'danger', msg, elm, icon: 'exclamation-circle-fill' })

export { toastSuccess, toastError }

// Same then that one
// function toastSuccess(msg) {
//   toast({ type: 'success', msg, icon: 'check-circle-fill'})
// }
