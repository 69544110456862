<template>
  <b-button
    type="submit"
    variant="primary"
    ref="formSubmit"
    :tabindex="tabindex"
    :class="block ? '' : css === '' ? 'btn-minwidth' : css"
    :block="block ? true : false"
  >
    <b-spinner v-if="loading" small></b-spinner>
    <slot v-else></slot>
  </b-button>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    tabindex: { type: Number, default: -1 },
    css: { type: String, default: '' },
  },
}
</script>
