<template>
  <div v-if="!isPageLoading">
    <app-page-header title="Settings"></app-page-header>
    <b-row class="mb-5">
      <b-col>
        <b-form v-if="listSettings.notifications">
          <h3 class="mb-3">Notifications</h3>
          <b-form-group
            :description="`Send every notification within WeBuild to the worker via SMS. The SMS are carried out by the provider Twilio and cost 0.07 USD per SMS. The account balance is at ${
              Math.round(getTwilioAccountInfo.balance * 100) / 100
            } ${getTwilioAccountInfo.currency}.`"
            class="mb-4"
          >
            <b-form-checkbox
              switch
              id="notificationsEnabled"
              :checked="listSettings.notifications.enabled"
              @change="
                SET_PROPERTY({ val: $event, field: 'notifications.enabled' })
                updateSettings()
              "
              name="notificationsEnabled"
              :value="true"
              :unchecked-value="false"
              >Send notifications via SMS</b-form-checkbox
            >
          </b-form-group>
          <b-form-group
            label="Sender Mobile Number"
            label-for="notificationsSender"
            description="The number needs to be registered on Twilio."
            class="mb-4"
          >
            <b-form-input
              id="notificationsSender"
              name="notificationsSender"
              :value="listSettings.notifications.sender"
              @input="SET_PROPERTY({ val: $event, field: 'notifications.sender' })"
              @change="updateSettings"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Receiver Whitelist"
            label-for="notificationsReceiverWhitelist"
            description="Comma separated list of whitelisted phone numbers. If empty, notifications get sent to everyone."
            class="mb-4"
          >
            <b-form-input
              id="notificationsReceiverWhitelist"
              name="notificationsReceiverWhitelist"
              :value="listSettings.notifications.receiverWhitelist"
              @input="SET_PROPERTY({ val: $event, field: 'notifications.receiverWhitelist' })"
              @change="updateSettings"
            ></b-form-input>
          </b-form-group>
          <b-card no-body class="mb-4">
            <b-tabs pills card vertical>
              <b-tab
                :title="template.label"
                v-for="(template, index) in listSettings.notifications.templates"
                :key="template.label"
                :active="index === 0 ? true : false"
              >
                <b-card-text>
                  <b-form-group
                    label="Message"
                    :label-for="template.id"
                    :description="`Available fieldnames: ${template.fieldnames}`"
                    class="mb-2"
                  >
                    <b-form-textarea
                      :id="template.id"
                      :ref="template.id"
                      tabindex="2"
                      rows="6"
                      max-rows="6"
                      :value="template.message"
                      @input="
                        SET_PROPERTY({
                          val: $event,
                          field: `notifications.templates[${index}].message`,
                        })
                      "
                      @change="updateSettings"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-link @click="resetTemplate(template.id)">
                    <b-icon icon="arrow-clockwise" font-scale="1" class="mr-1"></b-icon>Reset
                    default
                  </b-link>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
          <h3 class="mb-3">Mail</h3>
          <b-form-group
            label="Receivers"
            label-for="mailReceivers"
            class="mb-5"
            description="List of email addresses (comma separated) which receive: Withdrawal Requests."
          >
            <b-form-input
              id="mailReceivers"
              name="mailReceivers"
              :value="listSettings.mail.receivers"
              @input="SET_PROPERTY({ val: $event, field: 'mail.receivers' })"
              @change="updateSettings"
            ></b-form-input>
          </b-form-group>
          <h3 class="mb-3">Mobile App</h3>
          <b-form-group
            label="URL"
            label-for="appURL"
            description="The URL on which the mobile app for the workers is reachable."
          >
            <b-form-input
              id="appURL"
              name="appURL"
              :value="listSettings.app.url"
              @input="SET_PROPERTY({ val: $event, field: 'app.url' })"
              @change="updateSettings"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <div v-else class="mt-2">
          <p>The default settings are not yet initialised.</p>
          <b-link @click="initSettings">
            <b-icon icon="download" font-scale="1" class="mr-1"></b-icon>Load default settings
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <app-list-inline
          title="Competences"
          description="The competences are used to define the skills of a worker and to tag reference projects."
          label="Competence"
          :items="listCompetences"
          @add="addCompetence"
          @delete="deleteCompetence"
          @update="updateCompetence"
          @init="initCompetences"
        ></app-list-inline>
      </b-col>
      <b-col>
        <app-list-inline
          title="Categories"
          description="The categories are used to segment reference projects and for worker reviewes."
          label="Category"
          :items="listCategories"
          @add="addCategory"
          @delete="deleteCategory"
          @update="updateCategory"
          @init="initCategories"
        >
          <b-alert show variant="warning" class="mt-4">
            <strong>Important:</strong> Changing the review categories has an impact on all past
            reviews. You should not change them once the first reviews have been collected.
          </b-alert>
        </app-list-inline>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppListInline from '@/components/ui/list.inline.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('app', ['isPageLoading']),
    ...mapGetters('settings', ['listSettings', 'getTwilioAccountInfo']),
    ...mapGetters('competences', ['listCompetences']),
    ...mapGetters('categories', ['listCategories']),
  },
  components: {
    AppPageHeader,
    AppListInline,
  },
  methods: {
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapMutations('settings', ['SET_PROPERTY']),
    ...mapActions('settings', [
      'initSettings',
      'loadSettings',
      'updateSettings',
      'resetTemplate',
      'loadTwilioAccountInfo',
    ]),
    ...mapActions('competences', [
      'loadCompetences',
      'addCompetence',
      'updateCompetence',
      'deleteCompetence',
      'initCompetences',
    ]),
    ...mapActions('categories', [
      'loadCategories',
      'addCategory',
      'updateCategory',
      'deleteCategory',
      'initCategories',
    ]),
  },
  async created() {
    this.SET_PAGELOADING(true)
    // Populate store
    await Promise.all([this.loadSettings(), this.loadCompetences(), this.loadCategories()])
    this.loadTwilioAccountInfo()

    this.SET_PAGELOADING(false)
  },
}
</script>