<template>
  <div v-if="!isSidebarLoading" class="h-100">
    <b-form
      @submit.stop.prevent="onSubmit"
      ref="reviewForm"
      class="d-flex flex-column justify-content-between h-100"
    >
      <div class="review-container flex-grow-1">
        <b-form-group label="Name of Reviewer*" label-for="reviewFullname">
          <b-form-input
            autofocus
            id="reviewFullname"
            tabindex="1"
            :state="validateState($v.form.fullname)"
            v-model.trim="$v.form.fullname.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Phone of Reviewer" label-for="reviewPhone" v-if="form.phoneNumber">
          <b-form-input
            id="reviewPhone"
            tabindex="2"
            v-model.trim="form.phoneNumber"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Company*" label-for="reviewCompany">
          <b-form-input
            id="reviewCompany"
            tabindex="3"
            :state="validateState($v.form.company)"
            v-model.trim="$v.form.company.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Project*" label-for="reviewProject">
          <b-form-input
            id="reviewProject"
            autocomplete="off"
            tabindex="4"
            :state="validateState($v.form.project)"
            v-model.trim="$v.form.project.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Comment" label-for="reviewComment">
          <b-form-textarea
            id="reviewComment"
            tabindex="5"
            rows="3"
            max-rows="6"
            v-model.trim="form.comment"
          ></b-form-textarea>
        </b-form-group>
        <b-form-checkbox
          v-model="form.recommend"
          tabindex="6"
          :value="true"
          :unchecked-value="false"
          class="my-3"
          >I would recommend this person</b-form-checkbox
        >
        <template v-if="form.categories">
          <b-form-group
            v-for="category in form.categories"
            :key="category.id"
            :label="category.name"
            :label-for="category.id"
            class="d-flex align-items-center justify-content-between mb-1 category"
          >
            <b-form-rating
              :id="category.id"
              size="sm"
              v-model="category.rating"
              no-border
            ></b-form-rating>
          </b-form-group>
        </template>
        <b-form-checkbox v-model="form.verified" :value="true" :unchecked-value="false" class="my-3"
          >This review is verified
          <span
            class="ml-1 text-secondary"
            title="Unverified reviews are not visible for anyone else nor considered in the overall worker rating."
            v-b-tooltip.hover
          >
            <b-icon icon="info-circle" font-scale="1"></b-icon>
          </span>
        </b-form-checkbox>
      </div>
      <div class="mt-2">
        <app-button-spinner :loading="isProcessing" :tabindex="7">Save</app-button-spinner>
        <b-button variant="light" @click.prevent="$router.go(-1)" class="btn-minwidth"
          >Cancel</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import { required } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['id', 'reviewId'],
  data: function () {
    return {
      review: {},
      categories: [],
      form: {
        fullname: '',
        company: '',
        project: '',
        comment: '',
        categories: {},
        recommend: false,
      },
    }
  },
  validations: {
    form: {
      fullname: { required },
      company: { required },
      project: { required },
    },
  },
  computed: {
    ...mapGetters('app', ['isSidebarLoading', 'isProcessing']),
    ...mapGetters('workers', ['findWorker']),
    ...mapGetters('reviews', ['findReview']),
  },
  components: {
    AppButtonSpinner,
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_SIDEBARLOADING', 'SET_SIDEBARTITLE', 'SET_SIDEBARCLASS']),
    ...mapActions('workers', ['loadWorker']),
    ...mapActions('reviews', ['updateReview']),
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.updateReview({ form: this.form, id: this.id, reviewId: this.reviewId })
    },
  },
  async created() {
    this.SET_SIDEBARLOADING(true)
    this.SET_SIDEBARCLASS('sticky-footer')

    // Populate store
    await Promise.all([this.loadWorker({ awaitPopulated: true, id: this.id })])

    // Reference to worker in store
    this.form = this._.cloneDeep(this.findReview(this.id, this.reviewId))

    // Set sidebar title
    this.SET_SIDEBARTITLE(`Edit review from ${this.form.fullname}`)

    this.SET_SIDEBARLOADING(false)
  },
  destroyed() {
    this.SET_SIDEBARCLASS('')
  },
}
</script>

<style lang="scss">
.category label {
  margin-bottom: 0;
}

.review-container {
  overflow-y: scroll;
  padding: 0 5px; // To fix the cut-off dropshadow of :focus state from form fields
  margin-left: -5px;
}
</style>
