import * as fb from '@/firebase'
import { renderTemplateVars } from '@/utils'
import { trackEvent } from '@/utils/analytics'

import { groupBy, keyBy } from 'lodash'

const state = {
  all: {},
  unsubscribe: null,
}

const mutations = {
  SET_NOTIFICATIONS(state, val) {
    state.all = val
  },
  ADD_NOTIFICATION(state, val) {
    state.all.push(val)
  },
  SET_UNSUBSCRIBE(state, val) {
    state.unsubscribe = val
  },
}

const getters = {
  listNotifications(state) {
    return state.all
  },
  listNotificationsByWorker(state, getters, rootState, rootGetters) {
    return state.all.reduce((acc, n) => {
      const workerId = n.workerId
      if (workerId) {
        if (acc[workerId] === undefined) {
          acc[workerId] = []
        }
        acc[workerId].push(n)
      }
      return acc
    }, {})

    //return workers
  },
  listNotificationsSort(state) {
    return keyBy(state.all, 'workerId')
  },
  listWorkerNotifications(state) {
    return id => {
      return state.all.filter(notification => notification.workerId === id)
    }
  },
}

const actions = {
  /**
   * Subscribe to notifications of a all workers (real-time updates)
   **/
  subscribeAllNotifications: async ({ commit }) => {
    const unsubscribe = fb.notificationsCollection
      .orderBy('createdOn', 'desc')
      .onSnapshot(snapshot => {
        const notifications = snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })

        commit('SET_NOTIFICATIONS', notifications)
      })

    commit('SET_UNSUBSCRIBE', unsubscribe)
  },
  /**
   * Unsubscribe to notifications of all workers
   **/
  unsubscribeAllNotifications: async ({ state }) => {
    state.unsubscribe()
  },
  /**
   * Subscribe to notifications of a specific worker (real-time updates)
   **/
  subscribeWorkerNotifications: async ({ commit }, id) => {
    const unsubscribe = fb.notificationsCollection
      .where('workerId', '==', id)
      .orderBy('createdOn', 'desc')
      .onSnapshot(snapshot => {
        const notifications = snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })

        commit('SET_NOTIFICATIONS', notifications)
      })

    commit('SET_UNSUBSCRIBE', unsubscribe)
  },
  /**
   * Unsubscribe to notifications of a specific worker
   **/
  unsubscribeWorkerNotifications: async ({ state }) => {
    state.unsubscribe()
  },
  /**
   * Load all notifications, sorted by creation date
   **/
  loadNotifications: async ({ commit }, id) => {
    const snapshot = await fb.notificationsCollection.orderBy('createdOn', 'desc').get()

    const notifications = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    commit('SET_NOTIFICATIONS', notifications)
  },
  /**
   * Load all notifications of a specific  worker, sorted by creation date
   **/
  loadWorkerNotifications: async ({ commit }, id) => {
    const snapshot = await fb.notificationsCollection
      .where('workerId', '==', id)
      .orderBy('createdOn', 'desc')
      .get()

    const notifications = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    })

    commit('SET_NOTIFICATIONS', notifications)
  },
  /**
   * Send notification to worker based on a template and its tempalte vars
   **/
  sendTplNotification: async ({ rootGetters }, { tplId, tplData }) => {
    const body = renderTemplateVars(
      rootGetters['settings/findNotificationTemplate'](tplId).message,
      { ...tplData }
    )

    await fb.notificationsCollection.add({
      createdOn: new Date(),
      to: tplData.worker.phone,
      from: rootGetters['settings/listSettings'].notifications.sender.replace(/ /g, ''),
      type: 'outgoing',
      workerId: tplData.worker.id,
      body,
    })
  },
  /**
   * Send notification to worker with custom text
   **/
  sendCustomNotification: async ({ commit, rootGetters }, notification) => {
    commit('app/SET_PROCESSING', true, { root: true })

    await fb.notificationsCollection.add({
      createdOn: new Date(),
      from: rootGetters['settings/listSettings'].notifications.sender.replace(/ /g, ''),
      ...notification,
    })

    trackEvent('Custom Notification Sent')
    commit('app/SET_PROCESSING', false, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
