<template>
  <div v-show="!isPageLoading">
    <app-page-header title="Edit Reference"></app-page-header>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Project*" label-for="referenceProject">
        <b-form-input
          id="referenceProject"
          tabindex="1"
          v-model.trim="$v.form.project.$model"
          :state="validateState($v.form.project)"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Completion Date*" label-for="referenceDate">
        <b-form-datepicker
          id="referenceDate"
          tabindex="2"
          locale="en"
          v-model.trim="$v.form.date.$model"
          :state="validateState($v.form.date)"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label="Worker*"
        label-for="referenceWorker"
        :class="{ 'is-invalid': $v.form.worker.$error }"
      >
        <multiselect
          v-model="$v.form.worker.$model"
          track-by="id"
          :show-labels="false"
          :internal-search="false"
          id="referenceWorker"
          :options="workers"
          @search-change="searchWorker"
          placeholder="Search or select a worker"
        >
          <template slot="option" slot-scope="{ option }">
            <div class="d-flex align-items-center">
              <app-photo-user
                class="mr-2"
                :photo="option.photo"
                size="s"
                :placeholder="`${option.firstname} ${option.lastname}` | getInitials"
              ></app-photo-user>
              {{ option.firstname }} {{ option.lastname }}
            </div>
          </template>
          <template slot="singleLabel" slot-scope="{ option }"
            >{{ option.firstname }} {{ option.lastname }}</template
          >
        </multiselect>
      </b-form-group>
      <b-form-group label="Required Competences" label-for="requiredCompetences">
        <multiselect
          v-model="form.competences"
          track-by="id"
          label="name"
          id="requiredCompetences"
          :options="competences"
          :close-on-select="false"
          :show-labels="false"
          :multiple="true"
          :taggable="false"
          tag-placeholder="Add this as new value"
          placeholder="Search or add a value"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
        </multiselect>
      </b-form-group>
      <b-form-group label="Description" label-for="referenceDescription">
        <b-form-textarea
          id="referenceDescription"
          tabindex="4"
          v-model="form.description"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Photos" label-for="referencePhotos">
        <div v-if="reference.photos">
          <div
            v-for="photo in reference.photos"
            :key="photo"
            class="img-removable img-reference mr-2 mb-2"
            :style="`background-image: url(${photo})`"
          >
            <a @click="deletePhoto({ photo, reference })">
              <b-icon icon="x" font-scale="1.2" class="text-white"></b-icon>
            </a>
          </div>
        </div>
        <b-form-file
          v-model="form.photos"
          id="referencePhotos"
          ref="referencePhotos"
          accept="image/jpeg, image/png, image/gif"
          class="text-nowrap text-truncate"
          tabindex="5"
          placeholder="Choose photos or drop them here..."
          drop-placeholder="Drop photos here..."
          multiple
        ></b-form-file>
        <b-link @click="$refs['referencePhotos'].reset()" class="link-small"
          >Reset selection</b-link
        >
      </b-form-group>
      <app-button-spinner :loading="isProcessing" :tabindex="6">Save</app-button-spinner>
      <b-button variant="light" @click="$router.go(-1)" class="btn-minwidth">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import AppPageHeader from '@/components/layout/page-header.vue'
import AppButtonSpinner from '@/components/ui/button-spinner.vue'
import AppPhotoUser from '@/components/ui/photo.user.vue'
import { required } from 'vuelidate/lib/validators'
import { validateState } from '@/utils/validators'
import { mapGetters, mapActions, mapMutations } from 'vuex'

const now = new Date()
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

export default {
  props: ['id'],
  data: function () {
    return {
      form: {
        project: '',
        date: today,
        worker: [],
        competences: [],
        description: '',
        photos: [],
      },
      workers: [],
      competences: [],
      reference: {},
    }
  },
  validations: {
    form: {
      project: { required },
      date: { required },
      worker: { required },
    },
  },
  components: {
    AppPageHeader,
    AppButtonSpinner,
    AppPhotoUser,
  },
  computed: {
    ...mapGetters('app', ['isPageLoading', 'isProcessing']),
    ...mapGetters('workers', ['listWorkers']),
    ...mapGetters('competences', ['listCompetences']),
    ...mapGetters('references', ['findReference']),
  },
  methods: {
    validateState,
    ...mapMutations('app', ['SET_PAGELOADING']),
    ...mapActions('workers', ['loadWorkers']),
    ...mapActions('references', [
      'loadReference',
      'updateReference',
      'deletePhoto',
      'getPublicURL',
    ]),
    async photoUrl(photo) {
      return await this.getPublicURL({
        workerId: this.reference.worker.id,
        referenceId: this.reference.id,
        photo,
      })
    },
    onSubmit() {
      // Validate form
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      this.updateReference(this.form)
    },
    searchWorker(query) {
      this.workers = this.listWorkers.filter((worker) =>
        `${worker.firstname} ${worker.lastname}`.toLowerCase().includes(query.toLowerCase())
      )
    },
  },
  async created() {
    this.SET_PAGELOADING(true)
    // Populate store
    await Promise.all([
      this.loadWorkers(false),
      this.loadReference({ awaitPopulated: true, id: this.id }), // Competences are loaded within here
    ])

    // Create local copy of the reference for form manipulation
    this.form = this._.cloneDeep(this.findReference(this.id))
    this.form.date = new Date(this.form.date.seconds * 1000)
    // Reset photo so it's not interferring with form-field
    this.form.photos = null

    // Reference to referejce in store (needed to display photos)
    this.reference = this.findReference(this.id)

    this.workers = this.listWorkers
    this.competences = this.listCompetences

    this.SET_PAGELOADING(false)
  },
}
</script>

<style scoped>
  .img-reference {
    border-radius: 3px;
    height: 100px;
    width: 200px;
    background-size: cover;
    background-position: center;
  }
</style>