<template>
  <header class="d-sm-flex justify-content-between mb-4">
    <h2>{{ title }}</h2>
    <div v-if="$slots.default" class="d-flex align-items-start">
      <slot></slot>
    </div>
  </header>
</template>
<script>
export default {
  props: ['title'],
}
</script>